<template>
  <div class="p-8">
    <div
      class="bg-gray-700/10 rounded-lg px-6 py-8 text-indigo-400 ring-1 ring-inset ring-indigo-400/30 shadow-lg shadow-indigo-500/50 w-96"
    >
      <!-- Heading section -->
      <div class="flex flex-row gap-4 mb-6">
        <span
          class="inline-flex items-center justify-center w-12 h-12 bg-indigo-500 rounded-md shadow-lg"
        >
          <GlobeAltIcon
            class="h-8 w-8 text-white"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          />
        </span>
        <div>
          <span class="font-semibold text-white">MyWebScrape</span>
          <p class="text-sm font-medium leading-6 text-gray-400">Web Scraper</p>
        </div>
      </div>

      <!-- Form content -->
      <form>
        <div class="space-y-6">
          <!-- Website input with uneditable span prepended -->
          <div>
            <div class="flex flex-row justify-between">
              <label
                for="input"
                class="block text-sm font-medium leading-6 text-white"
                >Website</label
              >
              <button type="button" @click="addVariable">
                <span
                  class="inline-flex items-center gap-x-1.5 rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700"
                >
                  <BoltIcon
                    class="h-3 w-3 fill-green-700"
                    viewBox="0 0 12 12"
                    aria-hidden="true"
                  ></BoltIcon>
                  Add Variable
                </span>
              </button>
            </div>
            <div class="mt-2">
              <div
                class="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
              >
                <span
                  class="flex select-none items-center pl-3 text-gray-500 sm:text-sm"
                  >https://</span
                >
                <div
                  id="input"
                  contenteditable="true"
                  ref="inputField"
                  class="flex-1 border-0 bg-transparent py-1.5 pl-1 text-white sm:text-sm sm:leading-6"
                  @input="saveCursorPosition"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { GlobeAltIcon } from "@heroicons/vue/24/outline";
import { BoltIcon } from "@heroicons/vue/20/solid";

const inputField = ref(null);
let cursorPosition = 0;

const saveCursorPosition = (event) => {
  const selection = window.getSelection();
  if (selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    cursorPosition = range.endOffset;
  }
};

const addVariable = () => {
  const selection = window.getSelection();
  let range;
  const inputEl = inputField.value;

  if (selection.rangeCount > 0) {
    range = selection.getRangeAt(0);
    const commonAncestor = range.commonAncestorContainer;

    if (!inputEl.contains(commonAncestor)) {
      // If the cursor is outside of the input field, append the variable to the end.
      range = document.createRange();
      range.selectNodeContents(inputEl);
      range.collapse(false);
    }
  } else {
    // If no range is selected, create a new range at the end of the input field.
    range = document.createRange();
    range.selectNodeContents(inputEl);
    range.collapse(false);
  }

  const span = document.createElement("span");
  span.className =
    "inline-flex items-center gap-x-0.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20";
  span.innerHTML = `
    Dynamic Variable
    <button type="button" class="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-green-600/20">
      <span class="sr-only">Remove</span>
      <svg viewBox="0 0 14 14" class="h-3.5 w-3.5 stroke-green-700/50 group-hover:stroke-green-700/75">
        <path d="M4 4l6 6m0-6l-6 6" />
      </svg>
      <span class="absolute -inset-1" />
    </button>
  `;

  span.setAttribute("contenteditable", "false");
  span.querySelector("button").addEventListener("click", () => {
    span.remove();
  });

  range.insertNode(span);
  range.setStartAfter(span);
  range.setEndAfter(span);
  selection.removeAllRanges();
  selection.addRange(range);
};
</script>

<style lang="scss" scoped></style>
