<template>
  <div class=" border-white/10 pt-11">
    <!--  add margin top 6 to element below <h2 class="px-4 text-base font-semibold leading-7 text-white sm:px-6 lg:px-8">
      Workflow Activity
    </h2>-->
    <table class="w-full whitespace-nowrap text-left">
      <colgroup>
        <col class="min-w-0" />
        <col class="min-w-0" />
        <col class="min-w-0" />
        <col class="min-w-0" />
        <col class="min-w-0" />
        <col class="min-w-0" />
      </colgroup>
      <thead class="border-b border-white/10 text-sm leading-6 text-white">
        <tr>
          <th scope="col" class="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8">
            Job ID
          </th>
          <th
            scope="col"
            class="hidden py-2 pl-0 pr-4 text-left font-semibold sm:table-cell sm:pr-6 lg:pr-8"
          >
            Deployment Date
          </th>
          <th
            scope="col"
            class="py-2 pl-0 pr-4 text-left font-semibold sm:pr-8 sm:text-left lg:pr-8"
          >
            Status
          </th>
          <th
            scope="col"
            class="py-2 pl-0 pr-4 text-left font-semibold sm:pr-8 sm:text-left lg:pr-8"
          >
            Rows
          </th>
          <th
            scope="col"
            class="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-8"
          >
            Duration
          </th>
          <th
            scope="col"
            class="hidden py-2 pl-0 pr-4 text-left font-semibold sm:table-cell sm:pr-6 lg:pr-8"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody class="divide-y divide-white/5">
        <tr v-for="job in jobs" :key="job.workflow.id">
          <td class="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
            <div class="flex items-center gap-x-4">
              <a
                href="#"
                class="truncate text-sm font-medium leading-6 text-white"
                >{{ job.workflow.id }}</a
              >
            </div>
          </td>
          <td
            class="hidden py-4 pl-0 pr-4 text-left text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8"
          >
            <time :datetime="job.dateTime">{{ job.dateTime }}</time>
          </td>
          <td class="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-4 lg:pr-4">
            <div class="flex items-center justify-end gap-x-2 sm:justify-start">
              <div
                :class="[statuses[job.status], 'flex-none rounded-full p-1']"
              >
                <div class="h-1.5 w-1.5 rounded-full bg-current"></div>
              </div>
              <div class="hidden text-white sm:block">{{ job.status }}</div>
            </div>
          </td>
          <td
            class="py-4 pl-0 pr-4 text-sm leading-6 text-gray-400 sm:pr-8 lg:pr-8"
          >
            {{ job.rows }}
          </td>
          <td
            class="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-400 md:table-cell lg:pr-8"
          >
            {{ job.duration }}
          </td>
          <td
            class="hidden py-4 pl-0 pr-4 text-left text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8"
          >
            <div class="self-start flex flex-row gap-1">
              <!-- Delete Button -->
              <button
               @click="viewJobDetails(job)"
                class="rounded-full p-2 text-white ring-1 ring-inset ring-white/10 hover:bg-gray-700 focus-visible:outline-offset-0"
              >
                <EyeIcon class="h-4 w-4" aria-hidden="true" />
              </button>
              <!-- Download Button -->
              <button @click="downloadJob(job)" :disabled="job.status == 'Pending'"
                class="rounded-full p-2 text-white ring-1 ring-inset ring-white/10 hover:bg-gray-700 focus-visible:outline-offset-0"
              >
                <ArrowDownTrayIcon class="h-4 w-4" aria-hidden="true" />
              </button>


              <button v-if="job.status === 'Pending'" @click="cancelJob(job)" :disabled="job.backendStatus == 'completed' || job.status == 'cancelled'"
                class="rounded-full p-2 text-white ring-1 ring-inset ring-white/10 hover:bg-gray-700 focus-visible:outline-offset-0"
              >
              <XMarkIcon class="h-4 w-4"  aria-hidden="true"/>
</button>




            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed, watch, toRaw } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useWorkflowEditorStore } from '@/stores/workflowEditorStore';
import { useTaskDefinerStore } from "@/stores/taskDefinerStore";
import { useUserStore } from '@/stores/user';

import axios from 'axios';
import DeleteModal from "../modals/DeleteModal.vue";
import DuplicateModal from "../modals/DuplicateModal.vue";
import RenameModal from "../modals/RenameModal.vue";
import WorkflowDashboard from "./WorkflowDashboard.vue";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  ChartBarSquareIcon,
  Cog6ToothIcon,
  FolderIcon,
  GlobeAltIcon,
  ServerIcon,
  SignalIcon,
  XMarkIcon,
  TrashIcon,
  EyeIcon,
} from "@heroicons/vue/24/outline";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  ArrowDownTrayIcon,

} from "@heroicons/vue/20/solid";

import statusColors from "../../../data/constants.js";


const route = useRoute();
const workflowId = route.params.workflowId;
const router = useRouter();


// Use the workflowEditor store
const workflowEditorStore = useWorkflowEditorStore();
const taskDefinerStore = useTaskDefinerStore();
const userStore = useUserStore();



const statuses = {
  Success: statusColors.Green,
  Pending: statusColors.Amber,
  Aborted: statusColors.Rose,
};


const jobs = ref([]);
// const jobs = [
//   {
//     workflow: {
//       id: 'e3f40b28-2f3a-4b91-8ae8-7a1f2e4bc4cf',
//       URL: "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
//     },
//     branch: "main",
//     status: "Success",
//     rows: "729/732",
//     failedrows: 3,
//     duration: "1h 3m 23s",
//     dateTime: "2022-11-03 08:47:29",
//   },

//   {
//     workflow: {
//       id: "a4f893d5-5d4c-49a3-8f1b-9f3e2f4a5d6b",
//       URL: "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
//     },
//     branch: "main",
//     status: "Pending",
//     rows: "12/15",
//     duration: "25s",
//     dateTime: "2023-05-15 14:23:45",
//   },
// ];

const sidebarOpen = ref(false);


const fetchInterval = 2000; 

onMounted(async () => {
  var userToken = userStore.getToken;
  const response = await axios.post(`${process.env.VUE_APP_API_URL}/user/getWorkflowById`, {
      workflowId: workflowId
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`,
      }
    });

    

    workflowEditorStore.setWorkflowName(response.data.workflowName);
    

  await fetchJobs();
  startPolling();
});

onBeforeUnmount(() => {
  stopPolling();
});

let pollingInterval;

const startPolling = () => {
  pollingInterval = setInterval(async () => {
    await fetchJobs();
  }, fetchInterval);
};

const stopPolling = () => {
  if (pollingInterval) {
    clearInterval(pollingInterval);
  }
};


async function cancelJob(job) {
  try {

var userToken = userStore.getToken;


const cancelResponse = await axios.post(`${process.env.VUE_APP_API_URL}/user/cancelJob`, {
  jobId: job.workflow.id
}, {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${userToken}`,
  }
});

console.log('the cancel result is ' ,  cancelResponse.data);

// job.status = 'cancelled';


// isRunning.value = false;
} catch (error) {
console.error('Error running workflow:', error);
}

}

async function downloadJob(job) {
  try {

    const userToken = userStore.getToken;

    const response = await axios.post(`${process.env.VUE_APP_API_URL}/user/downloadJob`, { jobId: job.workflow.id }, {
      responseType: 'blob',  // Ensuring the response type is 'blob' for file download
      headers: {
        'Authorization': `Bearer ${userToken}`  // Adding Authorization header
      }
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));

    // Create an anchor element to trigger the download
    const link = document.createElement('a');
    link.href = url;


    // console.log('the headers are ' ,  response.headers);

    // Extract filename from the Content-Disposition header
    const contentDisposition = response.headers['content-disposition'];

    console.log(response.headers);
    let filename = "download.csv"; // Default filename if not specified
    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
      if (filenameMatch.length > 1) {
        filename = filenameMatch[1];
      }
    }

    link.setAttribute('download', filename);

    // Append the anchor element to the document body and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the anchor element and revoking the blob URL
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading file:', error.response ? error.response.data : error.message);
  }

}
function viewJobDetails(job) {
  // console.log('Job details:', job);

  // this will route them to the preview section to see the workflow run live.
  const workflowId = job.workflow.workflowId;
  router.push(`/workspace/workflows/${workflowId}/preview/${job.workflow.id}`);

  // Add any additional logic you need when the Eye icon is clicked
}


// Function to transform the job data
function transformJobData(apiData) {
  return apiData.map(job => ({
    workflow: {
      id: job._id,
      workflowId: job.workflowId,
      URL: "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
    },
    branch: "main", // Placeholder
    status: job.status === "completed" ? "Success" : (job.status === "cancelled" ? "Aborted" : "Pending"),
    backendStatus: job.status,
    rows: `${job.rowCount}/${job.totalRow}`,
    failedrows: 0, // Placeholder
    duration: "N/A", // Placeholder

    dateTime: new Date(job.updatedAt).toLocaleString()
  }));
}

async function fetchJobs() {

  const token  = userStore.getToken;
  try {


    const response = await axios.post(`${process.env.VUE_APP_API_URL}/user/getJobsByWorkflow`,  {
      workflowId: workflowId
    },  {
      headers: {
        // 'Content-Type': 'application/json',  // Ensuring Content-Type is set
        'Authorization': `Bearer ${token}`,  // Adding Authorization header
      }
    });


    // console.log('the response is ' , response.data);

   var final =  transformJobData(response.data);

   jobs.value = final;

   console.log('the final is ', final);



  } catch (error) {
    console.error('Error fetching jobs:', error.response ? error.response.data : error.message);
  }
}

</script>
