<template>


  <div class="bg-gray-700/10 rounded-lg px-6 py-8 text-indigo-400 ring-1 ring-inset ring-indigo-400/30 w-96">
    <!-- Heading Section -->
    <div class="flex flex-row justify-between items-center gap-4 mb-6">
      <!-- Icon and Header/Subheader -->

      <div class="flex flex-row gap-4 items-center">
        <!-- Card Icon -->
        <span class="inline-flex items-center justify-center w-12 h-12 bg-indigo-500 rounded-md shadow-lg"
          style="min-width: 3rem; min-height: 3rem;">


          <component v-if="typeof icon === 'function' || typeof icon === 'object'" :is="icon" class="w-8 h-8 text-white"
            viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" />
          <img v-else-if="typeof icon === 'string'" :src="icon" alt="icon" class="w-8 h-8 rounded-md" />


          <!-- <component :is="icon" class="h-8 w-8 text-white" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" /> -->
        </span>
        <!-- Card Header / Subheader -->
        <div>
          <span class="font-semibold text-white">{{ props.card.name }}</span>
          <p class="text-sm font-medium leading-6 text-gray-400">{{ subheader }}</p>
        </div>
      </div>
      <div class="self-start flex flex-row gap-1">
        <!-- Cost Tooltip 
        <button type="button" @click="$emit('rename')"
          class="rounded-full p-2 text-white ring-1 ring-inset ring-white/10 hover:bg-gray-700 focus-visible:outline-offset-0">
          <CurrencyDollarIcon class="h-4 w-4" aria-hidden="true" />
        </button> -->
        <!-- Rename Button -->
        <button type="button" @click="$emit('rename')"
          class="rounded-full p-2 text-white ring-1 ring-inset ring-white/10 hover:bg-gray-700 focus-visible:outline-offset-0">
          <PencilSquareIcon class="h-4 w-4" aria-hidden="true" />
        </button>
        <!-- Duplicate Button -->
        <button type="button" @click="$emit('duplicate')"
          class="rounded-full p-2 text-white ring-1 ring-inset ring-white/10 hover:bg-gray-700 focus-visible:outline-offset-0">
          <DocumentDuplicateIcon class="h-4 w-4" aria-hidden="true" />
        </button>
        <!-- Delete Button -->
        <button type="button" @click="$emit('remove')"
          class="rounded-full p-2 text-white ring-1 ring-inset ring-white/10 hover:bg-gray-700 focus-visible:outline-offset-0">
          <TrashIcon class="h-4 w-4" aria-hidden="true" />
        </button>

        <button @click="togglePreviewModal(props.card)"
          class="rounded-full p-2 text-white ring-1 ring-inset ring-white/10 hover:bg-gray-700 focus-visible:outline-offset-0">
          <EyeIcon class="h-4 w-4" aria-hidden="true" />
        </button>

        <!-- Modal for displaying full CSV data -->
        <transition name="fade">
          <div v-if="showModal" class="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50"
            @keydown.esc="closeModal" @click.self="closeModal">
            <div
              class="bg-gray-900 rounded-lg p-6 w-full max-w-6xl max-h-[90vh] overflow-auto ring-1 ring-inset ring-white/10"
              @click.stop>
              <div class="flex justify-between items-center mb-4">
                <h3 class="text-lg font-semibold text-white">{{ fileName }}</h3>
                <div class="flex items-center space-x-2">
                  <button @click="downloadCSV" class="text-gray-400 hover:text-white">
                    <span class="sr-only">Download</span>
                    <ArrowDownOnSquareIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                  <button @click="closeModal" class="text-gray-400 hover:text-white">
                    <span class="sr-only">Close</span>
                    <XCircleIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
              <div class="overflow-auto">

                <table class="min-w-full divide-y divide-gray-700">
                  <thead>
                    <tr>
                      <!-- <th v-for="(row, rowIndex) in csvData" :key="rowIndex"> -->

                      <th v-for="(header, index) in headers" :key="index"
                        class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0">
                        {{ header }}
                      </th>

                      <!-- <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0">
        Column
      </th> -->
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-800">
                    <tr v-for="(row, rowIndex) in csvData" :key="rowIndex">
                      <td class="whitespace-nowrap px-3 py-4 text-sm max-w-[150px] overflow-auto">


                        <span v-if="combinedTextLength(row) > 300">
                          <button @click="previewText(row)" class="ml-2 text-indigo-400">
                            <EyeIcon class="h-4 w-4 mr-2 inline-block" />
                          </button>
                          <span>
                            <span v-for="(item, index) in row" :key="index" :class="{
                              'text-gray-300': item.color === 'normal',
                              'text-green-400': item.color === 'green',
                              'text-blue-400': item.color === 'blue',
                            }" v-html="item.text ? truncate(item.text).replace(/\n/g, '<br>') : ''">
                            </span>
                            <!-- <span v-if="remainingTextLength(row) > 0">...</span> -->
                          </span>
                        </span>
                        <span v-else>
                          <span v-for="(item, index) in row" :key="index" :class="{
                            'text-gray-300': item.color === 'normal',
                            'text-green-400': item.color === 'green',
                            'text-blue-400': item.color === 'blue',
                          }" v-html="item.text ? item.text.replace(/\n/g, '<br>') : ''">
                          </span>
                        </span>

                        <!-- <span v-if="combinedTextLength(row) > 300">
    <button @click="previewText(getCombinedText(row))" class="ml-2 text-indigo-400">
      <EyeIcon class="h-4 w-4 mr-2 inline-block" />
    </button>
    <span v-html="truncate(getCombinedText(row)).replace(/\n/g, '<br>')"></span>
  </span>
  <span v-else>
    <span v-for="(item, index) in row" :key="index"
      :class="{
        'text-gray-300': item.color === 'normal',
        'text-green-400': item.color === 'green',
        'text-blue-400': item.color === 'blue',
      }"
      v-html="item.text ? item.text.replace(/\n/g, '<br>') : ''">
    </span>
  </span> -->

                        <!-- <span v-for="(item, index) in row" :key="index"
          :class="{
            'text-gray-300': item.color === 'normal',
            'text-green-400': item.color === 'green',
            'text-blue-400': item.color === 'blue',
          }">
          <span v-if="item.text && item.text.length > 300">
            <button @click="previewText(item.text)" class="ml-2 text-indigo-400">
              <EyeIcon class="h-4 w-4 mr-2 inline-block" />
            </button>
            <span v-html="item.text ? truncate(item.text).replace(/\n/g, '<br>') : ''"></span>
          </span>
          <span v-else v-html="item.text ? item.text.replace(/\n/g, '<br>') : ''"></span>
        </span> -->
                      </td>
                    </tr>
                  </tbody>
                </table>

                <!-- Modal for previewing text -->
                <transition name="fade">
                  <div v-if="showTextModal"
                    class="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50"
                    @keydown.esc="escHandler" @click.self="closeTextModal">
                    <div class="bg-gray-800 rounded-lg p-6 w-full max-w-lg max-h-[70vh] overflow-auto" @click.stop>
                      <div class="flex justify-between items-center mb-4">
                        <h3 class="text-lg font-semibold text-white">Full Text</h3>
                        <button @click="closeTextModal" class="text-gray-400 hover:text-white">
                          <span class="sr-only">Close</span>
                          <XCircleIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                      <div class="text-sm text-gray-300">
                        <span v-for="(item, index) in previewContent" :key="index" :class="{
                          'text-gray-300': item.color === 'normal',
                          'text-green-400': item.color === 'green',
                          'text-blue-400': item.color === 'blue',
                        }" v-html="item.text ? item.text.replace(/\n/g, '<br>') : ''">
                        </span>



                      </div>
                    </div>
                  </div>
                </transition>

              </div>
            </div>
          </div>
        </transition>


      </div>
    </div>

    <!-- Form content -->
    <form>
      <div class="space-y-6">
        <!-- Render form elements based on their type -->
        <div v-for="element in filteredElements" :key="element.id">
          <!-- Website input -->
          <div v-if="element.type === 'website'">


            <!-- <div class="flex flex-row justify-between"> -->
            <AddDV :card="props.card" :label="element.label" :targetDynamicId="element.id"
              :dynamicInputRef="dynamicReference"></AddDV>


            <!-- </div> -->

            <TagifyInput ref="dynamicReference" :label="element.label" :card="props.card" :targetDynamicId="element.id"
              mode="dv" @updateCsvTag="$emit('updateCsvTag', $event)"></TagifyInput>



            <!-- <AddDV :card="props.card" :dynamicInputRef="dynamicReference" ></AddDV>

            <TagifyInput ref="dynamicReference" :label="element.label" :card="props.card" :targetDynamicId="element.id" :mode="dv"
              @updateCsvTag="$emit('updateCsvTag', $event)"></TagifyInput> -->

            <div class="mt-2">

              <div
                class="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">

                <!-- <span class="flex select-none items-center pl-3 text-gray-500 sm:text-sm">{{ element.prefix }}</span> -->
                <!-- <div :id="element.id" contenteditable="true" class="flex-1 border-0 bg-transparent py-1.5 pl-1 pr-2 overflow-scroll text-white sm:text-sm sm:leading-6"></div> -->

                <!-- <input v-model="element.value" :id="element.id" type="text" class="flex-1 border-0 bg-transparent py-1.5 pl-1 pr-2 overflow-scroll text-white sm:text-sm sm:leading-6" /> -->
              </div>
            </div>
          </div>

          <!-- Short text input -->
          <div v-if="element.type === 'shorttext'">
            <div class="flex flex-row justify-between">
              <label :for="element.id" class="block text-sm font-medium leading-6 text-white">{{ element.label
                }}</label>
              <AddVariable v-if="element.hasAddVariable" :variables="variables" :targetElementId="element.id" />
            </div>
            <div class="mt-2">
              <!-- <div :id="element.id" contenteditable="true" class="block w-full rounded-md border-0 bg-white/5 py-1.5 px-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"></div>

<input v-model="element.value" :id="element.id" type="text" class="block w-full rounded-md border-0 bg-white/5 py-1.5 px-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" /> -->
            </div>
          </div>

          <!-- Long text (textarea) -->
          <div v-if="element.type === 'textarea'">
            <div class="flex flex-row justify-between">
              <label :for="element.id" class="block text-sm font-medium leading-6 text-white">{{ element.label
                }}</label>
              <AddVariable v-if="element.hasAddVariable" :variables="variables" :targetElementId="element.id" />
            </div>
            <div class="mt-2">
              <!-- <div :id="element.id" contenteditable="true" class="block w-full rounded-md border-0 bg-white/5 py-1.5 px-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"></div> -->

              <textarea v-model="element.value" :id="element.id"
                class="block w-full rounded-md border-0 bg-white/5 py-1.5 px-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"></textarea>
            </div>
            <p class="mt-3 text-sm leading-6 text-gray-400">{{ element.description }}</p>
          </div>

          <!-- Photo upload -->
          <div v-if="element.type === 'photo'">
            <label :for="element.id" class="block text-sm font-medium leading-6 text-white">{{ element.label }}</label>
            <div class="mt-2 flex items-center gap-x-3">
              <UserCircleIcon class="h-12 w-12 text-gray-500" aria-hidden="true" />
              <button type="button"
                class="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20">{{
                  element.buttonText }}</button>
            </div>
          </div>

          <!-- File upload with drag and drop -->
          <div v-if="element.type === 'file'">
            <label :for="element.id" class="block text-sm font-medium leading-6 text-white">{{ element.label }}</label>
            <div class="mt-2 flex justify-center rounded-lg border border-dashed border-white/25 px-6 py-10">
              <div class="text-center">
                <PhotoIcon class="mx-auto h-12 w-12 text-gray-500" aria-hidden="true" />
                <div class="mt-4 flex text-sm leading-6 text-gray-400">
                  <label :for="element.id"
                    class="relative cursor-pointer rounded-md bg-gray-900 font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900 hover:text-indigo-500">
                    <span>{{ element.buttonText }}</span>
                    <input :id="element.id" :name="element.id" type="file" class="sr-only" />
                  </label>
                  <p class="pl-1">{{ element.description }}</p>
                </div>
                <p class="text-xs leading-5 text-gray-400">{{ element.fileTypes }}</p>
              </div>
            </div>
          </div>


          <!-- Checkbox -->
          <div v-if="element.type === 'checkbox'" class="relative flex gap-x-3">
            <div class="flex h-6 items-center">
              <!-- <input :id="element.id" :name="element.id" type="checkbox" class="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900" /> -->

              <input v-model="element.value" :id="element.id" type="checkbox"
                class="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900" />

            </div>
            <div class="text-sm leading-6">
              <label :for="element.id" class="font-medium text-white">{{ element.label }}</label>
              <p class="text-gray-400">{{ element.description }}</p>
            </div>
          </div>

          <!-- Radio -->
          <fieldset v-if="element.type === 'radio-group'" class="mt-4">

            <!-- Radio Group -->
            <RadioGroup v-model="element.value" :legend="element.legend" :description="element.description"
              :name="element.name" :options="element.options" />

            <!-- <legend class="text-sm font-semibold leading-6 text-white">{{ element.legend }}</legend>
            <p class="mt-1 text-sm leading-6 text-gray-400">{{ element.description }}</p>
            <div class="mt-6 space-y-6">
              <div v-for="option in element.options" :key="option.id" class="flex items-center gap-x-3">
                <input v-model="option.value" :id="option.id" :name="element.name" type="radio" class="h-4 w-4 border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900" />
                <label :for="option.id" class="block text-sm font-medium leading-6 text-white">{{ option.label }}</label>
              </div>
            </div> -->
          </fieldset>

          <!-- Slider -->
          <div v-if="element.type === 'slider'">
            <label :for="element.id" class="block text-sm font-medium leading-6 text-white">{{ element.label }}</label>
            <div class="mt-2">
              <input type="range" @mouseover="emitMouseOver" @mouseout="emitMouseOut" :id="element.id"
                :name="element.id" :min="element.min" :max="element.max" :step="element.step" v-model="element.value"
                class="w-full h-2 bg-gray-200 accent-indigo-500 hover:accent-indigo-400 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" />
              <p class="mt-2 text-sm text-gray-400">{{ element.value }}</p>

            </div>
          </div>


          <div v-if="element.type === 'multiselect'">
            <div class="flex flex-row justify-between">
              <label :for="element.id" class="block text-sm font-medium leading-6 text-white">{{ element.label
                }}</label>
              <!-- <AddVariable v-if="element.hasAddVariable" :variables="variables" :targetElementId="element.id" /> -->
            </div>
            <div class="mt-2">

              <!-- Multiselect for HTML tags -->
              <multiselect v-model="element.value"
                class="block w-full rounded-md border-0 bg-white/5 py-1.5 px-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 resize-none"
                :options="element.tagOptions" :multiple="true" placeholder="Select option" label="tag" track-by="tag"
                :preselect-first="false"
                @Open	="handleMultiSelectChange"></multiselect>

              <!-- <div :id="element.id" contenteditable="true" class="block w-full rounded-md border-0 bg-white/5 py-1.5 px-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"></div> -->

              <!-- <textarea v-model="element.value" :id="element.id" class="block w-full rounded-md border-0 bg-white/5 py-1.5 px-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"></textarea> -->


            </div>
            <!-- <p class="mt-3 text-sm leading-6 text-gray-400">{{ element.description }}</p> -->
          </div>
          <div v-if="element.type === 'single-select'">
            <div class="flex flex-row justify-between">
              <label :for="element.id" class="block text-sm font-medium leading-6 text-white">{{ element.label
                }}</label>
              <!-- <AddVariable v-if="element.hasAddVariable" :variables="variables" :targetElementId="element.id" /> -->
            </div>
            <div class="mt-2">
              <!-- Single select for HTML tags -->
              <select v-model="element.value" :id="element.id"
                class="block w-full rounded-md border-0 bg-white/5 py-1.5 px-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 ">
                <option
                  class="block w-full rounded-md border-0 bg-white/5 py-1.5 px-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  v-for="option in element.tagOptions" :key="option.id" :value="option.id">{{ option.tag }}</option>
              </select>
            </div>
          </div>


          <!-- input text -->
          <div v-if="element.type === 'input'">
            <div class="flex flex-row justify-between">
              <label :for="element.id" class="block text-sm font-medium leading-6 text-white">{{ element.label
                }}</label>
              <AddVariable v-if="element.hasAddVariable" :variables="variables" :targetElementId="element.id" />
            </div>
            <div class="mt-2">
              <!-- <div :id="element.id" contenteditable="true" class="block w-full rounded-md border-0 bg-white/5 py-1.5 px-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"></div> -->

              <input v-model="element.value" :id="element.id"
                class="block w-full rounded-md border-0 bg-white/5 py-1.5 px-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
            </div>
            <p class="mt-3 text-sm leading-6 text-gray-400">{{ element.description }}</p>
          </div>



        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted, nextTick, onBeforeUnmount } from 'vue';
import { PhotoIcon, UserCircleIcon, BoltIcon } from "@heroicons/vue/24/solid";
import TagifyInput from './DynamicInputElement.vue'
import {
  TrashIcon, PencilSquareIcon, DocumentDuplicateIcon, QuestionMarkCircleIcon, CurrencyDollarIcon, EyeIcon, XCircleIcon,
  ArrowDownIcon,
  ArrowUpIcon,
} from '@heroicons/vue/24/outline';
import { defineProps, defineEmits } from 'vue';
import AddVariable from './workspace_components/workflows/card_components/AddVariable.vue';
import AddDV from './workspace_components/workflows/card_components/AddDV.vue';
import Multiselect from 'vue-multiselect';
import RadioGroup from './workspace_components/workflows/task_cards/RadioGroup.vue';
import 'vue-multiselect/dist/vue-multiselect.css';
import useEventsBus from '@/stores/eventBus';
import { useWorkflowEditorStore } from '@/stores/workflowEditorStore';
import { useTaskDefinerStore } from '../stores/taskDefinerStore'; // Adjust the import path as needed
import useNewEventBus from "@/stores/eventNewBus";

import WorkflowEditor from './workspace_components/workflows/WorkflowEditor.vue';

const workflowEditorStore = useWorkflowEditorStore();
const taskDefinerStore = useTaskDefinerStore();
const eventBus = useEventsBus();
const { emitNew, on, off, offAll } = useNewEventBus();
import useEmitter from '@/composables/useEmitter';

const filteredElements = computed(() => {
  return props.elements.filter(element => {
    if (element.onlyShowWhen) {
      const dependency = props.elements.find(el => el.id === element.onlyShowWhen.id);
      return dependency && dependency.value === element.onlyShowWhen.value;
    }
    return true;
  });
});

const csvData = ref([{ "hi": "there" }, { "hi": "there" }]);
const showModal = ref(false);
const showTextModal = ref(false);
const previewContent = ref(false);
// const headers = ref([]);

const emitter = useEmitter();

const combinedTextLength = (row) => {
  return row.reduce((total, item) => total + (item.text ? item.text.length : 0), 0);
}

const getCombinedText = (row) => {
  return row.map(item => item.text).join(' ');
}

const previewText = (text) => {
  previewContent.value = text;
  showTextModal.value = true;
};


const closeTextModal = () => {
  showTextModal.value = false;
};


const truncate = (text, length = 50) => {
  return text.length > length ? text.substring(0, length) + "..." : text;
};



const generateDVPreviewText = (targetDv, csvData) => {

  var dvValue = [];
  // var testString = "";

  for (const segment of targetDv.value) {
    if (segment.type == 'static') {
      dvValue.push({ text: segment.value, color: 'normal' });
    } else if (segment.type == 'dynamic' && segment.nodeReference == null) {
      // console.log('the task deifner is ' , taskDefinerStore.csvData);
      dvValue.push({ text: csvData[segment.value], color: 'green' });
      // testString += taskDefinerStore.csvData[0][segment.value];
    } else {

      const targetType = workflowEditorStore.cards.find(cards => cards.id == segment.nodeReference).type.__name;
      console.log('the name is ', targetType);
      switch (targetType) {
        case "WebScraper":
          dvValue.push({ text: '[meta title: META TITLE] | [meta description: META DESCRIPTION | [webpage content: WEBPAGE CONTENT]', color: 'blue' });
          break;

        case "ArticleFetcher":
          dvValue.push({ text: `{Article 1: [publisher: Publisher1] [title: Title1] [description: Description1]} {Article 2: [publisher: Publisher2] [title: Title2] [description: Description2]} {Article 3: [publisher: Publisher3] [title: Title3] [description: Description3]}`, color: 'blue' });
          break;

        case "IGScraper":
          dvValue.push({ text: `[{"inputUrl":"https://www.instagram.com/post","Caption":"CAPTION 1"."Caption":"CAPTION 1"} {"inputUrl":"https://www.instagram.com/post","Caption":"CAPTION 2"} {"inputUrl":"https://www.instagram.com/post","Caption":"CAPTION 3"}]`, color: 'blue' });
          break;

        case "GPTOutputGenerator":

          dvValue.push({ text: `GPT Output`, color: 'blue' });
          break;


        case "GoogleSheetFormulaTask":
          dvValue.push({ text: `Evaluated Cell`, color: 'blue' });
          break;

          case "ImageScalerTask":
          dvValue.push({ text: `https://imageoutput.com`, color: 'blue' });
          break;


          case "ClaudeOutputGenerator":
          dvValue.push({ text: `Claude Output`, color: 'blue' });
          break;


          case "AISignupTask":
          dvValue.push({ text: `Job fulfilled`, color: 'blue' });
          break;


        default:
          let dvString = "";
          let targetReference = workflowEditorStore.cards.find(cards => cards.id == segment.nodeReference).options.find(element => element.hasAddVariable && element.value.length > 0) ;


          processDvSegment(targetReference, csvData, dvValue)


      }
    }
  }

  return dvValue;
}

function processDvSegment(segment, csvData, dvValue) {
  for (var dvSegment of segment.value) {
    if (dvSegment.type == 'static') {
      dvValue.push({ text: dvSegment.value, color: 'blue' });
    } else if (dvSegment.type == 'dynamic' && dvSegment.nodeReference == null) {
      dvValue.push({ text: csvData[dvSegment.value], color: 'blue' });
    } else if (dvSegment.type == 'dynamic' && dvSegment.nodeReference != null) {
      let nestedReference = workflowEditorStore.cards.find(cards => cards.id == dvSegment.nodeReference)
        .options.find(element => element.hasAddVariable && element.value.length > 0);
      processDvSegment(nestedReference, csvData,dvValue); // Recursive call
    }
  }
}

const updateFormDat = async (cardObj) => {
  // propgates the changes from the undo/redo result back to the form element
  console.log('triggered the updateForm with card id ', cardObj.id);
  console.log('the props is ' , props.card);
  if (props.card.id === cardObj.id) {
    console.log('it got into here ', cardObj.id);
    console.log('the options is ', cardObj.options);

    // props.elements = cardObj.options;
    for (let i = 0; i < cardObj.options.length; i++) {
      if (props.elements[i].value !== cardObj.options[i].value) {
        props.elements[i].value = cardObj.options[i].value;
      }
    }
  }


  // no wonder all odd indexed cards weren't working.
//   // Turn off the handler after use
//   await emitter.off('updateForm', updateFormDat);
//   // Turn off the handler after use
//  await  emitter.on('updateForm', updateFormDat);


}
emitter.on('updateForm', updateFormDat);


onBeforeUnmount(() => {

  emitter.off('updateForm', updateFormDat);

  // offAll('updateForm');
  offAll('saveWorkflow')
})

const togglePreviewModal = (card) => {


  const targetCard = workflowEditorStore.cards.find(cards => cards.id == card.id);


  const targetDV = targetCard.options.find(dv => dv.hasAddVariable);

  console.log('the target dv is ', targetDV.value);

  //  var finalString = generateDVPreviewText(targetDV);

  const finalDvRes = taskDefinerStore.csvData.map(row => {
    return generateDVPreviewText(targetDV, row)
  }, targetDV);


  headers.value = [targetCard.name];

  csvData.value = finalDvRes;




  console.log('the final result is', finalDvRes);

  showModal.value = true;
}

const closeModal = () => {
  showModal.value = false;
}

const headers = ref(['hi']);

const dynamicReference = ref(null);

// Define the properties that the component can receive
const props = defineProps({
  heading: {
    type: String,
    required: true,
  },
  subheader: {
    type: String,
    required: true,
  },
  icon: {
    type: [Object, Function],
    required: true,
  },
  elements: {
    type: Array,
    required: true,
  },
  cards: Array, // Accept the cards prop
  card: {
    type: Object,
    required: true,
  },


});


const processCsvChange = () => {
  // console.log('it got to the card template', dynamicReference.value);
  dynamicReference.value[0].updateCSVWhitelist();
}


defineExpose({ processCsvChange })

const handleCsvChange = (event) => {
  console.log('Received CSV Change Event:', event);
  // Handle the event and say hi
  console.log('Hi');
};

const emit = defineEmits(['remove', 'rename', 'duplicate', 'update-form']);

const variables = ref(['Variable 1', 'Variable 2', 'Variable 3']);



const handleMultiSelectChange = () => {
  // this ensures that for multi select inputs, we save states before adding a tag
  emit('input');
  console.log('the multi select has changed');
}


watch(
  () => eventBus.bus.value.get('triggerPreview'),
  async (val) => {

    if (props.card.id == val[0]) {
      togglePreviewModal(props.card);

    }

  }
);


const emitMouseOver = () => {

  eventBus.emit('range:in')

}

const emitMouseOut = () => {
  eventBus.emit('range:out')
}


// Print the cards array
onMounted(() => {

  console.log("Cards array:", props.cards);
});


</script>

<style lang="scss">
.multiselect__tag {
  color: rgb(255, 255, 255);
  font-size: 01rem;

}

.multiselect__tags {
  background: (0, 0, 0, 0);
  border: 0px;
}






.multiselect__option--disabled {
  background: red;
  color: rgba(var(--vs-primary), 1);
  font-style: italic;
}

.multiselect__option--highlight {
  background-color: rgba(var(--vs-primary), 1);
  color: rgb(255, 255, 255);
  font-size: 0.9rem;
  height: 1rem;
}

.multiselect__content {
  background: #212936;
  color: #ffffff;
  font-size: 0.9rem;
}

.multiselect__single {
  height: 0.8rem;
  font-size: 01rem;
  color: #6e2a2a;
}

.custom-select option {
  background-color: rgba(255, 255, 255, 0.05);
  /* Match the bg-white/5 */
  color: #374151;
  /* Match the text color */
}
</style>
