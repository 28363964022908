<template>
    <TransitionRoot as="template" :show="open">
      <Dialog class="relative z-10" @close="emit('update:open', false)">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
        </TransitionChild>
  
        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div
            class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-gray-900 ring-1 ring-inset ring-white/10 focus:ring-2 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
              >
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PencilSquareIcon class="h-6 w-6 text-indigo-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle
                      as="h3"
                      class="text-base font-semibold leading-6 text-white"
                      >{{ title }}</DialogTitle
                    >
                    <div class="mt-2">
                      <p class="text-sm text-gray-400">{{ message }}</p>
                      <div class="sm:col-span-4 mt-4">
                        <div class="mt-2">
                          <div class="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                            <select v-model="selectedSpreadsheetId" @change="onSpreadsheetChange" class="flex-1 border-0 bg-transparent py-1.5 pl-2 text-white focus:ring-0 sm:text-sm sm:leading-6">
                              <option v-for="spreadsheet in spreadsheets" :key="spreadsheet.id" :value="spreadsheet.id">
                                {{ spreadsheet.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <!-- Child Sheets Dropdown -->
                        <div class="mt-4">
                          <div class="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                            <select v-model="selectedChildSheetId" class="flex-1 border-0 bg-transparent py-1.5 pl-2 text-white focus:ring-0 sm:text-sm sm:leading-6">
                              <option v-for="childSheet in childSheets" :key="childSheet.id" :value="childSheet.id">
                                {{ childSheet.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    class="inline-flex w-full justify-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 sm:ml-3 sm:w-auto"
                    @click="onConfirm"
                  >
                    {{ confirmButtonText }}
                  </button>
                  <button
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-gray-700 px-3 py-2 text-sm font-semibold text-gray-300 shadow-sm ring-1 ring-inset ring-gray-600 hover:bg-gray-600 sm:mt-0 sm:w-auto"
                    @click="onCancel"
                    ref="cancelButtonRef"
                  >
                    {{ cancelButtonText }}
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  import { defineProps, defineEmits } from "vue";
  import {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  } from "@headlessui/vue";
  import { PencilSquareIcon } from "@heroicons/vue/24/outline";
  
  const props = defineProps({
    title: {
      type: String,
      default: "Select Spreadsheet",
    },
    message: {
      type: String,
      default: "Please select a spreadsheet from the list.",
    },
    confirmButtonText: {
      type: String,
      default: "Select",
    },
    cancelButtonText: {
      type: String,
      default: "Cancel",
    },
    open: {
      type: Boolean,
      default: true,
    },
    spreadsheets: {
      type: Array, // Expecting a list of spreadsheets
      required: true,
    },
    childSheets: {
      type: Array, // Expecting a list of child sheets
      required: true,
    },
  });
  
  const emit = defineEmits(["update:open", "confirm", "cancel", "spreadsheet-changed"]);
  
  const selectedSpreadsheetId = ref('');
  const selectedChildSheetId = ref('');
  
  const onConfirm = () => {

      // Find the selected spreadsheet by its ID
  const selectedSpreadsheet = props.spreadsheets.find(spreadsheet => spreadsheet.id === selectedSpreadsheetId.value)
  const selectedChildSheet = props.childSheets.find(sheet => sheet.id == selectedChildSheetId.value );
if (selectedSpreadsheet) {
  emit("confirm", {
    selectedSpreadsheetId: selectedSpreadsheet.id,
    selectedSpreadsheetName: selectedSpreadsheet.name, // Include the name
    selectedChildSheetId: selectedChildSheetId.value,
    selectedChildSheetName: selectedChildSheet.name
  });
} 

    // emit("confirm", { selectedSpreadsheetId: selectedSpreadsheetId.value, selectedChildSheetId: selectedChildSheetId.value });
    emit("update:open", false);
  };
  
  const onCancel = () => {
    emit("cancel");
    emit("update:open", false);
  };
  
  // Emit event when the spreadsheet is changed
  const onSpreadsheetChange = () => {
    emit("spreadsheet-changed", selectedSpreadsheetId.value);
    selectedChildSheetId.value = ''; // Reset the child sheet selection
  };
  </script>
  