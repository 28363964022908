<!-- Usage Example -->
<template>
    <TaskCardThumbnailTemplate 
      :heading="formHeading" 
      :description="description" 
      :icon="formIcon"
    />
  </template>
  
  <script setup>
  import TaskCardThumbnailTemplate from '../../../TaskCardThumbnailTemplate.vue'
  import { CommandLineIcon} from "@heroicons/vue/24/outline"
  
  // Define the properties for the form heading
  const formHeading = "Newsletter Sign-up"
  const description = "Sign up for an arbitrary newsletter."
  const formIcon = CommandLineIcon
  
  </script>
  