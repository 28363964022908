<template>
  <nav class="flex items-center justify-between border-t border-gray-700 bg-gray-900 px-4 py-3 sm:px-6" aria-label="Pagination">
    <div class="hidden sm:block">
      <p class="text-sm text-gray-400">
        Showing
        {{ ' ' }}
        <span class="font-medium text-white">{{ start }}</span>
        {{ ' ' }}
        to
        {{ ' ' }}
        <span class="font-medium text-white">{{ end }}</span>
        {{ ' ' }}
        of
        {{ ' ' }}
        <span class="font-medium text-white">{{ totalItems }}</span>
        {{ ' ' }}
        results
      </p>
    </div>
    <div class="flex flex-1 justify-between sm:justify-end">
      <button @click="previousPage" :disabled="currentPage === 1" class="relative inline-flex items-center rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-white/10 transition duration-150 hover:bg-gray-700 focus-visible:outline-offset-0">Previous</button>
      <button @click="nextPage" :disabled="currentPage === totalPages" class="relative ml-3 inline-flex items-center rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-white/10 transition duration-150 hover:bg-gray-700 focus-visible:outline-offset-0">Next</button>
    </div>
  </nav>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  currentPage: {
    type: Number,
    required: true
  },
  totalItems: {
    type: Number,
    required: true
  },
  itemsPerPage: {
    type: Number,
    required: true
  }
});

const emit = defineEmits(['updatePage']);

const totalPages = computed(() => Math.ceil(props.totalItems / props.itemsPerPage));
const start = computed(() => (props.currentPage - 1) * props.itemsPerPage + 1);
const end = computed(() => Math.min(props.currentPage * props.itemsPerPage, props.totalItems));

const previousPage = () => {
  if (props.currentPage > 1) {
    emit('updatePage', props.currentPage - 1);
  }
};

const nextPage = () => {
  if (props.currentPage < totalPages.value) {
    emit('updatePage', props.currentPage + 1);
  }
};
</script>
