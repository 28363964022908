<template>
  <!-- Settings forms -->
  <div class=" py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20 ">
    <div class="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none px-8">
      <div>
        <h2 class="text-base font-semibold leading-7 text-white">Profile</h2>
        <p class="mt-1 text-sm leading-6 text-gray-400">Your profile information.</p>

        <dl class="mt-6 space-y-6 divide-y divide-white/5 border-t border-white/10 text-sm leading-6">
          <div class="pt-6 sm:flex">
            <dt class="font-medium text-white sm:w-64 sm:flex-none sm:pr-6">Full name</dt>
            <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div class="text-white">John Doe</div>
              <button type="button" class="font-semibold text-indigo-400 hover:text-indigo-300">Update</button>
            </dd>
          </div>
          <div class="pt-6 sm:flex">
            <dt class="font-medium text-white sm:w-64 sm:flex-none sm:pr-6">Email address</dt>
            <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div class="text-white">johndoe@example.com</div>
              <button type="button" class="font-semibold text-indigo-400 hover:text-indigo-300">Update</button>
            </dd>
          </div>
        </dl>
      </div>

      <div>
        <h2 class="text-base font-semibold leading-7 text-white">Integrations</h2>
        <p class="mt-1 text-sm leading-6 text-gray-400">Connect applications to your account.</p>

        <dl class="mt-6 space-y-6 divide-y divide-white/5 border-t border-white/10 text-sm leading-6">
          <div class="pt-6 sm:flex">
            <dt class="font-medium text-white sm:w-64 sm:flex-none sm:pr-6">Google Sheets</dt>
            <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div class="text-white">
                {{ oauthEmail !== '' ? oauthEmail : 'Not connected' }}
              </div>
              <div class="flex space-x-4">
                <button type="button" @click="linkToGoogle"
                  class="font-semibold text-indigo-400 hover:text-indigo-300">Update</button>
                <button type="button" @click="disconnectGoogle"
                  class="font-semibold text-indigo-400 hover:text-indigo-300">Disconnect</button>
              </div>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>


<script setup>

import {onMounted, ref} from 'vue';
import { gapi } from 'gapi-script';
import { useTaskDefinerStore } from "@/stores/taskDefinerStore";
import { useWorkflowEditorStore } from '@/stores/workflowEditorStore';
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
import { useUserStore } from '@/stores/user';
import axios from 'axios';

const taskDefinerStore = useTaskDefinerStore();
const workflowEditorStore = useWorkflowEditorStore(); // Use the workflowEditor store
const userStore = useUserStore();
const userToken = userStore.getToken

const oauthEmail = ref('');

async function registerRefreshToken (code)  {
  const userStore = useUserStore();
    const userToken = userStore.getToken
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/auth/saveOAuth`, {
        code: code
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`,
        }
      });

      console.log('response from saving oauth is ' , response.data);

}

async function linkToGoogle() {


  try {
    // await loadGapiInsideDOM(); // Load gapi script into the DOM
    gapi.load('auth2', () => {
      const auth2 = gapi.auth2.init({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        scope: 'https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/spreadsheets',
      });


    

      auth2.grantOfflineAccess().then(async (response) => {
        console.log('the response code is ' , response.code);
        // if (response.code) {
        //   // Send the authorization code to the backend to exchange for tokens
          await registerRefreshToken(response.code);

          await updateConnectedEmail();


        // } else {
        //   console.error('Failed to obtain authorization code');
        // }
      });
    });
  } catch (error) {
    console.error('Error loading Google API script or during Google Sign-In:', error);
  }
}


const updateConnectedEmail = async () => { 
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/auth/getUserEmailFromSavedToken`, {
          // code: code
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`,
          }
        });

        console.log('the email data is ' , response.data); 


        oauthEmail.value = response.data.email;

}
onMounted(async () => {

  await updateConnectedEmail();

});

</script>

<style lang="scss" scoped></style>
