<template>
  <div class="border-white/10 flex flex-row overflow-x-auto h-full">
    <div v-if="csvData && csvData.length > 0" class="w-full">
      <div class="table-container relative">
        <table class="w-full whitespace-nowrap text-left">
          <colgroup>
            <col class="min-w-0" />
            <col class="min-w-0" />
            <col class="min-w-0" />
            <col class="min-w-0" />
            <col class="min-w-0" />
            <col class="min-w-0" />
          </colgroup>
          <thead class="border-b border-white/10 text-sm leading-6 text-white sticky top-0 bg-gray-800 z-10">
            <tr>
              <th @click="toggleSort('Status')" class="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8 cursor-pointer">
                <div :class="[statusColors.Gray, 'flex-none rounded-full p-1']">
                  <div class="h-1.5 w-1.5 rounded-full bg-current"></div>
                </div>
                <span v-if="sortField === 'Status'">
                  <ArrowDownIcon v-if="sortOrder === 'asc'" class="inline ml-2 h-3 w-3 text-gray-300" />
                  <ArrowUpIcon v-if="sortOrder === 'desc'" class="inline ml-2 h-3 w-3 text-gray-300" />
                </span>
              </th>
              <th v-for="(header, index) in allHeaders" :key="index"
                class="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-8">
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-white/5">
            <tr v-for="(row, rowIndex) in csvData" :key="rowIndex">
              <td class="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8 text-sm leading-6 text-gray-300 max-w-[300px]">
                <div class="flex items-center justify-end gap-x-2 sm:justify-start">
                  <div :class="[row.statusClass, 'flex-none rounded-full p-1']">
                    <div class="h-1.5 w-1.5 rounded-full bg-current"></div>
                  </div>
                </div>
              </td>
              <td v-for="(header, index) in allHeaders" :key="index"
                class="py-4 pl-0 pr-4 text-sm leading-6 text-gray-300 sm:pr-8 lg:pr-8 max-w-[300px] relative">
                <span v-if="row[header] && row[header].length > 50" class="flex flex-row">
                  <button @click="previewText(row[header])"
                    class="rounded-full p-1 text-white ring-1 ring-inset ring-white/10 hover:bg-gray-700 focus-visible:outline-offset-0">
                    <EyeIcon class="h-4 w-4" aria-hidden="true" />
                  </button>
                  <span class="ml-6 block truncate">{{ row[header] }}</span>
                </span>
                <span v-else>{{ row[header] || "-" }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else class="flex items-center justify-center h-full w-full">
      <div class="px-4 py-4 sm:px-6 lg:px-8 text-center">
        <a href="/editor" type="button"
          class="relative block w-full rounded-lg border-2 border-dashed border-gray-700 p-12 hover:border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-offset-2">
          <svg class="mx-auto h-12 w-12 text-gray-500" stroke="currentColor" fill="none" viewBox="0 0 48 48"
            aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6" />
          </svg>
          <span class="mt-2 block text-sm font-semibold text-gray-100">Run the workflow to view a preview!</span>
        </a>
      </div>
    </div>

    <!-- Modal for previewing text -->
    <transition name="fade">
      <div v-if="showTextModal" class="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50"
        @keydown.esc="escHandler" @click.self="closeTextModal">
        <div class="bg-gray-800 rounded-lg p-6 w-full max-w-lg max-h-[70vh] overflow-auto" @click.stop>
          <div class="flex justify-between items-center mb-4">
            <h3 class="text-lg font-semibold text-white">Full Text</h3>
            <button @click="closeTextModal" class="text-gray-400 hover:text-white">
              <span class="sr-only">Close</span>
              <XCircleIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div class="text-sm text-gray-300">{{ previewContent }}</div>
        </div>
      </div>
    </transition>
  </div>
</template>



<script setup>
import { ref, computed, onMounted, onBeforeUnmount, onUnmounted } from "vue";
import { useTaskDefinerStore } from "@/stores/taskDefinerStore";
import { useRoute } from "vue-router";
import { useWorkflowEditorStore } from "@/stores/workflowEditorStore";
import { useUserStore } from '@/stores/user';
import { io } from 'socket.io-client';

import {
  XCircleIcon,
  EyeIcon,
  ArrowDownIcon,
  ArrowUpIcon,
} from "@heroicons/vue/24/outline";
import statusColors from "../../../data/constants.js";
import axios from 'axios';
import useNewEventBus from '@/stores/eventNewBus';
import useEventsBus from '@/stores/eventBus';

const { emitNew, on, off, offAll } = useNewEventBus();

import ProgressBar from '../ProgressBar.vue';

const taskDefinerStore = useTaskDefinerStore();
const workflowEditorStore = useWorkflowEditorStore();
const userStore = useUserStore();
const { emit } = useEventsBus();

const { bus } = useEventsBus();


async function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


const socketIOConnect = async () => {
  console.log('socket connect click');
  const userToken = userStore.getToken

  var userData = await userStore.verifyToken(userToken);


  const workflowId = route.params.workflowId;

  var jobId = route.params.jobId;

  console.log('the job id is ', jobId);

  const response = await axios.post(`${process.env.VUE_APP_API_URL}/user/getWorkflowById`, {
    workflowId: workflowId
  }, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userToken}`,
    }
  });



  workflowEditorStore.setWorkflowName(response.data.workflowName);



  taskCards.value = response.data.steps.map(step => step.stepName);

  taskCards.value = response.data.steps.reduce((acc, step) => {

    if (step.stepType == "AISignUpTask") {
      acc.push(`${step.stepName} form elements`);
      acc.push(`${step.stepName} screenshot`);
      acc.push(`${step.stepName} result`);
      acc.push(`${step.stepName} bytes`);

  } else {

    acc.push(step.stepName);

    if (step.stepType === "GPTOutputGenerator") {
      if (step.options.promptColumn) {
        acc.push(`${step.stepName} prompt`);
      }
      acc.push(`${step.stepName} usage`);

      }


    }

    return acc;
  }, []);


  console.log(response.data.steps);


  csvHeaders.value = response.data.csvData;

  if (!jobId) {
    const jobResponse = await axios.post(`${process.env.VUE_APP_API_URL}/user/getLastJob`, {
      workflowId: workflowId
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`,
      }
    });


    console.log('the last job with workflow is ', jobResponse.data._id);
    jobId = jobResponse.data._id;

  }

  console.log(`socket io is with jobid ${jobId}`);
  socket.io.opts.query = {
    userId: jobId,
  };

  socket.connect();

  console.log('connected to socket io ');
  // socket.emit('example_event', { message: 'Hello from Vue.js with Composition API!' });


  socket.on('csv_preview_update', async (data) => {
    let csvDataResult = await parseCsvFromSocket(data);

    csvData.value = csvDataResult;

    console.log('updated csv preview in real time ');
  });


  // Optionally, handle disconnect event
  socket.on('disconnect', () => {
    console.log('Disconnected from server');
  });


}


// Define reactive variable to hold the response message
const responseMessage = ref('');

// Function to handle incoming messages from the server
const handleServerMessage = (data) => {
  console.log('Received data from server:', data);
  responseMessage.value = data.message;
};

// Function to send a message to the server
const sendMessage = () => {
  socket.emit('example_event', { message: 'Hello from Vue.js with Composition API!' });
};


const route = useRoute();
const progressPercentage = ref(0)

const statuses = {
  Completed: statusColors.Green,
  Pending: statusColors.Amber,
  Error: statusColors.Rose,
};

function getRandomStatus() {
  const keys = Object.keys(statuses);
  return keys[Math.floor(Math.random() * keys.length)];
}

const csvData = ref(
  taskDefinerStore.csvData.map((row) => {
    const status = 'Pending';
    return { ...row, Status: status, statusClass: statuses[status] };
  })
);


const csvHeaders = ref([]);
// const csvHeaders = ref(taskDefinerStore.headers);

const taskCards = ref([]);

const allHeaders = computed(() => [...csvHeaders.value, ...taskCards.value]);

const showTextModal = ref(false);
const previewContent = ref("");
const previewText = (text) => {
  previewContent.value = text;
  showTextModal.value = true;
};
const closeTextModal = () => {
  showTextModal.value = false;
};
const escHandler = (event) => {
  if (event.key === "Escape" && showTextModal.value) {
    closeTextModal();
  }
};

const sortField = ref("");
const sortOrder = ref("asc");
const toggleSort = (field) => {
  if (sortField.value === field) {
    if (sortOrder.value === "asc") {
      sortOrder.value = "desc";
    } else if (sortOrder.value === "desc") {
      sortField.value = "";
      sortOrder.value = "asc";
    } else {
      sortOrder.value = "asc";
    }
  } else {
    sortField.value = field;
    sortOrder.value = "asc";
  }
};

const sortedData = computed(() => {
  if (!sortField.value) return csvData.value;
  return [...csvData.value].sort((a, b) => {
    if (a[sortField.value] < b[sortField.value])
      return sortOrder.value === "asc" ? -1 : 1;
    if (a[sortField.value] > b[sortField.value])
      return sortOrder.value === "asc" ? 1 : -1;
    return 0;
  });
});

let socket;
onMounted(async () => {

  const userToken = userStore.getToken

  var userData = await userStore.verifyToken(userToken);


  const workflowId = route.params.workflowId;

  var jobId = route.params.jobId;

  console.log('the job id is ', jobId);

  const response = await axios.post(`${process.env.VUE_APP_API_URL}/user/getWorkflowById`, {
    workflowId: workflowId
  }, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userToken}`,
    }
  });


  var jobRes = await axios.post(`${process.env.VUE_APP_API_URL}/user/getJobByWorkflowId`, {
    workflowId: workflowId
  }, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userToken}`,
    }
  });


  workflowEditorStore.setWorkflowName(response.data.workflowName);



  taskCards.value = response.data.steps.map(step => step.stepName);

  taskCards.value = response.data.steps.reduce((acc, step) => {

    if (step.stepType == "AISignUpTask") {
      acc.push(`${step.stepName} form elements`);
      acc.push(`${step.stepName} screenshot`);
      acc.push(`${step.stepName} result`);
      acc.push(`${step.stepName} bytes`);

    } else {
      if (step.stepType === "GPTOutputGenerator" || step.stepType == "ClaudeOutputGenerator" || step.stepType == 'LlamaOutputGeneratorTask') {
        if (step.options.promptColumn) {
          acc.push(`${step.stepName} prompt`);
        }

        acc.push(step.stepName);

        acc.push(`${step.stepName} usage`);

      } else {
        acc.push(step.stepName);

      }
    }

    return acc;
  }, []);


  console.log(response.data.steps);


  csvHeaders.value = response.data.csvData;


  if (jobRes.data.mode =="Google Sheets") {


// if (!taskDefinerStore.googleHeaders) {
  let googleCsvData;
   googleCsvData = await axios.post(`${process.env.VUE_APP_API_URL}/user/getSpreadsheet`, {
selectedSpreadsheetId:response.data.spreadsheetId,
selectedChildSheetId: response.data.sheetId
}, {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${userToken}`,
  }
});
console.log('the google csv data is ' , googleCsvData)

  taskDefinerStore.setGoogleHeaders(googleCsvData.data.data[0]);

// }

// console.log('the headers are ' , taskDefinerStore.googleHeaders )
csvHeaders.value  = taskDefinerStore.googleHeaders;
}



  if (!jobId) {
    const jobResponse = await axios.post(`${process.env.VUE_APP_API_URL}/user/getLastJob`, {
      workflowId: workflowId
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`,
      }
    });


    console.log('the last job with workflow is ', jobResponse.data);

    if (jobResponse.data.mode =="Google Sheets") {


      // if (!taskDefinerStore.googleHeaders) {
        let googleCsvData;
         googleCsvData = await axios.post(`${process.env.VUE_APP_API_URL}/user/getSpreadsheet`, {
      selectedSpreadsheetId:response.data.spreadsheetId,
      selectedChildSheetId: response.data.sheetId
    }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`,
        }
      });
      console.log('the google csv data is ' , googleCsvData)

        taskDefinerStore.setGoogleSheetData(googleCsvData.data.data);

      // }

      // console.log('the headers are ' , taskDefinerStore.googleHeaders )
      csvHeaders.value  = taskDefinerStore.googleHeaders;
    }
    jobId = jobResponse.data._id;

  }

  console.log(`socket io is with jobid ${jobId}`);

  socket = io(`${process.env.VUE_APP_API_URL}`, {
    withCredentials: true,
    query: { userId: jobId },  // Pass any necessary query parameters
  });

  console.log('connected to socket io');

  socket.on('csv_preview_update', async (data) => {
    let csvDataResult = await parseCsvFromSocket(data);
    csvData.value = csvDataResult;
    console.log('updated csv preview in real time');
  });

  // Optionally, handle disconnect event
  socket.on('disconnect', () => {
    console.log('Disconnected from server');
  });

  // retrieve the workflow by id, then map all node names to get the node headers
  // csv headers will also be here.
  // now we create a new backend method that gets the last job assosiated with said workflow
  // these all get store in the respective pinia store.
  try {
    let csvDataResult = await fetchPartialCsv(jobId, jobRes.data.status);
    csvData.value = csvDataResult;

  } catch (error) {

  }

  window.addEventListener("keydown", escHandler);
  // startPolling(jobId);
});


onUnmounted(() => {
  socket.disconnect();
  // socketService.disconnect();
  // socket.disconnect();
  console.log('preview disconnected client');

});
onBeforeUnmount(() => {

  socket.disconnect();
  // socket.disconnect();

  console.log('preview disconnected client');


  offAll('setWorkflowName');
  // await emitNew('saveWorkflow');
  window.removeEventListener("keydown", escHandler);
  // stopPolling();
});

// Drawer state management
const drawerOpen = ref(false);
const openDrawer = () => {
  drawerOpen.value = true;
};
const closeDrawer = () => {
  drawerOpen.value = false;
};

const jobs = [
  {
    deployment: "14:12:21, Jul 2 2024",
    id: 1,
    rows: "44/45",
    status: "Pending",
    href: self.id,
  },
  {
    deployment: "14:12:21, Jul 2 2024",
    id: 2,
    rows: "48/50",
    status: "Completed",
    href: self.id,
  },
  {
    deployment: "9:31:32, Jul 1 2024",
    id: 3,
    rows: "33/334",
    status: "Aborted",
    href: self.id,
  },
];

const activityItems = [
  {
    user: {
      name: "Michael Foster",
      imageUrl:
        "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    projectName: "ios-app",
    commit: "2d89f0c8",
    branch: "main",
    date: "1h",
    dateTime: "2023-01-23T11:00",
  },
  // More items...
];


function fillMissingKeys(obj, keys) {
  let keyAdded = false;
  let totalKeysAdded = 0;

  for (var key of keys) {
    if (!(key in obj)) {
      obj[key] = "";
      keyAdded = true;
      totalKeysAdded++;
    }

  }
  return { updatedObj: obj, keyAdded, totalKeysAdded };
}


async function parseCsvFromSocket(socketData) {
  // console.log('fetching the partial csv ' , jobId);
  try {

    // Handle response here. Assuming the response contains the partial CSV data.
    // console.log("Received CSV Data:", JSON.parse(socketData));

    let csvData = JSON.parse(socketData);
    let keyAddedCount = 0;
    let totalKeyAdded = 0;
    // Process each row
    csvData = csvData.map(row => {
      const { updatedObj, keyAdded, totalKeysAdded } = fillMissingKeys(row, allHeaders.value);
      if (keyAdded) keyAddedCount++;
      totalKeyAdded += totalKeysAdded;
      const status = keyAdded ? "Pending" : "Completed"
      return { ...updatedObj, Status: status, statusClass: statuses[status], keyAdded };
    });


    if (keyAddedCount == 0) {
      // // console.log('the job is done stop polling ');
      // stopPolling();
      progressPercentage.value = 100;
    } else {
      // subtract 3 cause 3 keys are just frontend keys not part of task (like the status class and status obj)
      var numberOfTasks = (Object.keys(csvData[0]).length - 3 - Object.keys(csvHeaders.value).length) * csvData.length;


      var numberOfDoneTasks = numberOfTasks - totalKeyAdded;

      progressPercentage.value = (numberOfDoneTasks / numberOfTasks) * 100;

    }


    console.log('progress is', progressPercentage.value);
    // console.log('now it is ' , csvData);

    return csvData;

  } catch (error) {
    console.log('error fetching csv data from mongo', error);
    return taskDefinerStore.csvData.map((row) => {
      const status = 'Pending';
      return { ...row, Status: status, statusClass: statuses[status] };
    })

    // return [];
  }
}



async function fetchPartialCsv(jobId, jobStatus) {
  console.log('fetching the partial csv ', jobId);
  try {
    // Checking if jobId is provided
    if (!jobId) {
      throw new Error("Job ID is required for fetching CSV data.");
    }

    const response = await axios.post(`${process.env.VUE_APP_API_URL}/user/getPartialCsv`, {
      jobId: jobId  // Sending jobId in the request body
    }, {
      headers: {
        'Authorization': `Bearer ${userStore.getToken}`,
      }
    });

    // Handle response here. Assuming the response contains the partial CSV data.
    console.log("Received CSV Data:", JSON.parse(response.data.csvData));

    // update headers to be the preview headers once its completed.

    if (jobStatus == 'completed') {
      csvHeaders.value = Object.keys(JSON.parse(response.data.csvData)[0]) 
      taskCards.value = [];
    }
    let csvData = JSON.parse(response.data.csvData);
    let keyAddedCount = 0;
    let totalKeyAdded = 0;
    // Process each row
    csvData = csvData.map(row => {
      const { updatedObj, keyAdded, totalKeysAdded } = fillMissingKeys(row, allHeaders.value);
      if (keyAdded) keyAddedCount++;
      totalKeyAdded += totalKeysAdded;
      let status;
      if (jobStatus == 'completed') {
        status = "Completed";
      } else {
        status = keyAdded ? "Pending" : "Completed";

      }
      return { ...updatedObj, Status: status, statusClass: statuses[status], keyAdded };
    });


    if (keyAddedCount == 0) {
      // console.log('the job is done stop polling ');
      stopPolling();
      progressPercentage.value = 100;
    } else {
      // subtract 3 cause 3 keys are just frontend keys not part of task (like the status class and status obj)
      var numberOfTasks = (Object.keys(csvData[0]).length - 3 - Object.keys(csvHeaders.value).length) * csvData.length;


      var numberOfDoneTasks = numberOfTasks - totalKeyAdded;

      progressPercentage.value = (numberOfDoneTasks / numberOfTasks) * 100;

    }


    console.log('progress is', progressPercentage.value);
    // console.log('now it is ' , csvData);

    return csvData;

  } catch (error) {
    console.log('error fetching csv data from redis', error);
    return taskDefinerStore.csvData.map((row) => {
      const status = 'Pending';
      return { ...row, Status: status, statusClass: statuses[status] };
    })

    // return [];
  }
}

// Polling setup
let pollingInterval = null;

const startPolling = (targetJob) => {
  console.log('the polling has started');
  const jobId = targetJob;
  console.log('the job id is ', jobId);
  if (!jobId) return;
  console.log('it got here');

  pollingInterval = setInterval(async () => {
    const csvDataResult = await fetchPartialCsv(targetJob);

    // console.log("the csv data is " , csvDataResult);
    // console.log('the csv headers are supposed to be ' , csvHeaders.value);
    csvData.value = csvDataResult;
  }, 2000); // Poll every 2 seconds
};

const stopPolling = () => {
  if (pollingInterval) {
    clearInterval(pollingInterval);
    pollingInterval = null;
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

<style>
.table-container {
  max-height: 80vh;
  /* Adjust this value based on your needs */
  overflow-y: auto;
}
</style>
