<template>
  <div class="flex h-full">
    <!-- Sidebar component for mobile -->
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog class="relative z-50 xl:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0"
          enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100"
          leave-to="opacity-0">
          <div class="fixed inset-0 bg-[gray-900/80]" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full" enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0"
            leave-to="-translate-x-full">
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0"
                enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 ring-1 ring-white/10">
                <div class="flex h-16 shrink-0 items-center">
                  <img class="h-8 w-auto" src="@/assets/AudareMatic-logo.png" alt="Your Company" />
                </div>

                <nav class="flex flex-1 flex-col">
                  <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in navigation" :key="item.name">
                          <router-link :to="item.href" :class="[
                            {
                              'bg-gray-800 text-white':
                                item.href === item.current,
                              'text-gray-400 hover:bg-gray-800 hover:text-white':
                                item.href !== !item.current,
                            },
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                          ]">
                            <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                            {{ item.name }}
                          </router-link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div class="text-xs font-semibold leading-6 text-gray-400">
                        Users
                      </div>
                      <ul role="list" class="-mx-2 mt-2 space-y-1">
                        <li v-for="team in teams" :key="team.name">
                          <router-link :to="team.href" :class="[
                            {
                              'bg-gray-800 text-white':
                                team.href === currentRoute,
                              'text-gray-400 hover:bg-gray-800 hover:text-white':
                                team.href !== currentRoute,
                            },
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                          ]">
                            <span
                              class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">{{
                                team.initial }}</span>
                            <span class="truncate">{{ team.name }}</span>
                          </router-link>
                        </li>
                      </ul>
                    </li>
                    <li class="-mx-6 mt-auto">
                      <router-link to="#"
                        class="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800">
                        <img class="h-8 w-8 rounded-full bg-gray-800"
                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt="" />
                        <span class="sr-only">Your profile</span>
                        <span aria-hidden="true">Tom Cook</span>
                      </router-link>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-[#0F1623] px-6 ring-1 ring-white/5">
        <div class="flex h-16 shrink-0 items-center">
          <a href="/">
            <img class="h-8 w-auto" src="@/assets/AudareMatic-logo.png" alt="Your Company" />
          </a>
        </div>
        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li v-for="item in navigation" :key="item.name">
                  <router-link :to="item.href" :class="[
                    {
                      'bg-gray-800 text-white': item.current,
                      'text-gray-400 transition duration-150 hover:bg-gray-800 hover:text-white': !item.current,
                    },
                    'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                  ]">
                    <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                    {{ item.name }}
                    <!-- ActivityBadge -->
                    <span v-if="item.name === 'Activity'"
                      class="inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset bg-slate-600 text-slate-200  ring-slate-600/20 ml-2">
                      3
                    </span>
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <div class="text-xs font-semibold leading-6 text-gray-400">
                Users
              </div>
              <ul role="list" class="-mx-2 mt-2 space-y-1 w-64 h-80 overflow-y-auto">
                <li v-for="user in users" :key="user.username">
                  <a :href="user.href"
                    :class="[user.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:bg-gray-800 hover:text-white', 'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6']">
                    <span
                      class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                      {{ user.initials }}</span>
                    <span class="truncate">{{ user.fullName }}</span>
                  </a>
                </li>
                <li>
                  <button @click="openModal"
                    class="group flex w-full gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 transition duration-150 hover:bg-gray-800 hover:text-white">
                    <span
                      class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                      <PlusIcon class="h-4 w-4" aria-hidden="true" />
                    </span>
                    <span class="truncate">Add User</span>
                  </button>
                </li>
              </ul>
            </li>

            <div class="-mx-6 mt-auto sticky bottom-0 bg-[#0F1623]">
              <div to="#"
                class="flex items-center justify-between gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white">
                <div class="flex items-center gap-x-4">
                  <img class="h-8 w-8 rounded-full bg-gray-800" :src="avatarUrl" alt="User Avatar" />
                  <span aria-hidden="true">{{ username }}</span>
                  <div
                    class="inline-flex items-center rounded-md gap-1 bg-indigo-400/10 px-2 py-1 text-xs font-medium text-indigo-400 ring-1 ring-inset ring-indigo-400/30">
                    <UsersIcon class="h-4 w-4" />
                    Admin
                  </div>
                </div>
                <button @click="handleLogout" class="h-6 w-6 text-gray-400 hover:text-gray-200">
                  <ArrowLeftStartOnRectangleIcon />
                </button>
              </div>
            </div>

          </ul>
        </nav>
      </div>
    </div>

    <RegistrationModal :open="isModalOpen" @update:open="handleModalClose" @confirm="handleRegistration"
      @cancel="handleModalClose" />

    <!-- Main content area -->
    <div class="flex flex-1 flex-col xl:pl-72 w-screen">
      <header></header>
      <main>
        <router-view></router-view>
        <!-- This will render the matched child route components -->
      </main>
    </div>
  </div>

</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { useUserStore } from '@/stores/user';

import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  ChartBarSquareIcon,
  Cog6ToothIcon,
  FolderIcon,
  GlobeAltIcon,
  ServerIcon,
  SignalIcon,
  XMarkIcon,
  CircleStackIcon,
  ArrowLeftStartOnRectangleIcon,
  PlusIcon,
  WrenchScrewdriverIcon
} from "@heroicons/vue/24/outline";
import {
  Bars3Icon,
  ChevronRightIcon,
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
  UsersIcon
} from "@heroicons/vue/20/solid";

import { useRouter } from 'vue-router';
import axios from 'axios';
import RegistrationModal from "@/components/workspace_components/modals/RegistrationModal.vue";


const isModalOpen = ref(false);

const openModal = () => {
  isModalOpen.value = true;
};

const handleModalClose = () => {
  isModalOpen.value = false;
};

const handleRegistration = async (registrationData) => {
  console.log('Registration data:', registrationData);
  try {
    // Replace with your actual API call
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/auth/register`,
      registrationData,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userStore.getToken}`, // Replace with your actual token retrieval
        }
      }
    );
    console.log('Registration successful:', response.data);
    // Handle successful registration (e.g., show a success message, update UI)
  } catch (error) {
    console.error('Registration failed:', error.response?.data?.message || 'An error occurred');
    // Handle registration error (e.g., show an error message)
  }
};

const users = ref([]);

async function fetchUsers() {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API_URL}/user/getUsers`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userStore.getToken}`,
      }
    });
    console.log(response.data);
    users.value = response.data.map(user => {
      return {
        ...user,
        initials: getInitials(user.fullName),
      };
    });

    console.log(users)
  }

  catch (error) {
    console.error('Error fetching users: ', error.response ? error.response.data : error.message);
  }
}

const username = computed(() => userStore.user?.username || 'Guest');

const userStore = useUserStore();

const router = useRouter();

const sidebarOpen = ref(false);
const route = useRoute();

const avatarUrl = computed(() => {
  const seed = username.value === 'Guest' ? 'random' : username.value;
  return `https://api.dicebear.com/9.x/thumbs/svg?seed=${encodeURIComponent(seed)}`;
});


const handleLogout = () => {
  userStore.logout();
  router.push({ name: 'Login' });
};


const navigation = ref([
  {
    name: "Workflows",
    href: "/workspace/workflows",
    icon: CircleStackIcon,
    current: false,
  },
  {
    name: "Activity",
    href: "/workspace/activity",
    icon: FolderIcon,
    current: false,
  },
  {
    name: "Usage",
    href: "/workspace/usage",
    icon: ChartBarSquareIcon,
    current: false,
  },
  {
    name: "Settings",
    href: "/workspace/settings",
    icon: Cog6ToothIcon,
    current: false,
  },
  {
    name: "Admin Panel",
    href: "/workspace/admin",
    icon: WrenchScrewdriverIcon,
    current: false,
  },
]);

const teams = ref([
  { id: 1, name: "Evaristo Gualberto", href: "#", initial: "EG", current: false },
  { id: 2, name: "Adrien Jean-Luc", href: "#", initial: "AJ", current: false },
  { id: 3, name: "Brock Garnet", href: "#", initial: "BG", current: false },
]);

const currentRoute = computed(() => route.path);


function getInitials(fullName) {

  if (!fullName) { return '' };

  const nameParts = fullName.split(' ').filter(part => part);
  const initials = nameParts.map(part => part[0].toUpperCase()).join('');

  return initials
}

onMounted(() => {
  updateCurrentRoute();
  fetchUsers();
});

function updateCurrentRoute() {
  console.log("Current route:", currentRoute.value);
  navigation.value.forEach((item) => {
    item.current = currentRoute.value.startsWith(item.href);
  });
}

// Watch for route changes to update the current route
watch(currentRoute, updateCurrentRoute);
</script>
