<!-- Usage Example -->
<template>
    <TaskCardThumbnailTemplate 
      :heading="formHeading" 
      :description="description" 
      :icon="formIcon"
    />
  </template>
  
  <script setup>
  import TaskCardThumbnailTemplate from '../../../TaskCardThumbnailTemplate.vue'
  import { NewspaperIcon } from "@heroicons/vue/24/outline"
  
  // Define the properties for the form heading
  const formHeading = "Article Fetcher"
  const description = "Fetch articles related to a query."
  const formIcon = NewspaperIcon
  
  </script>
  