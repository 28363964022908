// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/stores/user';
import Hero from './views/Hero.vue';
import Workspace from './views/Workspace.vue';
import Login from "./views/Login.vue";
import CompleteRegistration from "./views/CompleteRegistration.vue";

import Workflows from "@/components/workspace_components/Workflows.vue";
import Activity from "@/components/workspace_components/Activity.vue";
import Usage from "@/components/workspace_components/Usage.vue";
import Settings from "@/components/workspace_components/Settings.vue";
import AdminPanel from './components/workspace_components/AdminPanel.vue';

import WorkflowStation from "@/components/workspace_components/WorkflowStation.vue";
import WorkflowsHome from "@/components/workspace_components/workflows/WorkflowsHome.vue";
import WorkflowUsage from "@/components/workspace_components/workflows/WorkflowUsage.vue";
import WorkflowEditor from "./components/workspace_components/workflows/WorkflowEditor.vue";
import WorkflowActivity from "@/components/workspace_components/workflows/WorkflowActivity.vue";
import DynamicVariableFieldTest from "./components/DynamicVariableFieldTest.vue";
import GoogleSheetsAutoTest from "@/components/workspace_components/GoogleSheetsAutotest.vue";

import FormBuilderTest from "./components/FormBuilderTest.vue";
import WorkflowPreview from "./components/workspace_components/workflows/WorkflowPreview.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Hero,
    meta: { htmlClass: "h-full bg-gray-900", bodyClass: "h-full" },
  },
  {
    path: "/workspace",
    name: "Workspace",
    component: Workspace,
    meta: { htmlClass: "h-full bg-gray-900", bodyClass: "h-full", requiresAuth: true },
    children: [
      {
        path: "workflows",
        component: Workflows,
        children: [
          {
            path: "",
            name: "WorkflowsHome",
            component: WorkflowsHome,
          },
          {
            path: ":workflowId",
            name: "WorkflowStation",
            component: WorkflowStation,
            children: [
              {
                path: "",
                redirect: { name: "Editor" },
              },
              {
                path: "editor",
                name: "Editor",
                component: WorkflowEditor,
                
              },
              {
                path: "preview/:jobId?",
                name: "Preview",
                component: WorkflowPreview,
              },
              {
                path: "activity",
                name: "Activity",
                component: WorkflowActivity,
              },
              {
                path: "usage",
                name: "Usage",
                component: WorkflowUsage,
              },
            ],
          },
        ],
      },
      {
        path: "activity",
        name: "WorkspaceActivity",
        component: Activity,
      },
      {
        path: "usage",
        name: "Usage",
        component: Usage,
      },
      {
        path: "settings",
        name: "Settings",
        component: Settings,
      },
      {
        path: "admin",
        name: "AdminPanel",
        component: AdminPanel,
      },
      {
        path: "",
        redirect: "/workspace/workflows",
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { htmlClass: "h-full bg-gray-900", bodyClass: "h-full", guest: true },
  },
  {
    path: "/complete-registration",
    name: "Complete Registration",
    component: CompleteRegistration,
    meta: { htmlClass: "h-full bg-gray-900", bodyClass: "h-full", guest: true },
  },
  {
    path: "/dynamicvariabletest",
    name: "Dynamic Variable Test",
    component: DynamicVariableFieldTest,
    meta: { htmlClass: "h-full bg-gray-900", bodyClass: "h-full" },
  },
  {
    path: "/formbuildertest",
    name: "Form Builder Test",
    component: FormBuilderTest,
    meta: { htmlClass: "h-full bg-gray-900", bodyClass: "h-full" },
  },
  {
    path: "/sheetstest",
    name: "Sheets Test",
    component: GoogleSheetsAutoTest,
    meta: { htmlClass: "h-full bg-gray-900", bodyClass: "h-full" },
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// // Using Vue Router's global afterEach guard to reset overflow properties
// router.afterEach((to, from) => {
//   document.body.style.overflowX = 'auto';
//   document.body.style.overflowY = 'auto';
// });

// Global navigation guard
router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();
  const token = userStore.getToken;

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      next('/login'); // No token, redirect to login
      return;
    }

    const res = await userStore.verifyToken(token);

    if (res.status === 'success') {
      next(); // Token is valid, proceed
    } else {
      console.log('Your session has expired. Please log in again.');
      await userStore.logout();
      next('/login'); // After logout, redirect to login
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (token && to.name !== 'Complete Registration') {
      next('/'); // If already logged in and not trying to access register, redirect to home
      return;
    }
    next(); // Proceed to guest route
  } else {
    next(); // Proceed if no specific meta rules
  }
});


export default router;
