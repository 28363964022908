  <template>



    <!-- <button @click.prevent="addTagAtCaret" class="mt-2 px-4 py-2 bg-indigo-500 text-white rounded">Add Tag</button> -->
    <textarea ref="tagifyInputRef"
      class="block w-full rounded-md border-0 bg-white/5 py-1.5 px-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 resize-none"
      name="mcu" autocomplete="off"></textarea>
  </template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch, nextTick } from 'vue';
import Tagify from '@yaireo/tagify';
import '@yaireo/tagify/dist/tagify.css'; // Tagify CSS
import { useWorkflowEditorStore } from '../stores/workflowEditorStore'; // Adjust the import path as needed
import { useTaskDefinerStore } from '../stores/taskDefinerStore'; // Adjust the import path as needed
import useEventsBus from '../stores/eventBus';
import { before, after, all, caretPosition, getLastWordBeforeCaret, getElementsBeforeCaret, getAllElements, updateCaretPosition, setCaretPosition, rangeIntersectsNode, getSelectedElementTags, processNode, copyToClipboard, handleCopy, setTagifyRef } from './CopyPasteUtil.js';

import { defineProps, defineEmits } from 'vue';

import { BoltIcon } from '@heroicons/vue/24/solid';

const variables = ref([]);

// Define props
const props = defineProps({
  // cards: {
  //   type: Array,
  //   required: true,
  // },
  card: {
    type: Object,
    required: true,
  },

  targetDynamicId: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },

  mode: {
    type: String,
    required: false
  }


});


const tagifyInputRef = ref(null);
const dropdownContainer = ref(null);

const { emit } = useEventsBus();

const { bus }  = useEventsBus();

let tagify = null;
const dropdownVisible = ref(false);
const inputField = ref(null);


// Listen for the forwarded event from FormElement
const updateCSVWhitelist = () => {

  changeWhitelist();
  // console.log("Handled event in TagifyInput:");
  // Perform the necessary actions
};


watch(
  () => bus.value.get('dropdownChange'),
  (val) => {

    if (val[0] == props.targetDynamicId) {
      dropdownVisible.value = true;

    }

  }
);




watch(
  () => bus.value.get('toggleDropdown'),
  (val) => {



    if (props.card) {
    if (val[0] == props.targetDynamicId && val[1] == props.card.id) {

toggleDropdown();
}

    } else {
          //     const [sidebarCollapsedBus] = val ?? [];
    if (val[0] == props.targetDynamicId) {

toggleDropdown();
}

    }

  }
);

// similar to setTagifyWhitelist, except it sets up the whitelist for the job definer menu
const toggleDropdown = () => {


  let cardIndex;

  if (props.mode !== 'csv') {
    console.log('toggle got here');
    cardIndex = workflowEditorStore.cards.findIndex(card => card.id === props.card.id);

  
// all elements after the current card are not allowed to be added to tagify so set false
workflowEditorStore.cards.slice(cardIndex).forEach(card => {
  card.showBolt = false;
});

// all elements after the current card are  allowed to be added to tagify so set true
workflowEditorStore.cards.slice(0, cardIndex).forEach(card => {
  card.showBolt = true;
});


  } else {
    // all elements after the current card are not allowed to be added to tagify so set false
workflowEditorStore.cards.forEach(card => {
  card.showBolt = false;
});

  }


  if (taskDefinerStore.currentTab == "Google Sheets") {
    // all csv headers allowed to be added to tagify so set true
    taskDefinerStore.sideGoogleHeaders.forEach(header => {
    header.showBolt = true;
  });

  } else {
    // all csv headers allowed to be added to tagify so set true
  taskDefinerStore.sideHeaders.forEach(header => {
    header.showBolt = true;
  });

  }

  taskDefinerStore.setJobOpened(true);


  setTagifyWhitelist();
  if (!dropdownVisible.value && inputField.value) {
    inputField.value.focus();
  }
 var emitCollapse = false;
  if (!dropdownVisible.value) {
       emitCollapse = true;

       if (props.card) {
        emit('collapseOtherDv', props.targetDynamicId, props.card.id);

       } else {
        emit('collapseOtherDv', props.targetDynamicId, null);
       }


    if (props.mode == 'csv') {
      taskDefinerStore.csvDVClicked = true;
    } else {
    // sets the target id so we know which input to put the DV in.
    workflowEditorStore.cards[cardIndex].cardDVClicked = true;
    workflowEditorStore.cards[cardIndex].activeDVId = props.targetDynamicId;

    }
  } else {

    if (props.mode == 'csv') {
      taskDefinerStore.csvDVClicked = false;
    } else {
    // if they collapse dropdown, remove set the dv as off
    workflowEditorStore.cards[cardIndex].cardDVClicked = false;
    workflowEditorStore.cards[cardIndex].activeDVId = null;
    // resets the whitelist
    workflowEditorStore.cards.slice(0, cardIndex).forEach(card => {
      card.showBolt = false;
    });

    }

    if (taskDefinerStore.currentTab == "Google Sheets") {
    // all csv headers allowed to be added to tagify so set true
    taskDefinerStore.sideGoogleHeaders.forEach(header => {
    header.showBolt = false;
  });

  } else {
    // all csv headers allowed to be added to tagify so set true
  taskDefinerStore.sideHeaders.forEach(header => {
    header.showBolt = false;
  });

  }

    // taskDefinerStore.sideHeaders.forEach(header => {
    //   header.showBolt = false;
    // });

  }
  dropdownVisible.value = !dropdownVisible.value;
};

const csvSegmentToTagify = (csvSegment) => {

  setTagifyWhitelist();

  var res = '';
   for (var segment of csvSegment) {

    var tagData;
    if (segment.type == 'dynamic' || segment.dynamic) {
          tagData = JSON.stringify({
            value: segment.value,
            code: segment.nodeReference,
            nodeReference: segment.nodeReference,// Assuming tags are triggered with '@'
            prefix: '@'
          });

          res += `[[${tagData}]]`; // Format the string as Tagify expects in mix mode


        } else {
          res += segment.value; // Format the string as Tagify expects in mix mode

        }

   }

   tagify.parseMixTags(res);
}


defineExpose({ updateCSVWhitelist , toggleDropdown, dropdownVisible, csvSegmentToTagify})

const selectVariable = (variable) => {
  addTagAtCaret(variable);


  const currentCard = workflowEditorStore.cards.find(card => card.id == props.card.id);

  var targetSegment = currentCard.options.find(element => element.id == props.targetDynamicId);

  targetSegment.value = [];
  transformer(targetSegment.value);

  setTagifyWhitelist();


};

// Access the store
const workflowEditorStore = useWorkflowEditorStore();

const taskDefinerStore = useTaskDefinerStore();


const handleRename = (newEvent) => {
  if (newEvent) {
    console.log('Value item updated in FormElement:', newEvent);
    // Handle the updated value item here
    workflowEditorStore.clearRenameEvent();
  }
};


watch(
  () => bus.value.get('collapseOtherDv'),
  (val) => {

    if (!props.card && val[0] !== props.targetDynamicId) {
      if (dropdownVisible.value) {

      dropdownVisible.value = !dropdownVisible.value;

  
      taskDefinerStore.csvDVClicked = false;

      }
      return;
    } else if (!props.card && val[0] == props.targetDynamicId) {
      return;
    }
    //     const [sidebarCollapsedBus] = val ?? [];
    if (val[0] !== props.targetDynamicId  || val[1] !== props.card.id) {
      if (dropdownVisible.value) {
        dropdownVisible.value = !dropdownVisible.value;

        if (props.mode !== 'csv' && val[1] !== props.card.id) {
          const cardIndex = workflowEditorStore.cards.findIndex(card => card.id === props.card.id);
         workflowEditorStore.cards[cardIndex].cardDVClicked = false;
        } else {
          taskDefinerStore.csvDVClicked = false;
        }

      }
    }
  }
);


// watch(
//   () => bus.value.get('triggerTagifyReRender'),
//   (val) => {

//     if (val[0] == props.targetDynamicId) {
//       dropdownVisible.value = true;

//     }

//   }
// );



watch(() => bus.value.get('segmentToTagify'), (rename) => {

// console.log('the re render was triggered');

if (props.mode !== 'csv') {
  setTagifyWhitelist();

  // var targetCard = workflowEditorStore.cards.find(element => element.id == props.card.id);
segmentToTagify(props.card);

console.log('finished segment to tagify');

} 
}, { deep: true });

watch(() => workflowEditorStore.renameEvent, (rename) => {

  // console.log('the re render was triggered');

  if (props.mode !== 'csv') {
    setTagifyWhitelist();

segmentToTagify(props.card);

  } 
}, { deep: true });


watch(
  () => bus.value.get('addCsvInput'),
  (val) => {

    if (props.mode !== 'csv') return;

    console.log('received add event');

    console.log('the val 1  ' , val[0]);
    console.log('the val 2 ' , val[1]);


    // prevent un-neccesary triggers by checking if the objects are the same
if (!hasDifferences(val[1].old, val[1].new)) {
  return;
}
var element = val[1];


if (element.new.type !== 'header') {
  addTagAtCaret({
    value: element.new.name,
    code: `${element.new.id}`,
    prefix: '@',
    nodeReference: element.new.id


  });


} else {
  addTagAtCaret({
    value: element.new.name,
    code: `${element.new.id}`,
    prefix: '@',
    nodeReference: null

  });
}

// reset its value, then propgate the tagify changes back to the workflow editor store
taskDefinerStore.csvOutputSegment = [];
transformer(taskDefinerStore.csvOutputSegment);

// console.log('after is  ' ,  workflowEditorStore.cards.find(card => card.id == element.old.id) );
// update the whitelist for valid nodes for the given card.
setTagifyWhitelist();

  }
);

try {
// this should watch for some card specific event
watch(() => props.card.addEvent, async (element, oldEvent) => {


  if(props.mode == 'csv') return;
// prevent un-neccesary triggers by checking if the objects are the same
if (!hasDifferences(element, oldEvent)) {
  return;
}

// only add the DV  if its the dv id  the user specified 
if (element.old.activeDVId !== props.targetDynamicId) {
  return;
}


console.log('the type is ', element.new);

//  console.log("this is the element " , element)
//         console.log('Old event:', oldEvent);
//     console.log('New event:', element);
// console.log('before adding the segement is  ' ,  workflowEditorStore.cards.find(card => card.id == element.old.id) );

// add the tag the user clicked on in job definer to tagify
if (element.new.type !== 'header') {
  addTagAtCaret({
    value: element.new.name,
    code: `${element.new.id}`,
    prefix: '@',
    nodeReference: element.new.id


  });


} else {
  addTagAtCaret({
    value: element.new.name,
    code: `${element.new.id}`,
    prefix: '@',
    nodeReference: null

  });

}

// get the card and the dynamic var input related to card
const currentCard = workflowEditorStore.cards.find(card => card.id == element.old.id);
var targetSegment = currentCard.options.find(element => element.id == props.targetDynamicId);


// reset its value, then propgate the tagify changes back to the workflow editor store
targetSegment.value = [];
transformer(targetSegment.value);

// console.log('after is  ' ,  workflowEditorStore.cards.find(card => card.id == element.old.id) );
// update the whitelist for valid nodes for the given card.
setTagifyWhitelist();

}, { deep: true });

} catch (error){

}


const initTagify = () => {


  const mcuHeros = ref([
    { value: "Company Name", code: "im", nodeReference: null },
    { value: "Company Test", code: "io", nodeReference: null },
  ]);

  tagify = new Tagify(tagifyInputRef.value, {
    dropdown: {
      enabled: 0,
      includeSelectedTags: true,
    },
    whitelist: mcuHeros.value,
    mode: 'mix',
    pattern: /@/,
    duplicates: true,
    enforceWhitelist: true,
    keepInvalidTags: false,
    templates: {
      tag(tagData) {
        return `<tag title="${tagData.title || tagData.value}"
                        contenteditable='false'
                        spellcheck='false'
                        tabIndex="${this.settings.a11y.focusableTags ? 0 : -1}"
                        class="${this.settings.classNames.tag} ${tagData.class ? tagData.class : ""} inline-flex items-center gap-x-0.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
                        ${this.getAttributes(tagData)}
                         style="display: inline-flex">
                               <x title='' class="${this.settings.classNames.tagX} text-green-700 hover:bg-green-700 " role='button' aria-label='remove tag'></x>

<span class=" tagify__tag-text" >
  ${tagData[this.settings.tagTextProp] || tagData.value}
</span>
                    </tag>`;
      },
      dropdownFooter(suggestions) {
        var hasMore = suggestions.length - this.settings.dropdown.maxItems;
        return hasMore > 0
          ? `<footer data-selector='tagify-suggestions-footer' class="${this.settings.classNames.dropdownFooter}">
                ${hasMore} more items. Refine your search.
              </footer>`
          : '';
      },
    },
  });



   setTagifyRef(tagifyInputRef.value)
  tagify.DOM.input.addEventListener('keyup', () => updateCaretPosition(tagify));
    tagify.DOM.input.addEventListener('mouseup', () => updateCaretPosition(tagify));
    tagify.DOM.input.addEventListener('click', () => updateCaretPosition(tagify));
    tagify.DOM.input.addEventListener('selectionchange', () => updateCaretPosition(tagify));

    document.addEventListener('copy', (event) => handleCopy(event, tagify));

        tagify.DOM.scope.addEventListener('paste', function (e) {
          e.preventDefault();
          // console.log('Paste event on Tagify scope');
          // Get the pasted text from the clipboard
          var pastedText = (e.clipboardData || window.clipboardData).getData('text');

          // invisible seperator (used to figure out where the pasted text lies in  the dom so we can place caret at proper position)
          const invisibleChar = '\u2063';  

          // clear any old references to the invisible seperator so we get the latest pasted text and not old pasted text
          tagifyInputRef.value.value = before.replace(/\u2063/g, '') + invisibleChar + pastedText.replace(/\u2063/g, '') + invisibleChar + after.replace(/\u2063/g, '');

          // Load the original values
          tagify.loadOriginalValues();
          tagify.dropdown.hide();

          var newState = getAllElements(tagify, 0);


          function placeCaretInTextNode(textNode, position) {
            // Create a range object
            const range = document.createRange();

            // Set the range to start and end at the specified position within the text node
            range.setStart(textNode, position);
            range.setEnd(textNode, position);

            // Get the selection object
            const selection = window.getSelection();

            // Remove any existing selections
            selection.removeAllRanges();

            // Add the new range to the selection
            selection.addRange(range);
          }

          let lastInvisibleCharPos = -1; // Track the last occurrence of the invisible character
          let lastElementWithInvisibleChar = null; // Track the element containing the last invisible character



// Iterate over all newState elements
for (let i = 0; i < newState.length; i++) {
    const element = newState[i];
    const textContent = element.textContent;

    // Iterate through the text content to find the invisible character
    for (let j = 0; j < textContent.length; j++) {
        if (textContent[j] === invisibleChar) {
            lastInvisibleCharPos = j; // Update the position of the last invisible character
            lastElementWithInvisibleChar = element; // Update the element containing this character
        }
    }
}
          // After looping through all elements, place the caret at the last occurrence (add 1 to position because we want cursor 1 pos after pasted text)
          if (lastElementWithInvisibleChar && lastInvisibleCharPos !== -1) {
            placeCaretInTextNode(lastElementWithInvisibleChar, lastInvisibleCharPos + 1);
          }


          // alternatively after placing caret i could loop through text node and remove invisible characters.

            if (props.mode !== 'csv') {
              const currentCard = workflowEditorStore.cards.find(card => card.id == props.card.id);
var targetSegment = currentCard.options.find(element => element.id == props.targetDynamicId);

props.card.options.find(element => element.id == props.targetDynamicId).value = [];
targetSegment.value = [];
transformer(targetSegment.value);

            } else {
              taskDefinerStore.csvOutputSegment= [];
              transformer(taskDefinerStore.csvOutputSegment);
              setTagifyWhitelist();


            }

        });



  // Listen for the 'remove' event
  tagify.on('remove', function (e) {
    console.log('Tag removed:', e.detail.data);

    if (props.mode !== 'csv') {

      const currentCard = workflowEditorStore.cards.find(card => card.id == props.card.id);
    var targetSegment = currentCard.options.find(element => element.id == props.targetDynamicId);

    // reset its value, then propgate the tagify changes back to the workflow editor store
    targetSegment.value = [];
    transformer(targetSegment.value);

    // update whitelist (nodes that are allowed to be used for this card)
    setTagifyWhitelist();

    } else {
      taskDefinerStore.csvOutputSegment= [];
    transformer(taskDefinerStore.csvOutputSegment);
    setTagifyWhitelist();

    }

  });

  tagify.on('add', function (e) {
    console.log("ADDED ", e.detail.data);


    if (props.mode !== 'csv') {
    // get the card and the dynamic var input related to card
    const currentCard = workflowEditorStore.cards.find(card => card.id == props.card.id);
    var targetSegment = currentCard.options.find(element => element.id == props.targetDynamicId);


    // reset its value, then propgate the tagify changes back to the workflow editor store
    targetSegment.value = [];
    transformer(targetSegment.value);
    setTagifyWhitelist();


    } else {
      taskDefinerStore.csvOutputSegment= [];
    transformer(taskDefinerStore.csvOutputSegment);
    setTagifyWhitelist();


    }
  });



  // Update content on any input change
  tagify.on('input', (e) => {

    console.log('props mode is ' , props.mode);

    // console.log('the card id  is ' , props.card.id);
    // console.log('the list of cards is ' , workflowEditorStore.cards);
   if (props.mode !== 'csv') {
    const currentCard = workflowEditorStore.cards.find(card => card.id == props.card.id);

    // console.log('the current card is ' , currentCard)

    var targetSegment = currentCard.options.find(element => element.id == props.targetDynamicId);

    props.card.options.find(element => element.id == props.targetDynamicId).value = [];
    targetSegment.value = [];
    transformer(targetSegment.value);
    setTagifyWhitelist();

   } else {
    taskDefinerStore.csvOutputSegment= [];
    transformer(taskDefinerStore.csvOutputSegment);
    setTagifyWhitelist();

   }
  });


  function preventTyping(e) {
    e.preventDefault();
}


  tagify.on('edit:start', (e) => {

      // Disable the input field to prevent typing
    tagify.DOM.input.addEventListener('keydown', preventTyping);
    console.log("edit started");
    
    setTagifyWhitelist();
  });



  tagify.on('edit:updated	', (e) => {

    console.log("changed happeend");
    tagify.DOM.input.removeEventListener('keydown', preventTyping);

    tagify.userInput = true;


    if (props.mode !== 'csv') {

        // get the card and the dynamic var input related to card
        const currentCard = workflowEditorStore.cards.find(card => card.id == props.card.id);
    var targetSegment = currentCard.options.find(element => element.id == props.targetDynamicId);


    // reset its value, then propgate the tagify changes back to the workflow editor store
    targetSegment.value = [];
    transformer(targetSegment.value);
    setTagifyWhitelist();

    } else {
      taskDefinerStore.csvOutputSegment= [];
    transformer(taskDefinerStore.csvOutputSegment);
    setTagifyWhitelist();


    }
  });


  tagify.on('edit:input', function (e) {
    var tagData = e.detail.data;
    var tagElement = e.detail.tag;

    // console.log('the dta is ' , tagData);


    if (!tagify.settings.whitelist.find(whiteTag => whiteTag.value == tagData.value)) {
      // console.log('tag not found in whitelist', tagData);


      tagElement.classList.remove(
        'bg-green-100',
        'text-green-700',
      );

      tagElement.classList.add(
        'bg-rose-100',
        'text-rose-700',
        'invalid-tag'
      );
    } else {

      tagElement.classList.remove(
        'bg-rose-100',
        'text-rose-700',
        'invalid-tag'
      );

      tagElement.classList.add(
        'bg-green-100',
        'text-green-700',
      );

    }
  });

};

const addTag = (tag) => {
  tagify.addTags([tag]);
};

const removeTag = (tag) => {
  tagify.removeTag(tag);
};

// when csv headers change,this propgates the changes to tagify and invalidates csv headers that are not valid
const changeWhitelist = () => {

  if (taskDefinerStore.currentTab == 'Google Sheets') {
  // Map the headers to the desired format for the whitelist
  tagify.settings.whitelist = taskDefinerStore.googleHeaders.map((header, index) => ({
    value: header,
    code: `${header}`,
    prefix: '@',
    nodeReference: null
  }));

  } else {
      // Map the headers to the desired format for the whitelist
  tagify.settings.whitelist = taskDefinerStore.headers.map((header, index) => ({
    value: header,
    code: `${header}`,
    prefix: '@',
    nodeReference: null
  }));

  }


  const tagElements = tagify.getTagElms();

  tagElements.forEach(tagElement => {
    const tagData = tagElement.__tagifyTagData;

    if(tagData.nodeReference !== null) {
       return;
    }
    // console.log('we are checking tag ' , tagData);

    if (!tagify.settings.whitelist.find(whiteTag => whiteTag.value == tagData.value)) {
      // console.log('tag not found in whitelist', tagData);


      tagElement.classList.remove(
        'bg-green-100',
        'text-green-700',
      );

      tagElement.classList.add(
        'bg-rose-100',
        'text-rose-700',
        'invalid-tag'
      );
    } else {

      tagElement.classList.remove(
        'bg-rose-100',
        'text-rose-700',
        'invalid-tag'
      );

      tagElement.classList.add(
        'bg-green-100',
        'text-green-700',
      );

    }
  });


}

const hasDifferences = (obj1, obj2) => {
  if (obj1 === obj2) return false;

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return true;
  }

  let keys1 = Object.keys(obj1);
  let keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return true;

  for (let key of keys1) {
    if (!keys2.includes(key) || hasDifferences(obj1[key], obj2[key])) return true;
  }

  return false;
};

const setTagifyWhitelist = () => {


  if (props.mode !== 'csv') {
  // console.log('the current card is ' , props.card);
  // get the current card index, to determine all nodes before it.
  const cardIndex = workflowEditorStore.cards.findIndex(card => card.id === props.card.id);
  // console.log('the position of the card is ' , cardIndex);

  // console.log('the previous nodes are ', workflowEditorStore.cards.slice(0, cardIndex));

  // console.log('the csv nodes are ' , taskDefinerStore.headers)



  // add csv headers to the whitelist
  let csvWhitelist;
  if (taskDefinerStore.currentTab == "Google Sheets") {
     csvWhitelist = taskDefinerStore.googleHeaders.map((header, index) => ({
    value: header,
    code: `${header}`,
    prefix: '@',
    nodeReference: null
  }));

  } else {
     csvWhitelist = taskDefinerStore.headers.map((header, index) => ({
    value: header,
    code: `${header}`,
    prefix: '@',
    nodeReference: null
  }));

  }


  // finds all cards in the card store before the present one, setting them as valid nodes in the whitelist.
  var whitelist = workflowEditorStore.cards.slice(0, cardIndex).map(element => ({

    // name: element.name,
    value: element.name,
    code: `${element.id}`,
    prefix: '@',
    nodeReference: element.id

  }));

  whitelist = [ ...csvWhitelist,...whitelist]

  // update tagify whitelist
  tagify.settings.whitelist = whitelist;

  } else {

      // add csv headers to the whitelist
  const csvWhitelist = taskDefinerStore.headers.map((header, index) => ({
    value: header,
    code: `${header}`,
    prefix: '@',
    nodeReference: null
  }));

  whitelist = [ ...csvWhitelist]
  // update tagify whitelist
  tagify.settings.whitelist = whitelist;



  }



}




function transformer(segments = null) {

  // console.log("parsing tagify data invoekd");

  // Regular expression with capturing group to keep the delimiters (JSON parts)
  // let pattern = /(\[\[(.*?)\]\])/g;

  // matches mixed tag data from tagify into its own individual elements via regex
  let pattern = /\[\[((?:\{.*?\})+)\]\]/g;

  function parseJSON(jsonString) {
    try {
      return JSON.parse(jsonString);
    } catch (e) {
      return null; 
    }
  }


  // Splitting the input and processing each part
  let parts = tagify.getMixedTagsAsString().split(pattern);

  for (let part of parts) {
    if (part == null) {
      continue;
    }
    // if the part is an object, its a tag reference
    if (part.startsWith('{') && part.endsWith('}')) {
      // propgate the changes back to workflow card store
      let jsonData = parseJSON(part);
      segments.push({
        value: jsonData.value,
        type: 'dynamic',
        nodeReference: jsonData.nodeReference
      });
      // if its just a string, and not a empty string its just text in the dv input, 
    } else if (part.trim()) {
      // propogate text elements to workflow card store
      segments.push({
        value: part,
        type: 'static',
        nodeReference: null
      });
    }
  }
}

const addTagAtCaret = (variable) => {
  var tagElm = tagify.createTagElem(variable);
  tagify.injectAtCaret(tagElm);
  var elm = tagify.insertAfterTag(tagElm);
  tagify.placeCaretAfterNode(elm);
};



const handleClickOutside = (event) => {
  if (dropdownVisible.value && dropdownContainer.value && !dropdownContainer.value.contains(event.target)) {
    dropdownVisible.value = false;
    const cardIndex = workflowEditorStore.cards.findIndex(card => card.id === props.card.id);
    workflowEditorStore.cards[cardIndex].cardDVClicked = false;

    workflowEditorStore.cards.slice(0, cardIndex).forEach(card => {
      card.showBolt = false;
    });


    if (taskDefinerStore.currentTab == "Google Sheets") {
    // all csv headers allowed to be added to tagify so set true
    taskDefinerStore.sideGoogleHeaders.forEach(header => {
    header.showBolt = false;
  });

  } else {
    // all csv headers allowed to be added to tagify so set true
  taskDefinerStore.sideHeaders.forEach(header => {
    header.showBolt = false;
  });

  }


    // taskDefinerStore.sideHeaders.forEach(header => {
    //   header.showBolt = false;
    // });


  }
};


onMounted(() => {

  initTagify();
  document.addEventListener('click', handleClickOutside);



});

onBeforeUnmount(() => {
  if (tagify) {
    tagify.destroy();
  }
  document.removeEventListener('click', handleClickOutside);

});


// this is opposite of transformer, takes changes from the workflow store and applies to to tagify
const segmentToTagify = (newCard) => {

  // console.log('heres the segment ' , props.card);

  // setTagifyWhitelist();

  // propgates changes from the workflow edit store back to the tagify element;
  var res = '';
  // Go through each DV option in the card 
  for (const option of newCard.options) {

    // console.log(option);
    // Check if the option has the key hasAddVariable = true
    if (option.hasAddVariable && option.id == props.targetDynamicId) {

      // console.log(option.value);
      // Loop through option.value using for...of loop
      for (const valueItem of option.value) {
        var tagData;

        if (valueItem.type == 'dynamic' || valueItem.dynamic) {
          tagData = JSON.stringify({
            value: valueItem.value,
            code: valueItem.nodeReference,
            nodeReference: valueItem.nodeReference,// Assuming tags are triggered with '@'
            prefix: '@'
          });

          res += `[[${tagData}]]`; // Format the string as Tagify expects in mix mode


        } else {
          res += valueItem.value; // Format the string as Tagify expects in mix mode

        }

      }

    }
  }

  // console.log("the result is ", res);
  tagify.parseMixTags(res); // Parse the updated content
};



const adjustTextareaHeight = () => {
  const el = tagifyInputRef.value;
  const newHeight = `${el.scrollHeight}px`;
  el.style.setProperty('--tagify--height', newHeight);
};

</script>

<style>

.multiselect__tags{
  overflow-y:scroll;
  scrollbar-width:thin;
}

/* Custom class for fractional padding */
.px-0-1 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

/* .tagify{ --tag--max-width:100px;} */

.tagify {
  min-width: 0.5px !important;
  --tag-bg: #ccffcc;
  /* max-height: 35px; */
  max-width: 400px;
  /* Adjust this value based on your requirement */
  border: 1px solid #ccc;
  /* Optional: Adds a border for visibility */
  padding: 5px;
  /* Padding inside the container */
}

/* Ensure that the CSS rules apply specifically to the Tagify input */

/*

*/
.tagify__input {
  font-size: 15px;
  /* Adjust the size as needed */
}



.tagify__tag {
  display: inline-block;
  margin-bottom: 5px;
  /* Optional, for vertical spacing */
}

.tagify__tag-text {
  display: inline-flex;
  align-items: center;
  padding-right: 5px;
  /* Adjust padding to prevent overlap */
}



.tagify__tag-text {
  white-space: nowrap;
}


/* Custom class for more precise padding */
.px-0-1 {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}

.py-0-1 {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}

.px-0-05 {
  padding-left: 0.15rem;
  padding-right: 0.15rem;
}

.py-0-05 {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

/* Custom class for additional spacing */
.mx-0-1 {
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}

.my-0-1 {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}
/* .tagify+textarea {
            display: block !important;
            position: static !important;
            transform: none !important;
            width: 100%;
            margin-top: 1em;
            padding: .5em;
        } */



/* Add any necessary styles here */
</style>
