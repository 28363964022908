const workflows = [
  {
    id: "1ajf83",
    name: "Compliment Automator",
    status: "online",
    statusText: "Initiated 1m 32s ago",
    description:
      "Generates compliments based on website, news articles, and Instagram",
    availability: "Running",
    isFavorited: true
  },

  {
    id: "2hsid8",
    name: "News Summarizer",
    status: "online",
    statusText: "Initiated 1m 32s ago",
    description: "Summarize articles about a query",
    availability: "Running",
  },

  {
    id: "3ssas9",
    name: "Testing Workflow",
    status: "offline",
    statusText: "Initiated 1m 32s ago",
    description: "Deploys from GitHub",
    availability: "Available",
  },

  {
    id: "3ssas9",
    name: "Testing Workflow",
    status: "offline",
    statusText: "Initiated 1m 32s ago",
    description: "Deploys from GitHub",
    availability: "Available",
  },{
    id: "3ssas9",
    name: "Testing Workflow",
    status: "offline",
    statusText: "Initiated 1m 32s ago",
    description: "Deploys from GitHub",
    availability: "Available",
  },{
    id: "3ssas9",
    name: "Testing Workflow",
    status: "offline",
    statusText: "Initiated 1m 32s ago",
    description: "Deploys from GitHub",
    availability: "Available",
  },
  {
    id: "3ssas9",
    name: "Testing Workflow",
    status: "offline",
    statusText: "Initiated 1m 32s ago",
    description: "Deploys from GitHub",
    availability: "Available",
  },
  {
    id: "3ssas9",
    name: "Testing Workflow",
    status: "offline",
    statusText: "Initiated 1m 32s ago",
    description: "Deploys from GitHub",
    availability: "Available",
  },
  {
    id: "3ssas9",
    name: "Testing Workflow",
    status: "offline",
    statusText: "Initiated 1m 32s ago",
    description: "Deploys from GitHub",
    availability: "Available",
  },
  {
    id: "3ssas9",
    name: "Testing Workflow",
    status: "offline",
    statusText: "Initiated 1m 32s ago",
    description: "Deploys from GitHub",
    availability: "Available",
  },{
    id: "3ssas9",
    name: "Testing Workflow",
    status: "offline",
    statusText: "Initiated 1m 32s ago",
    description: "Deploys from GitHub",
    availability: "Available",
  },{
    id: "3ssas9",
    name: "Testing Workflow",
    status: "offline",
    statusText: "Initiated 1m 32s ago",
    description: "Deploys from GitHub",
    availability: "Available",
  },{
    id: "3ssas9",
    name: "Testing Workflow",
    status: "offline",
    statusText: "Initiated 1m 32s ago",
    description: "Deploys from GitHub",
    availability: "Available",
  },
];

const WorkflowName = {
  COMPLIMENT_AUTOMATOR: "Compliment Automator",
  NEWS_SUMMARIZER: "News Summarizer",
  TESTING_WORKFLOW: "Testing Workflow"
};

const jobs = [
  {
    workflowName: WorkflowName.COMPLIMENT_AUTOMATOR,
    status: "pending",
    rows: "159/180",
    runtime: "32s",
  },

  {
    workflowName: WorkflowName.NEWS_SUMMARIZER,
    status: "pending",
    rows: "159/180",
    runtime: "32s",
  },
];

export default workflows;
export { WorkflowName, jobs };