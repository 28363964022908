<template>
    <!-- <fieldset class="mt-4"> -->
      <legend class="text-sm font-semibold leading-6 text-white">{{ legend }}</legend>
      <p class="mt-1 text-sm leading-6 text-gray-400">{{ description }}</p>
      <div class="mt-6 space-y-6">
        <div v-for="option in options" :key="option.id" class="flex items-center gap-x-3">
          <input 
            :id="option.id" 
            :name="name" 
            type="radio" 
            :value="option.value" 
            v-model="internalValue"
            class="h-4 w-4 border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900" 
          />
          <label :for="option.id" class="block text-sm font-medium leading-6 text-white">{{ option.label }}</label>
        </div>
      </div>
    <!-- </fieldset> -->
  </template>
  
  <script setup>
  import { ref, watch, toRefs } from 'vue';
  
  const props = defineProps({
    modelValue: [String, Number],
    legend: String,
    description: String,
    name: String,
    options: Array
  });
  
  const emit = defineEmits(['update:modelValue']);
  
  const { modelValue } = toRefs(props);
  const internalValue = ref(modelValue.value);
  
  watch(internalValue, (newValue) => {
    emit('update:modelValue', newValue);
  });
  
  watch(modelValue, (newValue) => {
    internalValue.value = newValue;
  });
  </script>
  