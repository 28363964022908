<template>
    <FormElement 
      ref="formElementRef"
      :heading="formHeading" 
      :subheader="formSubheader" 
      :description="formDescription"
      :icon="formIcon" 
      :elements="formElements"
      :has-delete="hasDelete"
      :cards="props.cards" 
      :card="props.card" 
      @input="emitFormData"
      @triggerCsvChange="nestedCsvInvoke"

    />
  </template>
  
  <script setup>
  import { defineProps, ref, watch, onMounted, defineEmits} from 'vue';
  import FormElement from '../../../CardTemplate.vue';
  import { NewspaperIcon } from "@heroicons/vue/24/outline"
  import { useWorkflowEditorStore } from '@/stores/workflowEditorStore';

  // Define the properties for the form heading
// Accept the cards prop
const props = defineProps({
card: {
  type: Object,
  required: true,
},
cards: {
  type: Array,
  required: true,
}
});
  const formHeading = "MyArticleFetch"
  const formSubheader = "Article Fetcher"
  const formDescription = "Fetches articles related to a query."
  const formIcon = NewspaperIcon
  const hasDelete = true
  const formElementRef = ref(null);
  const workflowEditorStore = useWorkflowEditorStore();

  
  // Define the form elements
  const formElements = ref([
    {
      id: 'query',
      type: 'website',
      label: 'Query',
      hasAddVariable: true,
      inputType: 'text',
      value:[], // Add value property to track the input value
    },
    
    {
      id: 'format',
      type: 'radio-group',
      legend: 'Aggregate format',
      description: 'The format the articles output will be in.',
      name: 'push-notifications',
      options: [
        {
          value: 'json',
          label: 'JSON',
        },
        {
          value: 'plain-text',
          label: 'Plain Text',
        },
      ],

      value: null, // Add value property to track the input value
    },

    {
    // id: 'remove-newlines',
    id:'articleLimit',
    type: 'input',
    label: 'Article Limit',
    value: [], // Add value property to track the input value
  },

  {
    // id: 'remove-newlines',
    id:'paramsToShow',
    type: 'multiselect',
    label: 'Tags to Scrape',
    tagOptions:  [
        { tag: 'url' },
        { tag: 'title' },
        { tag: 'description' },
        { tag: 'publisher' },
      ],
    value: [], // Add value property to track the input value
  },


  ]);


  const loadClonedCardData = (backendOptions) => {

for (const propObj of backendOptions) {

  var targetField = formElements.value.find(field => field.id == propObj.id);

  // console.log('the target field is ' , targetField);

  targetField.value = propObj.value;


  emit('input', formElements.value);

// Trigger the rename event in the store before deleting
workflowEditorStore.triggerRenameEvent({});



}

}

  const loadBackendData =  (backendData) => {

console.log('the backend data called', backendData);

for (const props in backendData.options) {
  // if (props == 'characterLimit') continue;
  console.log('the field is ' , props);
  var targetField = formElements.value.find(field => field.id == props);
  console.log('the target field is ' , targetField);

  if (targetField.type == 'multiselect') {
    targetField.value = backendData.options[props].map((field) => {
      return {
        tag: field
      }
    });

  } else {
    targetField.value = backendData.options[props];
  }

  if(targetField.hasAddVariable) {
    emit('input', formElements.value);

    // Trigger the rename event in the store before deleting
    workflowEditorStore.triggerRenameEvent({});



    // invoke segmentToTagify;

  }

}

console.log('the options are now ' , formElements);
// for (var elements of backendData) {

// }
};





const nestedCsvInvoke = (event) => {



formElementRef.value.processCsvChange();


}

defineExpose({ loadBackendData, nestedCsvInvoke, loadClonedCardData })

const emit = defineEmits(['input', 'triggerCSVChange']);

const emitFormData = () => {
emit('input', formElements.value);
};

onMounted(() => {

// emit('input', formElements.value);

    // seperate the mounted state so that we do not double save states for undo redo
    emit('form-mounted', formElements.value);

});



watch(() => props.cards, (newCards) => {
console.log("Updated cards array:", newCards);
});

  </script>
  