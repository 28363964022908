<template>
  <div class="function-input-container">
    <input
      type="text"
      v-model="inputValue"
      @input="onInput"
      @keydown.down.prevent="onArrowDown"
      @keydown.up.prevent="onArrowUp"
      @keydown.enter.prevent="onEnter"
      @blur="onBlur"
      @keyup="onKeyup"
    />
    <ul v-if="showDropdown" class="dropdown">
      <li
        v-for="(functionName, index) in filteredFunctions"
        :key="functionName"
        :class="{ 'highlighted': index === highlightedIndex }"
        @mousedown.prevent="selectFunction(functionName)"
      >
        {{ functionName }}
      </li>
    </ul>
    <div v-if="currentFunction" class="function-parameters">
      <div v-for="(param, index) in currentFunction.params" :key="index" :class="{ 'highlighted-param': index === currentFunction.highlightedParam }">
        {{ param }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import functionsData from '@/data/GoogleSheetsFunctions.json'

const inputValue = ref('')
const filteredFunctions = ref([])
const showDropdown = ref(false)
const highlightedIndex = ref(-1)
const currentFunction = ref(null)

const functionsList = functionsData.map(func => ({ name: func.Name.toLowerCase(), syntax: func.Syntax }))

const onInput = () => {
  const lastWord = getLastWord(inputValue.value)
  if (lastWord && !lastWord.includes('(')) {
    filteredFunctions.value = functionsList.filter(func =>
      func.name.startsWith(lastWord.toLowerCase())
    ).map(func => func.name)
    showDropdown.value = filteredFunctions.value.length > 0
    highlightedIndex.value = -1
  } else {
    showDropdown.value = false
  }
}

const getLastWord = (str) => {
  const words = str.split(/[^a-zA-Z]/)
  return words[words.length - 1]
}

const selectFunction = (functionName) => {
  const cursorPosition = inputValue.value.length
  const beforeCursor = inputValue.value.slice(0, cursorPosition)
  const afterCursor = inputValue.value.slice(cursorPosition)
  const lastWord = getLastWord(beforeCursor)
  inputValue.value = `${beforeCursor.slice(0, -lastWord.length)}${functionName}(${afterCursor}`
  showDropdown.value = false
}

const onArrowDown = () => {
  if (highlightedIndex.value < filteredFunctions.value.length - 1) {
    highlightedIndex.value++
  }
}

const onArrowUp = () => {
  if (highlightedIndex.value > 0) {
    highlightedIndex.value--
  }
}

const onEnter = () => {
  if (highlightedIndex.value >= 0) {
    selectFunction(filteredFunctions.value[highlightedIndex.value])
  }
}

const onBlur = () => {
  setTimeout(() => { showDropdown.value = false }, 100)
}

const onKeyup = (event) => {
  const cursorPosition = event.target.selectionStart
  updateCurrentFunction(cursorPosition)
}

const updateCurrentFunction = (cursorPosition) => {
  const textBeforeCursor = inputValue.value.slice(0, cursorPosition)
  const matches = [...textBeforeCursor.matchAll(/([a-zA-Z]+)\(/g)]
  
  if (matches.length > 0) {
    const lastMatch = matches[matches.length - 1]
    const functionName = lastMatch[1].toLowerCase()
    const functionInfo = functionsList.find(func => func.name === functionName)
    
    if (functionInfo) {
      const params = functionInfo.syntax.match(/\(([^)]+)\)/)[1].split(', ')
      const paramsCount = textBeforeCursor.slice(lastMatch.index + functionName.length + 1).split(',').length - 1
      
      currentFunction.value = {
        name: functionName,
        params: params,
        highlightedParam: Math.min(paramsCount, params.length - 1)
      }
    } else {
      currentFunction.value = null
    }
  } else {
    currentFunction.value = null
  }
}
</script>

<style>
.function-input-container {
  position: relative;
}

.dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown li {
  padding: 8px;
  cursor: pointer;
}

.dropdown li.highlighted {
  background-color: #bde4ff;
}

.function-parameters {
  margin-top: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 10px;
}

.function-parameters div {
  padding: 2px 0;
}

.highlighted-param {
  background-color: #d3f8e2;
}
</style>
