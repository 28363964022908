<template>
  <main class="lg:pr-96">
    <!-- Workflow List Header -->
    <header
      class="sticky top-0 z-20 bg-[#0F1623] flex items-center justify-between border-b border-white/5 px-4 py-4 sm:px-6 sm:py-6 lg:px-8">
      <div class="flex flex-row gap-4">
        <h1 class="text-base font-semibold leading-7 text-white">Workflows</h1>
        <button type="button"
          class="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          @click="openNewWorkflowModal">
          <PlusIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
          New Workflow
        </button>
      </div>
      <!-- Search Bar & Dropdown -->
      <div class="flex flex-row gap-3 items-center">
        <!-- Sort dropdown -->
        <Menu as="div" class="relative z-50">
          <MenuButton class="flex items-center gap-x-1 text-sm font-medium leading-6 text-white">{{ currentSortLabel }}
            <ChevronUpDownIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
          </MenuButton>
          <transition enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95">
            <MenuItems
              class="absolute right-0 z-10 mt-2.5 w-40 origin-top-right rounded-md bg-gray-800 py-2 shadow-lg ring-1 ring-white/10 focus:outline-none">
              <MenuItem v-slot="{ active }">
              <a href="#" :class="[active ? 'bg-gray-700' : '', 'block px-3 py-1 text-sm leading-6 text-white']"
                @click="sortBy('favorites')">Favorites</a>
              </MenuItem>
              <MenuItem v-slot="{ active }">
              <a href="#" :class="[active ? 'bg-gray-700' : '', 'block px-3 py-1 text-sm leading-6 text-white']"
                @click="sortBy('name')">Name</a>
              </MenuItem>
              <MenuItem v-slot="{ active }">
              <a href="#" :class="[active ? 'bg-gray-700' : '', 'block px-3 py-1 text-sm leading-6 text-white']"
                @click="sortBy('updatedAt')">Date updated</a>
              </MenuItem>
              <MenuItem v-slot="{ active }">
              <a href="#" :class="[active ? 'bg-gray-700' : '', 'block px-3 py-1 text-sm leading-6 text-white']"
                @click="sortBy('status')">Status</a>
              </MenuItem>
            </MenuItems>
          </transition>
        </Menu>
        <!-- Search Bar -->
        <div class="w-full sm:max-w-xs">
          <label for="search" class="sr-only">Search</label>
          <div class="relative">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input id="search" name="search" v-model="searchQuery" @input="updateSearch"
              class="block w-full rounded-md border-0 bg-gray-700 py-1.5 pl-10 pr-3 text-gray-300 placeholder:text-gray-400 focus:bg-white focus:text-gray-900 focus:ring-0 focus:placeholder:text-gray-500 sm:text-sm sm:leading-6"
              placeholder="Search" type="search" />
          </div>
        </div>
      </div>
    </header>
    <!-- Workflow list -->
    <div v-if="loading" class="flex items-center justify-center h-full min-h-screen py-8">
      <div role="status">
        <svg aria-hidden="true"
          class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
          viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor" />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill" />
        </svg>
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-else-if="workflows.length === 0">
      <!-- Empty, New Project Button-->
      <div class="px-4 py-4 sm:px-6 lg:px-8">
        <button type="button"
          class="relative block w-full rounded-lg border-2 border-dashed border-gray-700 p-12 text-center hover:border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-offset-2">
          <svg class="mx-auto h-12 w-12 text-gray-500" stroke="currentColor" fill="none" viewBox="0 0 48 48"
            aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6" />
          </svg>
          <span class="mt-2 block text-sm font-semibold text-gray-100">No workflows yet. Create a new workflow!</span>
        </button>
      </div>
      <TemplateSelection />
    </div>
    <div v-else>
      <div>
      </div>
      <!-- Workflow list -->
      <ul role="list" class="divide-y divide-white/5 ">
        <li v-for="workflow in filteredSortedAndPaginatedWorkflows" :key="workflow.id"
          class="relative flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8 transition duration-150 hover:bg-gray-800">
          <div class="min-w-0 flex-auto">
            <div class="flex items-center gap-x-3">
              <div :class="[statuses[workflow.status], 'flex-none rounded-full p-1']">
                <div class="h-2 w-2 rounded-full bg-current" />
              </div>
              <h2 class="min-w-0 text-sm font-semibold leading-6 text-white">
                <router-link :to="`/workspace/workflows/${workflow.id}`" class="flex gap-x-2">
                  <span class="whitespace-nowrap truncate ">{{ workflow.name }}</span>
                  <span class="absolute inset-0" />
                </router-link>
              </h2>
            </div>
            <div class="mt-3 flex items-center gap-x-2.5 text-xs leading-5 text-gray-400">
              <p class="whitespace-nowrap">{{ workflow.date }}</p>
              <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 flex-none fill-gray-300">
                <circle cx="1" cy="1" r="1" />
              </svg>
              <p class="truncate">{{ workflow.description }}</p>
            </div>
          </div>
          <div
            :class="[availabilities[workflow.availability], 'flex-none rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset']">
            {{ workflow.availability }}
          </div>
          <!-- Favorite Icon -->
          <div class="z-10" @click="toggleFavorite(workflow)">
            <StarIconSolid v-if="workflow.isFavorited"
              :class="{ 'text-gray-600 h-4 w-4  cursor-not-allowed z-10': selectedWorkflows.length > 1, 'text-gray-400 h-4 w-4 hover:text-gray-300 cursor-pointer z-10': selectedWorkflows.length <= 1 }" />
            <StarIconOutline v-else
              :class="{ 'text-gray-600 h-4 w-4  cursor-not-allowed z-10': selectedWorkflows.length > 1, 'text-gray-400 h-4 w-4 hover:text-gray-300 cursor-pointer z-10': selectedWorkflows.length <= 1 }" />
          </div>

          <!-- Rename Icon -->
          <PencilSquareIcon @click.stop="renameWorkflow(workflow)"
            :class="{ 'text-gray-600 h-4 w-4  cursor-not-allowed z-10': selectedWorkflows.length > 1, 'text-gray-400 h-4 w-4 hover:text-gray-300 cursor-pointer z-10': selectedWorkflows.length <= 1 }" />
          <!-- Duplicate Icon -->
          <DocumentDuplicateIcon @click.stop="duplicateWorkflow(workflow)"
            :class="{ 'text-gray-600 h-4 w-4 cursor-not-allowed z-10': selectedWorkflows.length > 1, 'text-gray-400 h-4 w-4 hover:text-gray-300 cursor-pointer z-10': selectedWorkflows.length <= 1 }"
            aria-hidden="true" />
          <!-- Delete Icon -->
          <TrashIcon @click.stop="deleteWorkflow(workflow)"
            class="h-4 w-4 text-gray-400 hover:text-gray-300 cursor-pointer z-10" />
          <!-- Checkbox for selecting the workflow -->
          <input type="checkbox" v-model="selectedWorkflows" :value="workflow.id"
            class="z-10 h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900" />
        </li>
      </ul>
      <Pagination class="sticky bottom-0 z-10 bg-[#0F1623] py-6" :currentPage="currentPage"
        :totalItems="filteredWorkflows.length" :itemsPerPage="itemsPerPage" @updatePage="updatePage" />
    </div>
  </main>

  <!-- Activity feed -->

  <aside
    class="bg-[#0F1623] lg:fixed lg:bottom-0 lg:right-0 h-screen lg:w-96 lg:overflow-y-auto lg:border-l lg:border-white/5">
    <header class="flex items-center justify-between border-b border-white/5 px-4 py-4 sm:px-6 sm:py-6 lg:px-8">
      <h2 class="text-base font-semibold leading-7 text-white">Activity feed</h2>
      <a href="#" class="text-sm font-semibold leading-6 text-indigo-400">View all</a>
    </header>
    <TemplateSelection />
    <ul role="list" class="divide-y divide-white/5">
      <li v-for="item in activityItems" :key="item.commit" class="px-4 py-4 sm:px-6 lg:px-8">
        <div class="flex items-center gap-x-3">
          <img :src="item.user.imageUrl" alt="" class="h-6 w-6 flex-none rounded-full bg-gray-800" />
          <h3 class="flex-auto truncate text-sm font-semibold leading-6 text-white">{{ item.user.name }}</h3>
          <time :datetime="item.dateTime" class="flex-none text-xs text-gray-600">{{ item.date }}</time>
        </div>
        <p class="mt-3 truncate text-sm text-gray-500">
          Pushed to
          <span class="text-gray-400">{{ item.projectName }}</span> (<span class="font-mono text-gray-400">{{
            item.commit }}</span>
          on <span class="text-gray-400">{{ item.branch }}</span>)
        </p>
      </li>
    </ul>
  </aside>

  <!-- Rename Modal -->
  <RenameModal v-if="renameModalOpen" :open="renameModalOpen" :name="selectedWorkflowName"
    @update:open="renameModalOpen = $event" @confirm="handleRename" />

  <!-- Duplicate Modal -->
  <DuplicateModal v-if="duplicateModalOpen" :open="duplicateModalOpen" :workflowName="selectedWorkflowName"
    @update:open="duplicateModalOpen = $event" @confirm="handleDuplicate" />

  <!-- Delete Modal -->
  <DeleteModal v-if="deleteModalOpen" :open="deleteModalOpen" :workflowName="selectedWorkflowName"
    @update:open="deleteModalOpen = $event" @confirm="handleDelete" />

  <!-- New Workflow Modal -->
  <NewWorkflowModal v-if="newWorkflowModalOpen" :open="newWorkflowModalOpen" @confirm="createWorkflow"
    @update:open="newWorkflowModalOpen = $event" />
</template>




<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import axios from "axios";
import { useWorkflowEditorStore } from '@/stores/workflowEditorStore';
import { useTaskDefinerStore } from "@/stores/taskDefinerStore";
import { useUserStore } from '@/stores/user';
import { useRoute, useRouter } from "vue-router";
import { io } from 'socket.io-client';

import TemplateSelection from "../../TemplateSelection.vue";
import Pagination from "../../../components/Pagination.vue";
import RenameModal from "../modals/RenameModal.vue";
import DuplicateModal from "../modals/DuplicateModal.vue";
import DeleteModal from "../modals/DeleteModal.vue";
import NewWorkflowModal from "../modals/NewWorkflowModal.vue";

import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  PlusIcon,
  ChevronUpDownIcon,
  StarIcon as StarIconOutline,
  DocumentDuplicateIcon,
  TrashIcon,
  PencilSquareIcon,
  StarIcon,
} from "@heroicons/vue/24/outline";

import {
  StarIcon as StarIconSolid,
  MagnifyingGlassIcon
} from "@heroicons/vue/20/solid"

const workflows = ref([]);
const selectedWorkflows = ref([]);

const searchQuery = ref('');
const userToken = ref(''); // Initialize userToken
const userStore = useUserStore(); // Assuming you're using Pinia or Vuex for state management
const loading = ref(true); // Add a loading state

const route = useRoute();
const workflowId = route.params.workflowId;
const router = useRouter();

// Use the workflowEditor store
const workflowEditorStore = useWorkflowEditorStore();
const taskDefinerStore = useTaskDefinerStore();

const statuses = {
  offline: "text-gray-500 bg-gray-100/10",
  online: "text-amber-400 bg-amber-400/10",
  error: "text-rose-400 bg-rose-400/10",
};

const availabilities = {
  Available: "text-gray-400 bg-gray-400/10 ring-gray-400/20",
  Running: "text-indigo-400 bg-indigo-400/10 ring-indigo-400/30",
};

const activityItems = [
  {
    user: {
      name: "Michael Foster",
      imageUrl:
        "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    projectName: "ios-app",
    commit: "2d89f0c8",
    branch: "main",
    date: "1h",
    dateTime: "2023-01-23T11:00",
  },
  // More items...
];

async function toggleFavorite(workflow) {
  if (selectedWorkflows.value.length > 1) return;
  const userToken = userStore.getToken;
  workflow.isFavorited = !workflow.isFavorited;
  const response = await axios.post(`${process.env.VUE_APP_API_URL}/user/updateWorkflowFavorite`, { workflowId: workflow.id, isFavorited: workflow.isFavorited }, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userToken}`,
    }
  });
  console.log('Response:', response.data);
}

const sidebarOpen = ref(false);
const renameModalOpen = ref(false);
const duplicateModalOpen = ref(false);
const deleteModalOpen = ref(false);
const newWorkflowModalOpen = ref(false);
const selectedWorkflowName = ref("");
const selectedWorkflow = ref(null);

function openNewWorkflowModal() {
  newWorkflowModalOpen.value = true;
}

function renameWorkflow(workflow) {
  if (selectedWorkflows.value.length > 1) return;
  selectedWorkflow.value = workflow;
  selectedWorkflowName.value = workflow.name;
  renameModalOpen.value = true;
}

function handleRename(newName) {
  if (selectedWorkflow.value) {
    selectedWorkflow.value.name = newName;
  }
  renameModalOpen.value = false;
}

function duplicateWorkflow(workflow) {
  if (selectedWorkflows.value.length > 1) return;
  selectedWorkflow.value = workflow;
  selectedWorkflowName.value = workflow.name;
  duplicateModalOpen.value = true;
}

const createWorkflow = async (workflowName) => {

  // console.log('the value of workflow name is ', workflowName);

  // Generate a default name if no workflow name is provided
  if (workflowName == undefined) {
    const currentTime = new Date().toLocaleString();
    workflowName = `Workflow - ${currentTime}`;
  }

  const userToken = userStore.getToken;
  const response = await axios.post(`${process.env.VUE_APP_API_URL}/user/makeBlankWorkflow`, {
    workflowName: workflowName, csvData: [], steps: [], csvFileName: '', csvOutputSegment: [], csvFileData: "",
    fileName: "",
    sheetId: "",
    sheetWorkflowType: "",
    spreadsheetId: "",
    subsheetName: "",
    currentTab: "CSV Upload"
}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userToken}`,
    }
  });
  console.log('Response:', response.data);
  console.log('the workflow id is ', response.data._id);
  router.push(`/workspace/workflows/${response.data._id}/editor`);
}

const handleDuplicate = async (newWorkflowName) => {
  const newWorkflow = {
    ...selectedWorkflow.value,
    id: Date.now(),
    name: newWorkflowName,
  };
  workflows.value.push(newWorkflow);

  const userToken = userStore.getToken;
  const response = await axios.post(`${process.env.VUE_APP_API_URL}/user/cloneWorkflow`, {
    workflowName: newWorkflowName,
    workflowId: selectedWorkflow.value.id
  }, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userToken}`,
    }
  });
  console.log('Response:', response.data);
  duplicateModalOpen.value = false;
  router.push({
    path: `/workspace/workflows/${response.data._id}/editor`,
  });
}

async function deleteWorkflow(workflow) {
  if (selectedWorkflows.value.length > 1) {
    selectedWorkflow.value = null;
    selectedWorkflowName.value = "";
    deleteModalOpen.value = true;
  } else {
    selectedWorkflow.value = workflow;
    selectedWorkflowName.value = workflow.name;
    deleteModalOpen.value = true;
  }
}

async function bulkDeleteWorkflows() {
  const userToken = userStore.getToken;
  if (selectedWorkflows.value.length > 0) {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/user/bulkDeleteWorkflows`, { workflowIds: selectedWorkflows.value }, {
      headers: {
        'Content-Type': 'application/json',  // Ensuring Content-Type is set
        'Authorization': `Bearer ${userToken}`,  // Adding Authorization header
      }
    });
    console.log("Bulk delete response:", response.data);
    // Remove deleted workflows from the local state
    selectedWorkflows.value.forEach(id => {
      const index = workflows.value.findIndex(workflow => workflow.id === id);
      if (index > -1) {
        workflows.value.splice(index, 1);
      }
    });
    selectedWorkflows.value = [];
  }
}

async function handleDelete() {
  const userToken = userStore.getToken;
  if (selectedWorkflows.value.length > 0) {
    await bulkDeleteWorkflows();
  } else if (selectedWorkflow.value) {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/user/deleteWorkflow`, { workflowId: selectedWorkflow.value.id }, {
      headers: {
        'Content-Type': 'application/json',  // Ensuring Content-Type is set
        'Authorization': `Bearer ${userToken}`,  // Adding Authorization header
      }
    });
    console.log("response.data message is ", response.data.message);
    const index = workflows.value.findIndex(
      (w) => w.id === selectedWorkflow.value.id
    );
    if (index > -1) {
      workflows.value.splice(index, 1);
    }
  }
  deleteModalOpen.value = false;
}

const currentPage = ref(1);
const itemsPerPage = ref(10);
const sortByKey = ref(localStorage.getItem('sortByKey') || 'name');

// Function to save the sort key to local storage
function saveSortByKey(key) {
  localStorage.setItem('sortByKey', key);
  sortByKey.value = key;
}

const paginatedWorkflows = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return workflows.value.slice(start, end);
});

function updatePage(page) {
  currentPage.value = page;
}
function sortBy(key) {
  saveSortByKey(key);
}

const sortedAndPaginatedWorkflows = computed(() => {
  const sortedWorkflows = [...workflows.value].sort((a, b) => {
    if (sortByKey.value === 'favorites') {
      return (a.isFavorited === b.isFavorited) ? 0 : a.isFavorited ? -1 : 1;
    } else if (sortByKey.value === 'name') {
      return a.name.localeCompare(b.name);
    } else if (sortByKey.value === 'updatedAt') {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    } else if (sortByKey.value === 'status') {
      return a.status.localeCompare(b.status);
    }
    return 0;
  });
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return sortedWorkflows.slice(start, end);
});

const currentSortLabel = computed(() => {
  const sortLabels = {
    favorites: 'Favorites',
    name: 'Name',
    updatedAt: 'Date updated',
    status: 'Status'
  };
  return sortLabels[sortByKey.value] || 'Name';
});

const filteredWorkflows = computed(() => {
  return workflows.value.filter(workflow =>
    workflow.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
    workflow.description.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});

const filteredSortedAndPaginatedWorkflows = computed(() => {
  const sortedWorkflows = [...filteredWorkflows.value].sort((a, b) => {
    if (sortByKey.value === 'favorites') {
      return (a.isFavorited === b.isFavorited) ? 0 : a.isFavorited ? -1 : 1;
    } else if (sortByKey.value === 'name') {
      return a.name.localeCompare(b.name);
    } else if (sortByKey.value === 'updatedAt') {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    } else if (sortByKey.value === 'status') {
      return a.status.localeCompare(b.status);
    }
    return 0;
  });
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return sortedWorkflows.slice(start, end);
});

function updateSearch() {
  currentPage.value = 1;
}

async function fetchWorkflows() {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API_URL}/user/getWorkflows`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userStore.getToken}`, // Assuming userStore has a method to get the token
      }
    });
    workflows.value = response.data.map(transformWorkflow);
    console.log('Workflows:', workflows.value);
  } catch (error) {
    console.error('Error fetching workflows:', error.response ? error.response.data : error.message);
  } finally {
    loading.value = false; // Set loading to false once the request is complete
  }
}

function transformWorkflow(apiWorkflow) {
  return {
    id: apiWorkflow.workflowId,
    name: apiWorkflow.workflowName,
    status: apiWorkflow.running ? "online" : 'offline', // Default status, adjust as necessary
    statusText: "New workflow created", // Default status text, adjust as necessary
    description: 'Workflow Description goes here',
    date: "4:55:32PM EST 08/11/2024",
    availability: "Available", // Default availability, adjust as necessary
    isFavorited: apiWorkflow.isFavorited  // Default favorite status, adjust as necessary
  };
}


onUnmounted(() => {
  socket.disconnect();
  console.log('disconnected station client')

})

let socket;


onMounted(() => {

  
  socket = io(`${process.env.VUE_APP_API_URL}`, {
    withCredentials: true,
    query: { workflowId: 'home' },  
  });

  socket.on('disconnect', () => {
    console.log('Disconnected from server');
  });

  console.log('connected to socket io on station');


  socket.on('job_started', async (data) => {

console.log('received job started signal!');
console.log('the workflow id is ' , data);


// Find the index of the object you want to modify
const index = workflows.value.findIndex(workflow => workflow.id == data);

// Modify the specific object (obj1 in this case)
if (index !== -1) {
  // You can update specific fields like this
  workflows.value[index] = {
    ...workflows.value[index], // Spread the existing properties
    status: 'online', // Update the status field
  };
}

// workflows.value
// isRunning.value = false;
});


  socket.on('job_completed', async (data) => {

    console.log('received job completed signal!');
    console.log('the workflow id is ' , data);


    // Find the index of the object you want to modify
    const index = workflows.value.findIndex(workflow => workflow.id == data);

    // Modify the specific object (obj1 in this case)
    if (index !== -1) {
      // You can update specific fields like this
      workflows.value[index] = {
        ...workflows.value[index], // Spread the existing properties
        status: 'offline', // Update the status field
      };
    }

    // workflows.value
    // isRunning.value = false;
  });

  fetchWorkflows();
  sortByKey.value = localStorage.getItem('sortByKey') || 'name';
});
</script>




<style lang="scss" scoped></style>
