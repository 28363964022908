// eventNewBus.js
import { ref } from 'vue';

const bus = ref(new Map());

export default function useNewEventBus() {
    function emitNew(event, ...args) {
        const eventHandlers = bus.value.get(event) || [];
        return Promise.all(eventHandlers.map(handler => handler(...args)));
    }


    function on(event, handler) {
        const eventHandlers = bus.value.get(event) || [];
        if (!eventHandlers.includes(handler)) {
            eventHandlers.push(handler);
            bus.value.set(event, eventHandlers);
        }
    }


    function off(event, handler) {
        const eventHandlers = bus.value.get(event) || [];
        const index = eventHandlers.indexOf(handler);
        if (index !== -1) {
            eventHandlers.splice(index, 1);
            bus.value.set(event, eventHandlers);
        }
    }

    function offAll(event) {
        if (bus.value.has(event)) {
            bus.value.delete(event);
        }
    }



    // function on(event, handler) {
    //     const eventHandlers = bus.value.get(event) || [];
    //     eventHandlers.push(handler);
    //     bus.value.set(event, eventHandlers);
    // }

    return {
        emitNew,
        on,
        off,
        offAll,
        bus
    };
}
