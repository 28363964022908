// stores/user.js
import { defineStore } from 'pinia';
import jwtDecode from 'jwt-decode';
import axios from 'axios';

export const useUserStore = defineStore('user', {
  state: () => ({
    token: localStorage.getItem("token") || null,
    user: JSON.parse(localStorage.getItem("user")) || null,  // Restore user from localStorage
  }),
  getters: {
    isAuthenticated: (state) => !!state.token,
    getToken: (state) => state.token,
    getUser: (state) => state.user,
    getEmail: (state) => state.user ? state.user.email : null
  },
  actions: {
    async setToken(token) {
      this.token = token;
      if (token) {
        localStorage.setItem("token", token);
        const decodedUser = jwtDecode(token);
        this.user = decodedUser;  // Decode token and update user state
        localStorage.setItem("user", JSON.stringify(decodedUser)); // Store decoded user in localStorage
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        this.user = null;  // Clear user state
      }
    },
    setUser(user) {
      this.user = user;
      localStorage.setItem("user", JSON.stringify(user));  // Ensure user is stored in localStorage
    },
    clearToken() {
      this.token = null;
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      this.user = null;  // Ensure user is also cleared
    },
    saveToken(token) {
      this.setToken(token);
    },
    logout() {
      this.clearToken();
    },
    async verifyToken(token) {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/auth/verify`, { token }, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          }
        });
        return response.data;
      } catch (error) {
        console.error("Token verification error:", error);
        return { status: 'error' };
      }
    },

    async login(credentials) {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/auth/login`, credentials, {
          headers: {
            "Content-Type": "application/json"
          }
        });

        if (response.data.status === 'success') {
          this.saveToken(response.data.token);
          return { status: 'success' };
        } else {
          return { status: 'error', message: response.data.message };
        }
      } catch (error) {
        console.error('Login error:', error);
        return { status: 'error', message: 'An error occurred during login.' };
      }
    },
  },
});

