import tagify from "@yaireo/tagify";

// Variables
export var before;
export var after;
export var all;
export var caretPosition = 0;

export var tagifyRef;


export function setTagifyRef(targetRef) {
    tagifyRef = targetRef;
}

// Functions
export function getLastWordBeforeCaret(tagify, element = null) {
    var containerEl;
    if (element == null) {
        containerEl = tagify.DOM.input;
    } else {
        containerEl = element;
    }
    let preceding = '';
    let sel;
    let range;
    if (window.getSelection) {
        sel = window.getSelection();
        if (sel && sel.rangeCount > 0) {
            range = sel.getRangeAt(0).cloneRange();
            range.collapse(true);
            if (element == null) {
                range.setStart(containerEl, 0);
                preceding = range.toString();
                return preceding;
            }
            if (element && rangeIntersectsNode(range, element)) {
                const caretPos = range.startOffset;
                preceding = element.textContent.substring(0, caretPos);
            } else {
                range.setStart(containerEl, 0);
                preceding = "RUSSXYZ";
            }
        }
    }
    return preceding;
}


// Helper function to check if a node is highlighted
export function isNodeHighlighted(node, selection) {
    const range = selection.getRangeAt(0);

    if (selection.containsNode(node, true)) {
        let startOffset, endOffset;

        if (range.startContainer === node && range.endContainer === node) {
            // The selection is entirely within this node
            startOffset = range.startOffset;
            endOffset = range.endOffset;
        } else if (range.startContainer === node) {
            // The node is at the start of the selection
            startOffset = range.startOffset;
            endOffset = node.textContent.length; // Highlight spans till the end of this node
        } else if (range.endContainer === node) {
            // The node is at the end of the selection
            startOffset = 0; // Highlight spans from the start of this node
            endOffset = range.endOffset;
        } else {
            // The node is fully within the selection but not at the start or end
            startOffset = 0;
            endOffset = node.textContent.length;
        }


        const beforeHighlight = node.textContent.substring(0, startOffset);
        const afterHighlight = node.textContent.substring(endOffset);

        // // Log the start and end offsets
        // console.log(`Highlighted range in node ${node.textContent}: startOffset = ${startOffset}, endOffset = ${endOffset}, without highlight: ${beforeHighlight + afterHighlight} ${node.textContent.substring(startOffset, endOffset)}`);

        return beforeHighlight + afterHighlight;
        // return true; // The node is highlighted
    }

    return false; // The node isn't highlighted
}


export function getElementsBeforeCaret(tagify) {
    const containerEl = tagify.DOM.input;
    let elements = [];
    let final = "";
    let finalAfter = '';
    let sel;
    let range;

    if (window.getSelection) {
        sel = window.getSelection();
        if (sel && sel.rangeCount > 0) {
            range = sel.getRangeAt(0).cloneRange();
            range.collapse(true);
            range.setStart(containerEl, 0);

            const treeWalker = document.createTreeWalker(
                containerEl,
                NodeFilter.SHOW_ALL,
                {
                    acceptNode: function (node) {
                        return rangeIntersectsNode(range, node) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;
                    }
                },
                false
            );

            var count = 0;
            var afterCaret;
            while (treeWalker.nextNode()) {
                let node = treeWalker.currentNode;
                elements.push(node);
                if (node.nodeType === Node.TEXT_NODE) {
                    if (!node.parentElement.classList.contains('tagify__tag-text')) {
                        var isCaretOnText = getLastWordBeforeCaret(tagify, node);
                        if (isCaretOnText == "RUSSXYZ") {
                            final += node.textContent;
                        } else {


                            let originalString = isCaretOnText + node.textContent.substring(isCaretOnText.length, node.textContent.length);

                            const highlightedOffsets = getHighlightedText(node, sel);


                            var afterCaret;

                            if (highlightedOffsets !== null) {
                                // if (highlightedOffsets.startOffset !== highlightedOffsets.endOffset) {
                                    const { startOffset, endOffset } = highlightedOffsets;

                                    // Remove the highlighted portion from the original string
                                    const beforeHighlight = originalString.substring(0, startOffset);
                                    const afterHighlight = originalString.substring(endOffset);

                                    // Combine the parts before and after the highlighted portion
                                    originalString = beforeHighlight + afterHighlight;

                                    // Adjust isCaretOnText and afterCaret based on the new originalString
                                    if (startOffset <= isCaretOnText.length) {
                                        // Highlighted portion starts within isCaretOnText
                                        isCaretOnText = beforeHighlight.substring(0, startOffset);
                                        afterCaret = originalString.substring(isCaretOnText.length);
                                    } else {
                                        // Highlighted portion starts in afterCaret
                                        afterCaret = originalString.substring(isCaretOnText.length);
                                    }
                                // } else {
                                //     afterCaret = node.textContent.substring(isCaretOnText.length, node.textContent.length);
                                // }

                            } else {
                                afterCaret = node.textContent.substring(isCaretOnText.length, node.textContent.length);

                            }


                            final += isCaretOnText;
                            finalAfter += afterCaret;
                        }
                    }
                } else if (node.nodeType === Node.ELEMENT_NODE && node.tagName.toLowerCase() === 'span') {
                    const value = node.getAttribute('data-value') || node.textContent;
                    const title = node.parentElement.getAttribute('title');
                    const code = node.parentElement.getAttribute('code');
                    const prefix = node.parentElement.getAttribute('prefix');
                    var nodeReference = node.parentElement.getAttribute('nodeReference');

                    if(nodeReference == 'null') {
                        nodeReference = null;
                    } else {
                        nodeReference = parseInt(nodeReference, 10)
                    }
                    final += `[[{"value":"${title}", "code":"${code}", "prefix":"${prefix}", "nodeReference":${nodeReference}}]]`;
                }
                count++;
            }

            var allElements = getAllElements(tagify, count);

            
            for (var node of allElements) {

                    var isHighlighted = isNodeHighlighted(node, sel);

                    if (isHighlighted !== false) {
                        finalAfter += isHighlighted;
                    }else { 
                        finalAfter += processNode(node);
                    }
            }

            // for (var node of allElements) {
            //     finalAfter += processNode(node);
            // }
        }
    }

    return { final, finalAfter };
}


export function getHighlightedText(node, selection) {
    const range = selection.getRangeAt(0);

    if (selection.containsNode(node, true)) {
        let startOffset, endOffset;

        if (range.startContainer === node && range.endContainer === node) {
            // The selection is entirely within this node
            startOffset = range.startOffset;
            endOffset = range.endOffset;
        } else if (range.startContainer === node) {
            // The node is at the start of the selection
            startOffset = range.startOffset;
            endOffset = node.textContent.length; // Highlight spans till the end of this node
        } else if (range.endContainer === node) {
            // The node is at the end of the selection
            startOffset = 0; // Highlight spans from the start of this node
            endOffset = range.endOffset;
        } else {
            // The node is fully within the selection but not at the start or end
            startOffset = 0;
            endOffset = node.textContent.length;
        }


        const beforeHighlight = node.textContent.substring(0, startOffset);
        const afterHighlight = node.textContent.substring(endOffset);

        // // Log the start and end offsets
        // console.log(`Highlighted range in node ${node.textContent}: startOffset = ${startOffset}, endOffset = ${endOffset}, without highlight: ${beforeHighlight + afterHighlight} ${node.textContent.substring(startOffset, endOffset)}`);
        return {startOffset, endOffset}
    }

    return false; // The node isn't highlighted
}


export function getAllElements(tagify, count) {
    let alltest = [];
    const containerEl = tagify.DOM.input;
    if (!containerEl) {
        console.error('Container element not found');
        return elements;
    }

    const range = document.createRange();
    range.selectNodeContents(containerEl);

    const newWalker = document.createTreeWalker(
        containerEl,
        NodeFilter.SHOW_ALL,
        {
            acceptNode: function (node) {
                return rangeIntersectsNode(range, node) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;
            }
        },
        false
    );
    var testCount = 0;
    while (newWalker.nextNode()) {
        if (testCount >= count) {
            alltest.push(newWalker.currentNode);
        }
        testCount++;
    }

    alltest = alltest.filter(node =>
        node.nodeType === Node.TEXT_NODE ||
        (node.nodeType === Node.ELEMENT_NODE && node.tagName.toLowerCase() === 'tag')
    );

    alltest = alltest.filter((node, index, array) => {
        if (node.nodeType === Node.TEXT_NODE && index > 0 && array[index - 1].tagName && array[index - 1].tagName.toLowerCase() === 'tag') {
            return false; 
        }
        return true;
    });

    return alltest;
}

export function updateCaretPosition(tagify) {
    all = getAllElements(tagify);
    before = getElementsBeforeCaret(tagify).final;
    after = getElementsBeforeCaret(tagify).finalAfter;

    // console.log('the before caret is ', before);
    // console.log('the after caret is ', after);

    caretPosition = tagifyRef.selectionStart;

    // console.log('the caret position is ', caretPosition);
}

export function rangeIntersectsNode(range, node) {
    var nodeRange;
    if (range.intersectsNode) {
        return range.intersectsNode(node);
    } else {
        nodeRange = node.ownerDocument.createRange();
        try {
            nodeRange.selectNode(node);
        } catch (e) {
            nodeRange.selectNodeContents(node);
        }

        return range.compareBoundaryPoints(Range.END_TO_START, nodeRange) == -1 &&
            range.compareBoundaryPoints(Range.START_TO_END, nodeRange) == 1;
    }
}

export function getSelectedElementTags(win) {
    var range, sel, elmlist, treeWalker, containerElement;
    sel = win.getSelection();
    if (sel.rangeCount > 0) {
        range = sel.getRangeAt(0);
    }

    if (range) {
        containerElement = range.commonAncestorContainer;
        if (containerElement.nodeType != 1) {
            containerElement = containerElement.parentNode;
        }

        treeWalker = win.document.createTreeWalker(
            containerElement,
            NodeFilter.SHOW_ALL,
            function (node) { return rangeIntersectsNode(range, node) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT; },
            false
        );

        elmlist = [treeWalker.currentNode];
        while (treeWalker.nextNode()) {
            elmlist.push(treeWalker.currentNode);
        }
        // console.log(elmlist);

        return elmlist;
    }
}

export function processNode(node) {
    let str = '';

    if (node.tagName !== undefined) {
        if (node.tagName === "TAG") {
            const value = node.getAttribute('data-value') || node.textContent;
            const title = node.getAttribute('title');
            const code = node.getAttribute('code');
            const prefix = node.getAttribute('prefix');
            var nodeReference = node.getAttribute('nodeReference');

            if(nodeReference == 'null') {
                nodeReference = null;
            } else {
                nodeReference =parseInt(nodeReference, 10);
            }

            str += `[[{"value":"${title}", "code":"${code}", "prefix":"${prefix}", "nodeReference": ${nodeReference}}]]`;
        }
    } else {
        if (node.nodeType === Node.TEXT_NODE) {
            const selection = window.getSelection();
            if (selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                if (node === range.startContainer) {
                    const caretPos = range.startOffset;
                    str += node.textContent.substring(caretPos);
                } else {
                    str += node.textContent;
                }
            } else {
                str += node.textContent;
            }
        } else {
            str += node.textContent;
        }
    }

    return str;
}

export function copyToClipboard(text) {
    navigator.clipboard.writeText(text).then(function () {
        console.log('Text copied to clipboard successfully!');
    }, function (err) {
        console.error('Could not copy text: ', err);
    });
}


export function getHighlightedTextFromLastElement(res) {
    if (!res.length) {
        return ""; // No elements in the array
    }

    const lastElement = res[res.length - 1];
    const selection = window.getSelection();

    if (selection.rangeCount === 0) {
        return ""; // No text is selected
    }

    const range = selection.getRangeAt(0); // Get the first range of the selection
    const highlightedText = [];

    // Iterate over the nodes within the range that are part of the last element
    const treeWalker = document.createTreeWalker(
        lastElement,
        NodeFilter.SHOW_TEXT,
        {
            acceptNode: function(node) {
                if (range.intersectsNode(node)) {
                    return NodeFilter.FILTER_ACCEPT;
                } else {
                    return NodeFilter.FILTER_REJECT;
                }
            }
        }
    );

    let currentNode = treeWalker.currentNode;

    while (currentNode) {
        const startOffset = currentNode === range.startContainer ? range.startOffset : 0;
        const endOffset = currentNode === range.endContainer ? range.endOffset : currentNode.textContent.length;

        highlightedText.push(currentNode.textContent.slice(startOffset, endOffset));

        currentNode = treeWalker.nextNode();
    }

    return highlightedText.join('');
}

export function handleCopy(event, tagify) {
    if (event.target.parentElement.tagName !== "TAGS") {
        return;
    }
    var str = "";
    var res = getSelectedElementTags(window);

    res = res.filter(node =>
        node.nodeType === Node.TEXT_NODE ||
        (node.nodeType === Node.ELEMENT_NODE && node.tagName.toLowerCase() === 'tag')
    );

    res = res.filter((node, index, array) => {
        if (node.nodeType === Node.TEXT_NODE && index > 0 && array[index - 1].tagName && array[index - 1].tagName.toLowerCase() === 'tag') {
            return false;
        }
        return true;
    });

    // var partialHighlightText = getHighlightedTextFromLastElement(res);

    // its possible that the last part copied is a tag in which case theres no such thing as "partial text"
    // so just copy the entire dynamic variable
    if (res[res.length - 1 ].tagName == 'TAG') {
        for (let i = 0; i < res.length; i++) {
        var currentNode = processNode(res[i]);
        str += currentNode;
    }

    } else {
        for (let i = 0; i < res.length - 1; i++) {
        var currentNode = processNode(res[i]);
        str += currentNode;

    }
        //  if last node highlighted is text only some of it could be copied so only copy partial text from last node
    var partialHighlightText = getHighlightedTextFromLastElement(res);



        str += partialHighlightText;

    }

    copyToClipboard(str);
}
