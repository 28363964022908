<!-- Usage Example -->
<template>
    <TaskCardThumbnailTemplate 
      :heading="formHeading" 
      :description="description" 
      :icon="formIcon"
    />
  </template>
  
  <script setup>
  import TaskCardThumbnailTemplate from '../../../TaskCardThumbnailTemplate.vue'
  import { NewspaperIcon } from "@heroicons/vue/24/outline"
  // Import the image from the current directory
  import imageScaleIcon from './imagescale.png'

  // Define the properties for the form heading
  const formHeading = "Image Scale Processor"
  const description = "Scale an image."
  const formIcon = imageScaleIcon;
  
  </script>
  