<template>
  <div
    class="bg-gray-700/10 rounded-lg px-6 py-8 text-indigo-400 ring-1 ring-inset ring-indigo-400/30 shadow-lg shadow-indigo-300/50 w-[600px]">
    <div class="flex flex-row justify-between items-center gap-4 mb-6">
      <!-- Left section: Icon and Header/Subheader -->
      <div class="flex flex-row gap-4 items-center">
        <!-- Card Icon -->
        <span class="inline-flex items-center justify-center w-12 h-12 bg-indigo-500 rounded-md shadow-lg">
          <TableCellsIcon class="h-8 w-8 text-white" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" />
        </span>
        <!-- Card Header / Subheader -->
        <div>
          <span class="font-semibold text-white">Job Definer</span>
          <p class="text-sm font-medium leading-6 text-gray-400">
            {{ fileName }}
          </p>
        </div>
      </div>
      <div class="self-start flex flex-row gap-1">
        <!-- Delete Button -->
        <button v-if="csvData.length" type="button" @click="clearFile"
          class="rounded-full p-2 text-white ring-1 ring-inset ring-white/10 hover:bg-gray-700 focus-visible:outline-offset-0">
          <XMarkIcon class="h-4 w-4" aria-hidden="true" />
        </button>
        <!-- Edit Button -->
        <button v-if="hasDelete" type="button" @click="$emit('edit')"
          class="rounded-full p-2 text-white ring-1 ring-inset ring-white/10 hover:bg-gray-700 focus-visible:outline-offset-0">
          <PencilSquareIcon class="h-4 w-4" aria-hidden="true" />
        </button>
      </div>
    </div>


    <!-- Show CSV Button for csv mode -->
    <div v-if="tabs[0].current && csvData.length" class="mt-4">
      <button type="button" @click="showModal = true"
        class="w-full flex justify-center rounded-md bg-indigo-500 py-2 px-4 text-sm font-semibold text-white hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-800">
        {{ fileName }}
      </button>
    </div>

    <!-- Show gogole sheets Button for csv mode -->
    <div v-else-if="tabs[1].current && googleSheetData.length" class="mt-4">
      <button type="button" @click="showModal = true"
        class="w-full flex justify-center rounded-md bg-indigo-500 py-2 px-4 text-sm font-semibold text-white hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-800">
        {{ `${taskDefinerStore.googleSpreadsheetName} - ${taskDefinerStore.googleSheetName}` }}
      </button>
    </div>


    <!-- Form content -->
    <form>
      <div class="space-y-6">
        <div class="">
          <div class="mx-auto max-w-7xl">
            <div class="sm:hidden">
              <label for="tabs" class="sr-only">Select a tab</label>
              <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
              <select id="tabs" name="tabs"
                class="block w-full rounded-md border-none bg-white/5 py-2 pl-3 pr-10 text-base text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm"
                @change="changeTab($event.target.value)">
                <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">
                  {{ tab.name }}
                </option>
              </select>
            </div>
            <div class="hidden sm:block">
              <nav class="flex border-b border-white/10 py-4">
                <ul role="list"
                  class="flex min-w-full flex-none gap-x-6 px-2 text-sm font-semibold leading-6 text-gray-400">
                  <li v-for="tab in tabs" :key="tab.name">
                    <a href="#" @click.prevent="selectTab(tab.name)" :class="tab.current ? 'text-indigo-400' : ''">{{
                      tab.name }}</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <!-- Conditional content based on the selected tab -->
        <div v-if="tabs[0].current" class="flex flex-col gap-4">
          <!-- Website input with uneditable span prepended -->
          <div>
            <div class="flex flex-row justify-between">
              <div class="flex flex-row gap-4">
                <label class="block text-sm font-medium leading-6 text-white">Output CSV Name</label>
              </div>
              <div class="flex flex-row gap-2">
                <AddDV :card="workflowEditorStore.cards[0]" mode="csv" :targetDynamicId="'csv'"></AddDV>
                <!-- <AddVariable :variables="dynamicVariables" targetElementId="output-csv-name" /> -->
              </div>
            </div>
            <div class="mt-2">
              <div
                class="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                <DynamicInputElement ref="dynamicReference" targetDynamicId="csv" :label="csv" mode="csv"
                  @updateCsvTag="$emit('updateCsvTag', $event)"></DynamicInputElement>
                <span class="flex select-none items-center pr-2 text-gray-500 sm:text-sm">.csv</span>
              </div>
            </div>
            <div v-if="dropdownVisible && selectedElementId === element.id" class="relative">
              <div
                class="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-green-100 shadow-lg ring-1 ring-white/20 focus:outline-none">
                <div class="py-1">
                  <div v-for="variable in variables" :key="variable"
                    class="pl-3 pr-4 py-2 text-xs flex flex-row font-medium text-green-700 cursor-pointer hover:bg-green-50"
                    @click="selectVariable(variable, element.id)">
                    <BoltIcon class="h-3 w-3 mr-1 fill-green-700" viewBox="0 0 12 12" aria-hidden="true"></BoltIcon>
                    {{ variable }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <SwitchGroup as="div" class="flex items-center justify-between">
            <span class="flex flex-grow flex-col">
              <SwitchLabel as="span" class="text-sm font-medium leading-6 text-white" passive>Show costs in CSV
              </SwitchLabel>
            </span>
            <Switch v-model="enabled" :class="[
              enabled ? 'bg-indigo-500' : 'bg-gray-600',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
            ]">
              <span aria-hidden="true" :class="[
                enabled ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
              ]" />
            </Switch>
          </SwitchGroup>
          <div class="flex items-center justify-center w-full" @dragover.prevent @drop.prevent="handleDrop">
            <label for="dropzone-file"
              class="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer dark:hover:bg-bray-800 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
              <div class="flex flex-col items-center justify-center pt-5 pb-6">
                <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                </svg>
                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span class="font-semibold">Click to upload</span> or drag and
                  drop
                </p>
                <p class="text-xs text-gray-500 dark:text-gray-400">
                  File: CSV
                </p>
              </div>
              <input id="dropzone-file" type="file" accept=".csv" class="hidden" @change="handleFileUpload" />
            </label>
          </div>
        </div>
        <div v-else-if="tabs[1].current" class="flex flex-col gap-4">
          <!-- <button type="button" @click="linkToGoogle"
            class="w-full flex justify-center rounded-md bg-indigo-500 py-2 px-4 text-sm font-semibold text-white hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-800">
            Link spreadsheet
          </button> -->

          <SpreadsheetModal :open="modalOpen" :spreadsheets="spreadsheetList" :childSheets="childSheets"
            @confirm="handleSpreadsheetSelect" @update:open="modalOpen = $event"
            @spreadsheet-changed="updateChildSheets" />




          <!-- <SwitchGroup as="div" class="flex items-center justify-between">
            <span class="flex flex-grow flex-col">
              <SwitchLabel as="span" class="text-sm font-medium leading-6 text-white" passive>
                Populate same sheet
              </SwitchLabel>
            </span>
            <Switch v-model="populateEnabled" :class="[
              populateEnabled ? 'bg-indigo-500' : 'bg-gray-600',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
            ]">
              <span aria-hidden="true" :class="[
                populateEnabled ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
              ]" />
            </Switch>
          </SwitchGroup> -->

          <div class="mt-4">
            <fieldset>
              <legend class="sr-only">Populate Options</legend>
              <div class="space-y-4">
                <div class="flex items-center">
                  <input id="same-sheet" name="sheetWorkflowType" type="radio" v-model="sheetWorkflowType"
                    value="populateSameSheet" class="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                    @change="editSheetWorkflowType" />
                  <label for="same-sheet" class="ml-3 block text-sm font-medium text-white">
                    Populate same sheet
                  </label>
                </div>

                <div class="flex items-center">
                  <input id="new-subsheet" name="sheetWorkflowType" type="radio" v-model="sheetWorkflowType"
                    value="createNewSubsheet" class="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                    @change="editSheetWorkflowType" />
                  <label for="new-subsheet" class="ml-3 block text-sm font-medium text-white">
                    Create in new subsheet
                  </label>
                </div>

                <div class="flex items-center">
                  <input id="new-file" name="sheetWorkflowType" type="radio" v-model="sheetWorkflowType"
                    value="createNewFile" class="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                    @change="editSheetWorkflowType" />
                  <label for="new-file" class="ml-3 block text-sm font-medium text-white">
                    Create in new file
                  </label>
                </div>
              </div>
            </fieldset>
          </div>

          <!-- Inputs for Subsheet Name and File Name -->
          <div class="mt-4 space-y-4">
            <!-- Subsheet Name Input -->
            <div>
              <label for="subsheet-name" class="block text-sm font-medium leading-6 text-white">Subsheet Name</label>
              <div class="mt-2">
                <input type="text" id="subsheet-name" v-model="subSheetName" @input="updateGoogleSheetName"
                  class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  placeholder="Enter subsheet name"
                  :disabled="sheetWorkflowType !== 'createNewSubsheet' && sheetWorkflowType !== 'createNewFile'" />
              </div>
            </div>

            <!-- File Name Input -->
            <div>
              <label for="file-name" class="block text-sm font-medium leading-6 text-white">File Name</label>
              <div class="mt-2">
                <input type="text" id="file-name" v-model="googleFileName" @input="updateGoogleFileName"
                  class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  placeholder="Enter file name" :disabled="sheetWorkflowType !== 'createNewFile'" />
              </div>
            </div>
          </div>

          <div>
            <div class="flex flex-row justify-between">
              <div class="flex flex-row gap-4">
                <label class="block text-sm font-medium leading-6 text-white">Input sheet</label>
              </div>
              <div class="flex flex-row gap-2">
                <ConnectButton @click="linkToGoogle" />
                <!-- <AddVariable :variables="dynamicVariables" targetElementId="output-csv-name" /> -->
              </div>
            </div>
            <div class="mt-2">
              <div class="mt-2">
                <input type="text" name="first-name" readonly id="first-name" autocomplete="given-name"
                  :value="`${taskDefinerStore.googleSpreadsheetName} - ${taskDefinerStore.googleSheetName}`"
                  class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
              </div>
            </div>
          </div>

          <div>
            <div class="flex flex-row justify-between">
              <div class="flex flex-row gap-4">
                <!-- <label class="block text-sm font-medium leading-6 text-white">Output sheet</label> -->
              </div>
              <div class="flex flex-row gap-2">
                <!-- <ConnectButton :class="populateEnabled ? 'opacity-50 cursor-not-allowed' : ''" :disabled="enabled"  @click="linkToGoogle" /> -->
                <!-- <AddVariable :variables="dynamicVariables" targetElementId="output-csv-name" /> -->
              </div>
            </div>
            <div class="mt-2">
              <div class="mt-2">
                <!-- <input type="text" name="first-name" id="first-name" autocomplete="given-name"
                  :class="['block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset', enabled ? 'bg-gray-500/50 ring-gray-400/50' : 'bg-white/5 ring-white/10 focus:ring-indigo-500']"
                  :disabled="populateEnabled" class="sm:text-sm sm:leading-6" /> -->
              </div>
            </div>
          </div>

        </div>

        <div v-else-if="tabs[2].current" class="flex flex-col gap-4">
    <div class="col-span-full">
      <label for="about" class="block text-sm font-medium leading-6 text-white">Comma-based line input</label>
      <div class="mt-2">
        <textarea
          id="replace"
          name="replace"
          rows="3"
          class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
          v-model="userInput"
          @input="processTextInput"
        />
      </div>
    </div>
  </div>
      </div>
    </form>
  </div>

  <!-- Modal for displaying full CSV data -->
  <transition name="fade">
    <div v-if="showModal" class="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50"
      @keydown.esc="closeModal" @click.self="closeModal">
      <div
        class="bg-gray-900 rounded-lg p-6 w-full max-w-6xl max-h-[90vh] overflow-auto ring-1 ring-inset ring-white/10"
        @click.stop>
        <div class="flex justify-between items-center mb-4">
          <h3 class="text-lg font-semibold text-white">{{ fileName }}</h3>
          <div class="flex items-center space-x-2">
            <button @click="downloadCSV" class="text-gray-400 hover:text-white">
              <span class="sr-only">Download</span>
              <ArrowDownOnSquareIcon class="h-6 w-6" aria-hidden="true" />
            </button>
            <button @click="closeModal" class="text-gray-400 hover:text-white">
              <span class="sr-only">Close</span>
              <XCircleIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
        <div class="overflow-auto">
          <!-- Conditionally render different table content based on active tab -->
          <template v-if="tabs[0].current">
            <!-- Render table for the first tab -->
            <table class="min-w-full divide-y divide-gray-700">
              <thead>
                <tr>
                  <th v-for="header in headers" :key="header"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0">
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-800">
                <tr v-for="(row, rowIndex) in csvData" :key="rowIndex">
                  <td v-for="header in headers" :key="header"
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-300 max-w-[150px] overflow-auto">
                    <span v-if="row[header] && row[header].length > 50">
                      <button @click="previewText(row[header])" class="ml-2 text-indigo-400">
                        <EyeIcon class="h-4 w-4 mr-2 inline-block" />
                      </button>
                      <span>{{ truncate(row[header]) }}</span>
                    </span>
                    <span v-else>{{ row[header] }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
          <template v-else-if="tabs[2].current">
            <!-- Render table for the second tab -->
            <table class="min-w-full divide-y divide-gray-700">
              <thead>
                <tr>
                  <!-- Render headers based on the first element of the array -->
                  <th v-for="(header, index) in googleSheetData[0]" :key="index"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0">
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-800">
                <tr v-for="(row, rowIndex) in googleSheetData.slice(1)" :key="rowIndex">
                  <!-- Render each row based on the subsequent arrays -->
                  <td v-for="(cell, cellIndex) in row" :key="cellIndex"
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-300 max-w-[150px] overflow-auto">
                    <!-- Render content differently if necessary -->
                    <span v-if="typeof cell === 'string' && cell.length > 50">
                      <button @click="previewText(cell)" class="ml-2 text-red-400">
                        <EyeIcon class="h-4 w-4 mr-2 inline-block" />
                      </button>
                      <span>{{ truncate(cell) }}</span>
                    </span>
                    <span v-else>{{ cell }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
          <!-- Add more conditions for additional tabs if necessary -->
        </div>
      </div>
    </div>
  </transition>


  <!-- Modal for previewing text -->
  <transition name="fade">
    <div v-if="showTextModal" class="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50"
      @keydown.esc="closeTextModal" @click.self="closeTextModal">
      <div class="bg-gray-900 ring-1 ring-inset ring-white/10 rounded-lg p-6 w-full max-w-lg max-h-[70vh] overflow-auto"
        @click.stop>
        <div class="flex justify-between items-center mb-4">
          <h3 class="text-lg font-semibold text-white">Preview Text</h3>
          <button @click="closeTextModal" class="text-gray-400 hover:text-white">
            <span class="sr-only">Close</span>
            <XCircleIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="text-sm text-gray-300">
          {{ previewContent }}
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from "vue";
import { useUserStore } from '@/stores/user';
import axios from 'axios';
import SpreadsheetModal from "../../SpreadsheetModal.vue";
import {
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
} from "@headlessui/vue";
import { useTaskDefinerStore } from "@/stores/taskDefinerStore";
import { useWorkflowEditorStore } from '@/stores/workflowEditorStore';
import Papa from "papaparse";
import { TableCellsIcon, XMarkIcon, BoltIcon } from "@heroicons/vue/20/solid";
import {
  TrashIcon,
  PencilSquareIcon,
  ArrowDownOnSquareIcon,
  EyeIcon,
  XCircleIcon,
} from "@heroicons/vue/24/outline";
import DynamicInputElement from "@/components/DynamicInputElement.vue";

import AddVariable from "../card_components/AddVariable.vue";
import AddDV from '../../workflows/card_components/AddDV.vue';
import ConnectButton from "../card_components/ConnectButton.vue";


const cloneDataEnabled = ref(true);
const taskDefinerStore = useTaskDefinerStore();
const workflowEditorStore = useWorkflowEditorStore(); // Use the workflowEditor store
const userStore = useUserStore();
const modalOpen = ref(false);
const spreadsheetList = ref([]);

const subSheetName = ref("");
const googleFileName = ref("");
const childSheets = ref([]);
const dynamicReference = ref(null);

const dynamicCSVOutput = ref([]);
const fileName = ref("No file uploaded");
const expandedCells = ref({});
const showModal = ref(false);
const showTextModal = ref(false);
const previewContent = ref("");

const csvData = ref(taskDefinerStore.csvData);
const googleSheetData = ref(taskDefinerStore.googleSheetData);
const headers = ref(taskDefinerStore.headers);
const sheetWorkflowType = ref('same'); // Default option selected

const dynamicVariables = ref(["Variable 1", "Variable 2", "Variable 3"]);

const userInput = ref('');
const processTextInput = () => {
  const lines = userInput.value.split('\n').filter(line => line.trim() !== '');

  // Check if input is valid (at least one line with commas)
  if (lines.length > 0 && lines[0].includes(',')) {
    // First line is treated as headers
    const headers = lines[0].split(',').map(header => header.trim());

    // Process the data rows (excluding the first line which is headers)
    const data = lines.slice(1).map(line => {
      const values = line.split(',').map(value => value.trim());
      const rowObject = {};
      headers.forEach((header, index) => {
        rowObject[header] = values[index] || '';
      });
      return rowObject;
    });

    // Set headers and data in the store
    taskDefinerStore.setSideHeaders(
      headers.map(header => ({
        type: 'header',
        id: header,
        name: header,
        showBolt: false,
      }))
    );

    taskDefinerStore.setHeaders(headers);
    taskDefinerStore.setCSVData(data);
  }
};



// Method to update the subsheet name in the store
function updateGoogleSheetName() {
  taskDefinerStore.setGoogleOutputSheetName(subSheetName.value);
}

// Method to update the file name in the store
function updateGoogleFileName() {
  taskDefinerStore.setGoogleOutputFileName(googleFileName.value);
}


const editSheetWorkflowType = (event) => {
  console.log('type changed to ', event.target.value);
  taskDefinerStore.setSheetWorkflowType(event.target.value);
  // // Update the Pinia store with the selected value
  // workflowStore.updateSheetWorkflowType(event.target.value);

}


// const emit = defineEmits(['tab-changed']);


const loadBackendData = () => {
  console.log('called csv val');
  dynamicReference.value.csvSegmentToTagify(taskDefinerStore.csvOutputSegment);

}

const nestedCsvInvoke = () => {
  console.log('called csv update');
  dynamicReference.value.updateCSVWhitelist();
}


async function linkToGoogle() {

  modalOpen.value = !modalOpen.value;
  const userToken = userStore.getToken
  const response = await axios.post(`${process.env.VUE_APP_API_URL}/user/viewSpreadsheets`, {
    // code: code
  }, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userToken}`,
    }
  });

  console.log('the spreadsheet data is ', response.data);


  spreadsheetList.value = response.data.spreadsheets;

  // oauthEmail.value = response.data.email;

  // console.log('the email data is ' , response.data); 

}


const updateChildSheets = async (selectedSheet) => {
  console.log('the parent sheet was changed to ', selectedSheet);

  const userToken = userStore.getToken
  const response = await axios.post(`${process.env.VUE_APP_API_URL}/user/getChildSpreadsheet`, {
    spreadsheetId: selectedSheet
  }, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userToken}`,
    }
  });

  console.log('the child sheets for ', selectedSheet, ' is ', response.data);

  childSheets.value = response.data.childSheets;
  // getChildSpreadsheet()

}
const handleSpreadsheetSelect = async (selected) => {
  // console.log('the selected spreadsheet value is ' , selected);

  const userToken = userStore.getToken
  const response = await axios.post(`${process.env.VUE_APP_API_URL}/user/getSpreadsheet`, {
    selectedSpreadsheetId: selected.selectedSpreadsheetId,
    selectedChildSheetId: selected.selectedChildSheetId
  }, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userToken}`,
    }
  });

  taskDefinerStore.setGoogleSheetData(response.data.data);

  console.log('the google sheet data is  now ', taskDefinerStore.googleSheetData);

  console.log('the data is ', selected.selectedSpreadsheetName)

  taskDefinerStore.setGoogleSpreadsheetName(selected.selectedSpreadsheetName)
  taskDefinerStore.setGoogleSheetName(selected.selectedChildSheetName)

  taskDefinerStore.setSpreadSheetId(selected.selectedSpreadsheetId);
  taskDefinerStore.setSheetId(selected.selectedChildSheetId);

  taskDefinerStore.setGoogleSideHeaders(taskDefinerStore.googleSheetData[0].map(header => ({
    type: 'header',
    id: header,
    name: header,
    showBolt: false,
  })));

  taskDefinerStore.setGoogleHeaders(taskDefinerStore.googleSheetData[0]);

}

const getCsvOutputVal = () => {
  console.log('the csv val is ', taskDefinerStore.csvOutputSegment);
}


watch(
  () => taskDefinerStore.currentTab,
  (newData) => {

    console.log('the tab has changed');

    selectTab(newData);
  }
);


watch(
  () => taskDefinerStore.sheetWorkflowType,
  (newData) => {


    sheetWorkflowType.value = newData;
  }
);


watch(
  () => taskDefinerStore.googleOutputSheetName,
  (newData) => {

    subSheetName.value = newData;
  }
);

watch(
  () => taskDefinerStore.googleOutputFileName,
  (newData) => {

    googleFileName.value = newData;
  }
);




watch(
  () => taskDefinerStore.googleSheetData,
  (newData) => {


    googleSheetData.value = newData;
  }
);

watch(
  () => taskDefinerStore.csvData,
  (newData) => {

    if (typeof newData === 'string') {
      processFile(newData);
    }

    csvData.value = newData;
    if (newData.length > 0) {
      fileName.value = taskDefinerStore.fileName || "CSV File";
    } else {
      fileName.value = "No file uploaded";
    }
    workflowEditorStore.setTaskDefiner({ csvData: newData, headers: taskDefinerStore.headers, fileName: fileName.value }); // Update taskDefiner in store
  }
);

watch(
  () => taskDefinerStore.fileName,
  (newData) => {
    fileName.value = taskDefinerStore.fileName || "CSV File";
  }
);


watch(
  () => taskDefinerStore.headers,
  (newHeaders) => {
    headers.value = newHeaders;
    workflowEditorStore.setTaskDefiner({ headers: newHeaders, csvData: csvData.value, fileName: fileName.value }); // Update taskDefiner in store
  }
);



const tabs = ref([
  { name: "CSV Upload", current: true },
  { name: "Google Sheets", current: false },
  { name: "Line Input", current: false },
]);

const emits = defineEmits(['tab-changed']);


const selectTab = (tabName) => {

  console.log('the tab is ', tabName);

  const currentTab = tabs.value.find(tab => tab.current);
  console.log('the current tab is ', currentTab);
  if (currentTab) {
    emits('tab-changed', tabName);
  }


  tabs.value.forEach((tab) => {
    tab.current = tab.name === tabName;
  });
};

defineExpose({ nestedCsvInvoke, loadBackendData, selectTab })

const populateEnabled = ref(true);


const changeTab = (tabName) => {


  selectTab(tabName);

  console.log('the tab change was triggered');
  // Emit an event with the name of the current tab
  // selectTab(tabName);
};

const handleFileUpload = (event) => {
  const file = event.target.files[0];
  processFile(file);
};

const handleDrop = (event) => {
  const file = event.dataTransfer.files[0];
  processFile(file);
};


const processFile = (file) => {
  if (file && file.name.endsWith(".csv")) {
    taskDefinerStore.setFileName(file.name);
    Papa.parse(file, {
      complete: (results) => {
        taskDefinerStore.setSideHeaders(results.meta.fields.map(header => ({
          type: 'header',
          id: header,
          name: header,
          showBolt: false,
        })))

        taskDefinerStore.setHeaders(results.meta.fields);
        taskDefinerStore.setCSVData(results.data);
      },
      header: true,
    });
  } else {
    alert("Please upload a valid CSV file.");
  }
};

const clearFile = () => {
  fileName.value = "No file uploaded";
  taskDefinerStore.setHeaders([]);
  taskDefinerStore.setCSVData([]);
  expandedCells.value = {};
  workflowEditorStore.setTaskDefiner({ headers: [], csvData: [], fileName: "No file uploaded" }); // Update taskDefiner in store
};

const previewText = (text) => {
  previewContent.value = text;
  showTextModal.value = true;
};

const closeModal = () => {
  showModal.value = false;
};

const closeTextModal = () => {
  showTextModal.value = false;
};

const downloadCSV = () => {
  const csv = Papa.unparse(csvData.value);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", fileName.value || "data.csv");
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const truncate = (text, length = 50) => {
  return text.length > length ? text.substring(0, length) + "..." : text;
};

const escHandler = (event) => {
  if (event.key === "Escape") {
    closeModal();
    closeTextModal();
  }
};

onMounted(() => {
  window.addEventListener("keydown", escHandler);
});

onBeforeUnmount(() => {
  window.removeEventListener("keydown", escHandler);
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
