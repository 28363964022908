<!-- Usage Example -->
<template>
    <TaskCardThumbnailTemplate 
      :heading="formHeading" 
      :description="description" 
      :icon="formIcon"
    />
  </template>
  
  <script setup>
  import TaskCardThumbnailTemplate from '../../../TaskCardThumbnailTemplate.vue'
  import { NewspaperIcon } from "@heroicons/vue/24/outline"
  // Import the image from the current directory
import llama from './llama.png'

  // Define the properties for the form heading
  const formHeading = "Llama Processor"
  const description = "Generate a reponse with Llama."
  const formIcon = llama;
  
  </script>
  