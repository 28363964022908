<!-- Usage Example -->
<template>
    <TaskCardThumbnailTemplate 
      :heading="formHeading" 
      :description="description" 
      :icon="formIcon"
    />
  </template>
  
  <script setup>
  import TaskCardThumbnailTemplate from '../../../TaskCardThumbnailTemplate.vue'
  import { GlobeAltIcon } from "@heroicons/vue/24/outline"
  
  // Define the properties for the form heading
  const formHeading = "Web Scraper"
  const description = "Scrape content from a webpage."
  const formIcon = GlobeAltIcon
  
  </script>
  