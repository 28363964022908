import { createApp } from 'vue';
import App from './App.vue';
import router from './router.js';
import './assets/styles.css';
import { createPinia } from 'pinia';
import mitt from 'mitt';
import { mittAsync } from './mittAsync';
const emitter = mittAsync();

const app = createApp(App);
const pinia = createPinia();

app.use(router);
app.use(pinia);
app.config.globalProperties.emitter = emitter;



app.mount('#app');
