<!-- WebScraper.vue -->
<template>
  <FormElement 
    ref="formElementRef"
    :heading="formHeading" 
    :subheader="formSubheader" 
    :icon="formIcon" 
    :elements="formElements" 
    :has-delete="hasDelete"
    :cards="props.cards" 
    :card="props.card" 
    @input="emitFormData"
    @triggerCsvChange="nestedCsvInvoke"
    @multiSelectChange="handleMultiSelectChange" 

      />
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import FormElement from '../../../CardTemplate.vue';
import { GlobeAltIcon } from '@heroicons/vue/24/outline';
import { defineProps, defineEmits } from 'vue';
import { useWorkflowEditorStore } from '@/stores/workflowEditorStore';
import useEventsBus from '@/stores/eventBus';
const eventBus = useEventsBus();


const workflowEditorStore = useWorkflowEditorStore();

// Define the properties for the form heading
const formHeading = "MyWebScrape";
const formSubheader = "Website Scraper";
const formIcon = GlobeAltIcon;
const hasDelete = true;



// watch(
//   () => eventBus.bus.value.get('updateForm'),
//   async (val) => {


//     if(props.card.id == val[0]) {
//       formElements.value[1].value = true;
//       // togglePreviewModal(props.card);

//     }

//   }
// );


// Define the form elements
const formElements = ref([
  {
    // id: 'website', // this will be backend id and when fetching workflow we use this id to retrieve frontend schema
    id: 'url',
    type: 'website',
    label: 'URL',
    hasAddVariable: true,
    prefix: 'https://',
    inputType: 'text',
    value:[], // Add value property to track the input value
  },
  {
    // id: 'include-meta-tags',
    id:'includeMeta',
    type: 'checkbox',
    label: 'Include Meta Tags',
    value: false, // Add value property to track the input value
  },
  {
    // id: 'remove-newlines',
    id:'removeNewlines',
    type: 'checkbox',
    label: 'Remove Newlines',
    value: false, // Add value property to track the input value
  },

  {
    // id: 'remove-newlines',
    id:'htmlElementsFilter',
    type: 'multiselect',
    label: 'Tags to Scrape',
    tagOptions:  [
        { tag: 'span' },
        { tag: 'h1' },
        { tag: 'h2' },
        { tag: 'h3' },
        { tag: 'h4' },
        { tag: 'h5' },
        { tag: 'h6' },
        { tag: 'p' },
        { tag: 'a' }
      ],
    value: [], // Add value property to track the input value
  },

  {
    // id: 'remove-newlines',
    id:'characterLimit',
    type: 'input',
    label: 'Character Scrape Limit',
    value: [], // Add value property to track the input value
  },



  
]);

// Reference to FormElement
const formElementRef = ref(null);


// emitFormData

onMounted(() => {

      // seperate the mounted state so that we do not double save states for undo redo
      emit('form-mounted', formElements.value);

  // emit('input', formElements.value);

});

const loadClonedCardData = (backendOptions) => {

for (const propObj of backendOptions) {

  var targetField = formElements.value.find(field => field.id == propObj.id);

  // console.log('the target field is ' , targetField);

  targetField.value = propObj.value;


  emit('input', formElements.value);

// Trigger the rename event in the store before deleting
workflowEditorStore.triggerRenameEvent({});



}

}

const loadBackendData =  (backendData) => {

  console.log('the backend data called', backendData);

  for (const props in backendData.options) {
    // if (props == 'characterLimit') continue;
    console.log('the field is ' , props);
    var targetField = formElements.value.find(field => field.id == props);
    console.log('the target field is ' , targetField);

    if (targetField.type == 'multiselect') {
      targetField.value = backendData.options[props].map((field) => {
        return {
          tag: field
        }
      });

    } else {
      targetField.value = backendData.options[props];
    }

    if(targetField.hasAddVariable) {
      emit('input', formElements.value);

      // Trigger the rename event in the store before deleting
      workflowEditorStore.triggerRenameEvent({});



      // invoke segmentToTagify;

    }

  }

  console.log('the options are now ' , formElements);
  // for (var elements of backendData) {
  
  // }
};

const emit = defineEmits(['input', 'triggerCsvChange']);

const nestedCsvInvoke = (event) => {



formElementRef.value.processCsvChange();


}

defineExpose({ loadBackendData, nestedCsvInvoke, loadClonedCardData })

const handleMultiSelectChange = () => {
  emit('input', formElements.value);
};



const emitFormData = () => {
  emit('input', formElements.value);
};

// Accept the cards prop
const props = defineProps({
  card: {
    type: Object,
    required: true,
  },
  cards: {
    type: Array,
    required: true,
  }
});

watch(() => props.cards, (newCards) => {
  console.log("Updated cards array:", newCards);
});

</script>
