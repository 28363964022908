import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useTaskDefinerStore = defineStore('taskDefiner', () => {

  // set a mode so we know which side header to use for the tagify whitelist
  const csvData = ref([]);
  const googleSheetData = ref([]);
  const googleSpreadsheetName = ref("");
  const googleSheetName = ref("");
  const sideGoogleHeaders = ref([]);
  const spreadsheetId = ref("");
  const sheetId = ref("");
  const googleHeaders = ref([]);
  const currentTab = ref("");

  const googleOutputFileName = ref("");
  const googleOutputSheetName = ref("");

  const setGoogleOutputFileName = (data) => {
    googleOutputFileName.value = data;
  }

  const setGoogleOutputSheetName = (data) => {
    googleOutputSheetName.value = data;
  }


  const sheetWorkflowType = ref("");
  const setSheetWorkflowType = (data) => {
    sheetWorkflowType.value = data;
  }


  const csvOutputSegment = ref([]);
  const headers = ref([]);
  const sideHeaders = ref([]);
  const fileName = ref('No file uploaded');
  const jobOpenedToggle = ref(false);

  const csvDVClicked = ref(false);
  const setCSVData = (data) => {
    csvData.value = data;
  };


  const setSheetId = (data) => {
    sheetId.value = data;
  }

  const setSpreadSheetId = (data) => {
    spreadsheetId.value = data;
  }
  const setCurrentTab = (data) => {
    currentTab.value = data;
  }
  const setGoogleSheetData = (data) => {
    googleSheetData.value = data
  }

  const setGoogleSpreadsheetName = (data) => {
    googleSpreadsheetName.value = data;
  }

  const setGoogleSheetName = (data) => {
    googleSheetName.value = data;
  }



  const setCsvOutputSegment = (data)  => {

    csvOutputSegment.value = data;

  }

  const setJobOpened = (data) => {
    jobOpenedToggle.value = data;
  }

  const setHeaders = (data) => {
    headers.value = data;
  };

  const setGoogleHeaders = (data) => {
    googleHeaders.value = data;
  }

  const setGoogleSideHeaders = (data) => {
    sideGoogleHeaders.value =  data;
  }

  const setSideHeaders = (data) => {
    sideHeaders.value = data;
  }

  const setFileName = (name) => {
    fileName.value = name;
  };

  return { csvData, headers, fileName, setCSVData, setHeaders, setFileName, setSideHeaders ,sideHeaders,jobOpenedToggle, setJobOpened, csvOutputSegment, setCsvOutputSegment, csvDVClicked,googleSheetData,setGoogleSheetData,
    googleSheetName, googleSpreadsheetName, setGoogleSpreadsheetName, setGoogleSheetName,sideGoogleHeaders,googleHeaders, setGoogleSideHeaders, setGoogleHeaders,
    currentTab, setCurrentTab, setSheetId, sheetId, setSpreadSheetId, spreadsheetId, sheetWorkflowType, setSheetWorkflowType,
    setGoogleOutputFileName, setGoogleOutputSheetName,googleOutputSheetName,googleOutputFileName
  };
});
