// GPTProcessor.vue
<template>
  <FormElement 
    ref="formElementRef"
    :heading="formHeading" 
    :subheader="formSubheader" 
    :icon="formIcon" 
    :elements="formElements" 
    :has-delete="hasDelete"
    :cards="props.cards" 
    :card="props.card" 
    @input="emitFormData"
    @triggerCsvChange="nestedCsvInvoke"
      />
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import FormElement from '../../../CardTemplate.vue'
import { NewspaperIcon } from "@heroicons/vue/24/outline"
import { defineProps, defineEmits } from 'vue';
import { useWorkflowEditorStore } from '@/stores/workflowEditorStore';
const workflowEditorStore = useWorkflowEditorStore();
import openAiIcon from '../task_card_thumbnails/openai.png'

// Define the properties for the form heading
const formHeading = "MyGPTResponse"
const formSubheader = "GPT Processor"
const formDescription = "Processes a prompt in GPT."
const formIcon = openAiIcon;
const hasDelete = true

// Define the form elements
const formElements = ref([
{
    // this will be backend id and when fetching workflow we use this id to retrieve frontend schema
    id: 'prompt',
    type: 'website',
    label: 'Prompt',
    hasAddVariable: true,
    inputType: 'text',
    value:[], // Add value property to track the input value
  },

  
  {
    id: 'systemPrompt',
    type: 'textarea',
    label: 'System Prompt',
    value: '', // initial value
  },


  // disabled for now, need to do some backend work to merge the 2 cards together
  {
    // id: 'website', // this will be backend id and when fetching workflow we use this id to retrieve frontend schema
    id: 'imageUrl',
    type: 'website',
    label: 'Image Url',
    hasAddVariable: true,
    inputType: 'text',
    value:[], // Add value property to track the input value
    onlyShowWhen: {
      id: 'gptVision',
      value: true
    }

  },

  {
    id: 'jsonMode',
    type: 'checkbox',
    label: 'JSON Mode',
    value: false, // Add value property to track the input value
  },
  // // disabled for now, need to do some backend work to merge the 2 cards together.
  {
    id: 'gptVision',
    type: 'checkbox',
    label: 'GPT Vision',
    value: false, // Add value property to track the input value
  },
  {
    id: 'promptColumn',
    type: 'checkbox',
    label: 'Show Prompt Column',
    value: false, // Add value property to track the input value
  },

  {
    id: 'temperature',
    type: 'slider',
    label: 'Temperature',
    min: 0,
    max: 2,
    step: 0.05,
    value: 0.7, // initial value
  },

  {
    id: 'gptModel',
    type: 'single-select',
    label: 'GPT Model',
    tagOptions:  [
      {
        tag: 'GPT 3.5',
        id: 'gpt-3.5'
      },

      {
        id: 'gpt-4',
        tag: 'GPT 4',
      },
      {
        id: 'gpt-4o',
        tag: 'GPT 4o',
      },

    // { tag: 'h1' },
    //     { tag: 'h2' },
    //     { tag: 'h3' },
    //     { tag: 'h4' },
    //     { tag: 'h5' },
    //     { tag: 'h6' },
    //     { tag: 'p' },
    //     { tag: 'a' }
      ],
    value: [], // Add value property to track the input value

    // options: [
    //   {
    //     id: 'gpt-3.5',
    //     name: 'GPT 3.5',
    //   },
    //   {
    //     id: 'gpt-4',
    //     name: 'GPT 4',
    //   },
    //   {
    //     id: 'gpt-4o',
    //     name: 'GPT 4o',
    //   },
    // ],
    // selected: {
    //   id: 'gpt-4', // default selected value
    //   name: 'GPT 4'
    // }
  }
])


// Reference to FormElement
const formElementRef = ref(null);


// emitFormData

onMounted(() => {

      // seperate the mounted state so that we do not double save states for undo redo
      emit('form-mounted', formElements.value);

  // emit('input', formElements.value);

});

const loadClonedCardData = (backendOptions) => {

for (const propObj of backendOptions) {

  var targetField = formElements.value.find(field => field.id == propObj.id);

  // console.log('the target field is ' , targetField);

  targetField.value = propObj.value;


  emit('input', formElements.value);

// Trigger the rename event in the store before deleting
workflowEditorStore.triggerRenameEvent({});



}

}

const loadBackendData =  (backendData) => {

  console.log('the backend data called', backendData);

  for (const props in backendData.options) {
    // if (props == 'characterLimit') continue;
    console.log('the field is ' , props);
    var targetField = formElements.value.find(field => field.id == props);
    console.log('the target field is ' , targetField);

    if (targetField.type == 'multiselect') {
      targetField.value = backendData.options[props].map((field) => {
        return {
          tag: field
        }
      });


    } else {
      targetField.value = backendData.options[props];
    }

    // if (targetField.type == 'single-select') {
    //   targetField.value = backendData.options[props].map((field) => {
    //     return {
    //       tag: field
    //     }
    //   });

    // } else {
    //   targetField.value = backendData.options[props];
    // }


    if(targetField.hasAddVariable) {
      emit('input', formElements.value);

      // Trigger the rename event in the store before deleting
      workflowEditorStore.triggerRenameEvent({});



      // invoke segmentToTagify;

    }

  }

  console.log('the options are now ' , formElements);
  // for (var elements of backendData) {
  
  // }
};

const emit = defineEmits(['input', 'triggerCsvChange']);

const nestedCsvInvoke = (event) => {



formElementRef.value.processCsvChange();


}

defineExpose({ loadBackendData, nestedCsvInvoke, loadClonedCardData })



const emitFormData = () => {
  emit('input', formElements.value);
};

// Accept the cards prop
const props = defineProps({
  card: {
    type: Object,
    required: true,
  },
  cards: {
    type: Array,
    required: true,
  }
});

watch(() => props.cards, (newCards) => {
  console.log("Updated cards array:", newCards);
});

</script>