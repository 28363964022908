<template>
  <div class="border-t border-white/10 pt-11">
    <h2 class="px-4 text-base font-semibold leading-7 text-white sm:px-6 lg:px-8">
      Activity
    </h2>
    <table class="mt-6 w-full whitespace-nowrap text-left">
      <colgroup>
        <col class="min-w-0" />
        <col class="min-w-0" />
        <col class="min-w-0" />
        <col class="min-w-0" />
        <col class="min-w-0" />
        <col class="min-w-0" />
        <col class="min-w-0" />
      </colgroup>
      <thead class="border-b border-white/10 text-sm leading-6 text-white">
        <tr>
          <th scope="col" class="py-2 pl-4 font-semibold sm:pl-6 lg:pl-8">
            <a href="#" @click.prevent="sortTable('jobId')" class="group inline-flex">
              Job ID
              <span :class="getSortIconClass('jobId')" class="ml-2 flex-none rounded text-gray-400">
                <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </a>
          </th>
          <th scope="col" class="hidden py-2 pl-0 pr-4 text-left font-semibold sm:table-cell sm:pr-6 lg:pr-8">
            <a href="#" @click.prevent="sortTable('workflowId')" class="group inline-flex">
              Workflow ID
              <span :class="getSortIconClass('workflowId')" class="ml-2 flex-none rounded text-gray-400">
                <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </a>
          </th>
          <th scope="col" class="py-2 pl-0 pr-4 text-left font-semibold sm:pr-8 sm:text-left lg:pr-8">
            <a href="#" @click.prevent="sortTable('status')" class="group inline-flex">
              Status
              <span :class="getSortIconClass('status')" class="ml-2 flex-none rounded text-gray-400">
                <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </a>
          </th>
          <th scope="col" class="py-2 pl-0 pr-4 text-left font-semibold sm:pr-8 sm:text-left lg:pr-8">
            <a href="#" @click.prevent="sortTable('rowCount')" class="group inline-flex">
              Rows Processed
              <span :class="getSortIconClass('rowCount')" class="ml-2 flex-none rounded text-gray-400">
                <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </a>
          </th>
          
          <th scope="col" class="hidden py-2 pl-0 pr-4 text-left font-semibold sm:table-cell sm:pr-6 lg:pr-8">
            <a href="#" @click.prevent="sortTable('updatedAt')" class="group inline-flex">
              Updated At
              <span :class="getSortIconClass('updatedAt')" class="ml-2 flex-none rounded text-gray-400">
                <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </a>
          </th>
          <th scope="col" class="hidden py-2 pl-0 pr-4 text-left font-semibold sm:table-cell sm:pr-6 lg:pr-8">
            Actions
          </th>
        </tr>
      </thead>
      <tbody class="divide-y divide-white/5">
        <tr v-for="job in jobs" :key="job.jobId">
          <td class="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
            <div class="flex items-center gap-x-4">
              <span class="truncate text-sm font-medium leading-6 text-white">{{ job.jobId }}</span>
            </div>
          </td>
          <td class="hidden py-4 pl-0 pr-4 text-left text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
            {{ job.workflowId }}
          </td>
          <td class="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-4 lg:pr-4">
            <div class="flex items-center justify-end gap-x-2 sm:justify-start">
              <div :class="[statuses[job.status], 'flex-none rounded-full p-1']">
                <div class="h-1.5 w-1.5 rounded-full bg-current"></div>
              </div>
              <div class="hidden text-white sm:block">{{ job.status }}</div>
            </div>
          </td>
          <td class="py-4 pl-0 pr-4 text-sm leading-6 text-gray-400 sm:pr-8 lg:pr-8">
            {{ job.rows }}
          </td>
          
          <td class="hidden py-4 pl-0 pr-4 text-left text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
            {{ job.updatedAt }}
          </td>
          <td class="hidden py-4 pl-0 pr-4 text-left text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
            <div class="self-start flex flex-row gap-1">
              <!-- View Button -->
              <button
                class="rounded-full p-2 text-white ring-1 ring-inset ring-white/10 hover:bg-gray-700 focus-visible:outline-offset-0">
                <EyeIcon class="h-4 w-4" aria-hidden="true" />
              </button>
              <!-- Download Button -->
              <button
                class="rounded-full p-2 text-white ring-1 ring-inset ring-white/10 hover:bg-gray-700 focus-visible:outline-offset-0">
                <ArrowDownTrayIcon class="h-4 w-4" aria-hidden="true" />
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- Pagination Component -->
    <Pagination
      :currentPage="currentPage"
      :totalItems="totalItems"
      :itemsPerPage="itemsPerPage"
      @updatePage="handlePageUpdate"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import { useUserStore } from "@/stores/user.js";
import Pagination from "../Pagination.vue";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import { ArrowDownTrayIcon, EyeIcon} from "@heroicons/vue/24/outline"

import statusColors from "../../data/constants.js";
const statuses = {
  Success: statusColors.Green,
  Pending: statusColors.Amber,
  Aborted: statusColors.Rose,
};

const jobs = ref([]);
const currentPage = ref(1);
const itemsPerPage = ref(10);
const totalItems = ref(0);
const sortKey = ref('updatedAt');
const sortDirection = ref('desc');

const userStore = useUserStore();

const fetchJobs = async () => {
  const token = userStore.getToken;
  try {
    const params = {
      page: currentPage.value,
      limit: itemsPerPage.value,
      sortKey: sortKey.value,
      sortDirection: sortDirection.value,
    };

    const response = await axios.get(`${process.env.VUE_APP_API_URL}/user/getJobs`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    });

    jobs.value = transformJobData(response.data.jobs);
    totalItems.value = response.data.totalItems;
  } catch (error) {
    console.error("Error fetching jobs:", error.response ? error.response.data : error.message);
  }
};

const transformJobData = (data) => {
  return data.map((job) => ({
    jobId: job.jobId,
    workflowId: job.workflowId,
    status: job.status === "completed" ? "Success" : job.status === "failed" ? "Aborted" : job.status === "cancelled" ? "Aborted" : job.status === "pending" ? "Pending" : job.status,
    rows: `${job.rowCount}/${job.totalRow}`,
    createdAt: job.createdAt,
    updatedAt: new Date(job.updatedAt).toLocaleString(),
  }));
};

const handlePageUpdate = (page) => {
  currentPage.value = page;
  fetchJobs();
};

const sortTable = (key) => {
  if (sortKey.value === key) {
    // If the key is the same, toggle the sort direction
    sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
  } else {
    // If the key is different, sort in ascending order
    sortKey.value = key;
    sortDirection.value = 'asc';
  }
  fetchJobs();
};

const getSortIconClass = (key) => {
  return sortKey.value === key ? 'text-white' : 'invisible';
};

onMounted(() => {
  fetchJobs();
});
</script>
