<template>
  <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <a href="/">
        <img class="mx-auto h-10 w-auto" src="@/assets/AudareMatic-logo.png" alt="Your Company" />
        <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">Complete Registration</h2>
      </a>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form @submit.prevent="completeRegistration" class="space-y-6">
        <div>
          <label for="username" class="block text-sm font-medium leading-6 text-white">Username</label>
          <div class="mt-2">
            <input 
              id="username" 
              v-model="username" 
              name="username" 
              type="text" 
              required 
              class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" 
            />
          </div>
        </div>

        <div>
          <label for="password" class="block text-sm font-medium leading-6 text-white">Password</label>
          <div class="mt-2">
            <input 
              id="password" 
              v-model="password" 
              name="password" 
              type="password" 
              required 
              class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" 
            />
          </div>
        </div>

        <div>
          <button type="submit" class="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Complete Registration</button>
        </div>
      </form>

      <p class="mt-10 text-center text-sm text-gray-400">
        Already a member?
        <a href="/login" class="font-semibold leading-6 text-indigo-400 hover:text-indigo-300">Sign in</a>
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { useRouter, useRoute } from 'vue-router';

const username = ref('');
const password = ref('');
const router = useRouter();
const route = useRoute();

const completeRegistration = async () => {
  const token = route.query.token; // Get the token from the URL query parameter
  if (!token) {
    alert('Invalid registration link');
    return;
  }

  try {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/auth/complete-registration`, {
      token,
      username: username.value,
      password: password.value
    });

    if (response.status === 200) {
      alert('Registration completed successfully. You can now log in.');
      router.push('/login'); // Redirect to login after successful registration
    }
  } catch (error) {
    alert(`Registration failed: ${error.response.data.message}`);
  }
};
</script>

<style scoped>
/* Add any additional styling if necessary */
</style>
