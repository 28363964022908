// AISignUpTask.vue
<template>
  <FormElement 
    ref="formElementRef"
    :heading="formHeading" 
    :subheader="formSubheader" 
    :icon="formIcon" 
    :elements="formElements" 
    :has-delete="hasDelete"
    :cards="props.cards" 
    :card="props.card" 
    @input="emitFormData"
    @triggerCsvChange="nestedCsvInvoke"
      />
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import FormElement from '../../../CardTemplate.vue'
import { NewspaperIcon, CommandLineIcon } from "@heroicons/vue/24/outline"
import { defineProps, defineEmits } from 'vue';
import { useWorkflowEditorStore } from '@/stores/workflowEditorStore';
const workflowEditorStore = useWorkflowEditorStore();

// Define the properties for the form heading
const formHeading = "AI Processor"
const formSubheader = "AISignUpTask"
const formDescription = "Intelligently sign up on website forms using AI"
const formIcon = CommandLineIcon;
const hasDelete = true

// Define the form elements
const formElements = ref([

{
    id: 'urlSegments',
    type: 'website',
    label: 'Username/Url',
    hasAddVariable: true,
    inputType: 'text',
    value:[], // Add value property to track the input value
  },

  {
    id:'targetFirstName',
    type: 'input',
    label: 'First Name',
    value: [], // Add value property to track the input value
  },

  {
    id:'targetLastName',
    type: 'input',
    label: 'Last Name',
    value: null, // Add value property to track the input value
  },

  {
    id:'targetEmail',
    type: 'input',
    label: 'Email',
    value: null, // Add value property to track the input value
  },


])


// Reference to FormElement
const formElementRef = ref(null);


// emitFormData

onMounted(() => {

    // seperate the mounted state so that we do not double save states for undo redo
    emit('form-mounted', formElements.value);

  // emit('input', formElements.value);

});

const loadClonedCardData = (backendOptions) => {

for (const propObj of backendOptions) {

  var targetField = formElements.value.find(field => field.id == propObj.id);

  // console.log('the target field is ' , targetField);

  targetField.value = propObj.value;


  emit('input', formElements.value);

// Trigger the rename event in the store before deleting
workflowEditorStore.triggerRenameEvent({});



}

}

const loadBackendData =  (backendData) => {

  console.log('the backend data called', backendData);

  for (const props in backendData.options) {
    // if (props == 'characterLimit') continue;
    console.log('the field is ' , props);
    var targetField = formElements.value.find(field => field.id == props);
    console.log('the target field is ' , targetField);

    if (targetField.type == 'multiselect') {
      targetField.value = backendData.options[props].map((field) => {
        return {
          tag: field
        }
      });


    } else {
      targetField.value = backendData.options[props];
    }

    // if (targetField.type == 'single-select') {
    //   targetField.value = backendData.options[props].map((field) => {
    //     return {
    //       tag: field
    //     }
    //   });

    // } else {
    //   targetField.value = backendData.options[props];
    // }


    if(targetField.hasAddVariable) {
      emit('input', formElements.value);

      // Trigger the rename event in the store before deleting
      workflowEditorStore.triggerRenameEvent({});



      // invoke segmentToTagify;

    }

  }

  console.log('the options are now ' , formElements);
  // for (var elements of backendData) {
  
  // }
};

const emit = defineEmits(['input', 'triggerCsvChange']);

const nestedCsvInvoke = (event) => {



formElementRef.value.processCsvChange();


}

defineExpose({ loadBackendData, nestedCsvInvoke, loadClonedCardData })



const emitFormData = () => {
  emit('input', formElements.value);
};

// Accept the cards prop
const props = defineProps({
  card: {
    type: Object,
    required: true,
  },
  cards: {
    type: Array,
    required: true,
  }
});

watch(() => props.cards, (newCards) => {
  console.log("Updated cards array:", newCards);
});

</script>