<template>
  <div class="flex flex-row select-none h-full overflow-hidden">

    <!-- Left Aside: Task List -->
    <aside
      class="bg-[#0F1623] w-72 lg:overflow-y-auto lg:border-l lg:border-r lg:border-b lg:border-white/5 sticky top-0">
      <!--<header class="flex flex-col border-b border-white/5 px-2 py-2 sm:px-6 lg:px-4">
        <h2 class="text-sm font-semibold leading-7 text-white">Task List</h2>
      </header>-->
      <ul role="list" class="divide-y divide-white/5">
        <!-- Job Definer -->
        <li class="bg-gray-700/10 relative flex items-center space-x-4 py-1 cursor-pointer hover:bg-gray-700/20"
          @click="toggleJobDefiner" >
          <div class="min-w-0 flex justify-between w-full px-2 items-center">
            <h2 class="min-w-0 text-xs font-semibold leading-6 text-gray-400">Job Definer</h2>
            <div class="flex items-center">
              <ChevronDownIcon v-if="taskDefinerStore.jobOpenedToggle" class="h-4 w-4 text-gray-400" />
              <ChevronRightIcon v-else class="h-4 w-4 text-gray-400" />
            </div>
          </div>
        </li>
        <ul v-if="taskDefinerStore.jobOpenedToggle" class="pl-4 divide-y divide-white/5">
          <li v-for="(subItem, index) in jobDefinerItems" :key="subItem.id"
            class="bg-gray-700/10 relative flex items-center space-x-4 py-1 cursor-pointer hover:bg-gray-700/20">
            <div class="min-w-0 flex justify-between w-full px-2">
              <h2 class="min-w-0 text-xs font-semibold leading-6 text-gray-400">
                {{ subItem.name }}
              </h2>
              <div class="flex flex-row gap-1 items-center">
                <div class="flex items-center justify-center" :class="{
                  'cursor-pointer': subItem.showBolt,
                  'cursor-default': !subItem.showBolt,
                }">
                  <div v-if="subItem.showBolt"
                    class="flex-none rounded-full bg-green-100 hover:bg-green-50 p-1 text-gray w-4 h-4 flex items-center justify-center cursor-pointer"
                    @click.stop="handleBoltClick({ id: subItem.id, type: subItem.type })">
                    <BoltIcon class="h-4 w-4 fill-green-700 cursor-pointer" viewBox="0 0 12 12" aria-hidden="true">
                    </BoltIcon>
                  </div>
                  <div v-else
                    class="flex-none rounded-full bg-transparent outline-dashed outline-1 outline-white/60 p-1 w-4 h-4 flex items-center justify-center cursor-default"
                    @click.stop="handleBoltClick({ id: subItem.id, type: subItem.type })">
                    <BoltIcon class="h-4 w-4 text-white/60 cursor-default" viewBox="0 0 12 12" aria-hidden="true">
                    </BoltIcon>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <!-- Cards List -->
        <li v-for="(item, index) in nodeDefinerItems" :key="item.id" :class="{
          'active-task': currentCardIndex === index && clickedCardIndex !== index,
          'clicked-task': clickedCardIndex === index,
        }" class="bg-gray-700/10 relative flex items-center space-x-4 py-1 cursor-pointer hover:bg-gray-700/20"
          @click="scrollToCard(index)">
          <div class="min-w-0 flex justify-between w-full px-2">
            <h2 class="min-w-0 text-xs font-semibold leading-6 text-gray-400">{{ item.name }}</h2>
            <div class="flex flex-row gap-1 items-center">

              <button class="text-gray-400 hover:text-white" @click.stop="openRenameModal(index, 'item')">
                <PencilSquareIcon class="h-4 w-4" aria-hidden="true" />
              </button>

              <button class="text-gray-400 hover:text-white" @click.stop="openDuplicateModal(index, 'item')">
                <DocumentDuplicateIcon class="h-4 w-4" aria-hidden="true" />
              </button>
              <button class="text-gray-400 hover:text-white" @click.stop="openDeleteModal(index, 'item')">
                <TrashIcon class="h-4 w-4" aria-hidden="true" />
              </button>
              <button @click="triggerPreview(item.id)"
                    class="rounded-full p-1 text-white ring-1 ring-inset ring-white/10 hover:bg-gray-700 focus-visible:outline-offset-0">
                    <EyeIcon class="h-3 w-3" aria-hidden="true" />
                  </button>

                  <!-- experimental undo and redo -->
<!-- 
                  <button @click="undoCard"
                    class="rounded-full p-1 text-white ring-1 ring-inset ring-white/10 hover:bg-gray-700 focus-visible:outline-offset-0">
                    <TrashIcon class="h-4 w-4" aria-hidden="true" />
                  </button>


                  <button @click="reDoCard"
                    class="rounded-full p-1 text-white ring-1 ring-inset ring-white/10 hover:bg-gray-700 focus-visible:outline-offset-0">
                    <TrashIcon class="h-4 w-4" aria-hidden="true" />
                  </button> -->


              <div class="flex items-center justify-center" :class="{
                'cursor-pointer': item.showBolt,
                'cursor-default': !item.showBolt,
              }">
                <div v-if="item.showBolt"
                  class="flex-none rounded-full bg-green-100 hover:bg-green-50 p-1 text-gray w-4 h-4 flex items-center justify-center cursor-pointer"
                  @click.stop="handleBoltClick({ id: item.id, type: item.type })">
                  <BoltIcon class="h-4 w-4 fill-green-700 cursor-pointer" viewBox="0 0 12 12" aria-hidden="true">
                  </BoltIcon>
                </div>
                <div v-else
                  class="flex-none rounded-full bg-transparent outline-dashed outline-1 outline-white/60 p-1 w-4 h-4 flex items-center justify-center cursor-default"
                  @click.stop="handleBoltClick({ id: item.id, type: item.type })">
                  <BoltIcon class="h-4 w-4 text-white/60 cursor-default" viewBox="0 0 12 12" aria-hidden="true">
                  </BoltIcon>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </aside>

    <!--Workflow Container-->
    <div class="p-4 relative flex flex-col items-center grid-background w-3/4 overflow-y-auto" @scroll="handleScroll">
      <div class=" flex flex-col items-center">
        <JobDefiner  @tab-changed="handleTabChange" ref="jobRef"/>

        <Container group-name="thumbnails" :get-child-payload="getDropzonePayload" @drop="onDropZoneDrop('top', $event)"
          class="flex flex-col w-96 h-16 items-center px-4 pb-2 pt-4 my-6 gap-2 rounded-md border-dashed border-2 border-white/10">

          <Draggable v-for="item in ['dropzone']" :key='top'>
            <PlusIcon class="text-white/50 h-6 w-6" />
            <!-- Your existing button logic for modals -->

          </Draggable>
        </Container>



       <!-- Each Container handles a single card -->
<Container v-for="(card, index) in workflowEditorStore.cards" :key="card.id" group-name="cards"
  @drop="onDrop(index, $event)" :get-child-payload="() => getChildPayload(index)"
  class="relative card-container flex flex-col items-center" @drop-not-allowed="dropNotAllowed">
  <!-- Draggable card -->
  <Draggable :item="card" :drag-not-allowed="dragDisabled">
    <div :class="{
      'shadow-md shadow-indigo-300/50': clickedCardIndex !== index,
      'shadow-lg shadow-indigo-500/70': clickedCardIndex === index,
      'draggable-card': true
    }" class="flex flex-col items-center" @click.stop="handleCardClick(index)">
      <component ref="cardComp" :is="card.type" :key="card.id" :cards="workflowEditorStore.cards" :card="card"
        :formData="card.formData" @remove="openDeleteModal(index)" @rename="openRenameModal(index)"
        @duplicate="openDuplicateModal(index)" @input="updateCardForm(index, $event)" @form-mounted="initForm(index, $event)"
        @triggerCsvChange="csvInvoke" />
    </div>

    <div class="item">
      <Container group-name="thumbnails" :get-child-payload="getDropzonePayload"
        @drop="onDropZoneDrop(card.id, $event)"
        class="flex flex-col w-96 h-16 items-center px-4 pb-2 pt-4 gap-2 my-6 rounded-md border-dashed border-2 border-white/10"
        :card-link="card.id">

        <Draggable v-for="item in ['dropzone']" :key=card.id>
          <!-- Your existing button logic for modals -->
          <PlusIcon class="text-white/50 h-6 w-6" />
        </Draggable>
      </Container>
    </div>
  </Draggable>
</Container>
      </div>
      <!-- <div class="mt-4 flex items-center">
        <button type="button"
          class="rounded-full bg-indigo-500 p-2 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          @click="toggleModal">
          <PlusIcon :class="{ 'rotate-45': showModal }" class="h-5 w-5 transition-transform duration-200"
            aria-hidden="true" />
        </button>
      </div> -->
      <!-- <Transition name="fade">
        <div v-if="showModal" id="modal" class="mt-4">
          <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2">
            <li class="cursor-pointer" @click="addCard('ArticleFetcher')">
              <ArticleFetcherThumbnail />
            </li>
            <li class="cursor-pointer" @click="addCard('WebScraper')">
              <WebScraperThumbnail />
            </li>
            <li class="cursor-pointer" @click="addCard('GPTOutputGenerator')">
              <GPTProcessorThumbnail />
            </li>

          </ul>
        </div>
      </Transition> -->
      <!-- Add the RenameModal component -->
      <RenameModal v-if="showRenameModal" :name="currentCardName" title="Rename Card"
        message="Please enter a new name for the card." @confirm="renameCard" @cancel="closeRenameModal"
        @update:open="showRenameModal = $event" />

      <!-- Add the DeleteModal component -->
      <DeleteModal v-if="showDeleteModal" title="Delete Card"
        message="Are you sure you want to delete this card? This action cannot be undone." confirmButtonText="Delete"
        cancelButtonText="Cancel" :open="showDeleteModal" @confirm="confirmDeleteCard" @cancel="closeDeleteModal"
        @update:open="showDeleteModal = $event" />

      <!-- Add the DuplicateModal component -->
      <DuplicateModal v-if="showDuplicateModal" :workflowName="currentCardName" title="Duplicate Card"
        message="Please enter a new name for the duplicated card." @confirm="duplicateCard"
        @cancel="closeDuplicateModal" @update:open="showDuplicateModal = $event" />

      <UnsavedConfirmationModal v-if="showUnsavedModal" @update:open="showUnsavedModal = $event"
        @leave="closeUnSavedModal" @save="onSave" />

    </div>

    <!--Card List-->
    <aside class="bg-[#0F1623] w-1/4 lg:overflow-y-auto lg:border-l lg:border-white/5 sticky top-0">
      <header class="flex sticky flex-col justify-between border-b border-white/5 px-4 py-4 sm:px-6 lg:px-4">
        <h2 class="text-base font-semibold leading-7 text-white">Cards</h2>
        <p class="text-sm font-medium leading-6 text-gray-400">Drag a card to add it to the workflow.</p>
      </header>
      <Container behaviour="copy" group-name="thumbnails" :get-child-payload="getThumbnailPayload"
        class="flex flex-col px-4 py-4 divide-y gap-2 divide-white/5" :tag="{ value: 'ul' }">
        <!-- list of draggable thumbnails that can be dragged into the card dropzone to add a card  -->
        <Draggable v-for="(component, index) in components" :key="component.id" :item="component"
          :tag="{ value: 'li', props: { class: 'cursor-pointer draggable-card' } }">
          <component :is="component.thumbnail" class="w-full" />
        </Draggable>
      </Container>
    </aside>


  </div>

</template>


<style scoped>
.sticky {
  position: -webkit-sticky;
  /* For Safari */
  position: sticky;
}
</style>

<style scoped>
.draggable-card:hover {
  cursor: grab;
}

.draggable-card:active {
  cursor: grabbing;
}

.draggable-card input:hover,
.draggable-card button:hover,
.draggable-card textarea:hover {
  cursor: default;
}
</style>


<script setup>
import { ref, nextTick, onMounted, computed, reactive, onBeforeUnmount, watch, resolveComponent, shallowRef } from 'vue';
import { useWorkflowEditorStore } from '@/stores/workflowEditorStore';
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
import { useUserStore } from '@/stores/user';
import { useTaskDefinerStore } from '@/stores/taskDefinerStore'; // Adjust the import path as needed
import { VueDraggable } from 'vue-draggable-plus';
import { Container, Draggable } from 'vue-dndrop';

import { BoltIcon } from "@heroicons/vue/20/solid";
import { PencilSquareIcon, TrashIcon, ChevronDownIcon, ChevronRightIcon, DocumentDuplicateIcon, EyeIcon} from "@heroicons/vue/24/outline";

import axios from 'axios';
import ArticleFetcher from './task_cards/ArticleFetcher.vue';
import WebScraper from './task_cards/WebScraper.vue';
import GPTOutputGenerator from './task_cards/GPTOutputGenerator.vue';
import AISignUpTask from './task_cards/AISignUpTask.vue';
import IGScraper from './task_cards/IGScraper.vue';
import GoogleSheetFormulaTask from './task_cards/GoogleSheetFormulaTask.vue';
import ImageScalerTask from './task_cards/ImageScalerTask.vue';
import ClaudeOutputGenerator from './task_cards/ClaudeOutputGenerator.vue';
import LlamaOutputGeneratorTask from './task_cards/LlamaOutputGeneratorTask.vue';

import JobDefiner from './task_cards/JobDefiner.vue';
import RenameModal from '../modals/RenameModal.vue';
import DeleteModal from '../modals/DeleteModal.vue';
import DuplicateModal from '../modals/DuplicateModal.vue';
import UnsavedConfirmationModal from '../modals/UnsavedConfirmationModal.vue';
import { PlusIcon } from '@heroicons/vue/24/outline';
import ArticleFetcherThumbnail from './task_card_thumbnails/ArticleFetcherThumbnail.vue';
import WebScraperThumbnail from './task_card_thumbnails/WebScraperThumbnail.vue';
import GPTProcessorThumbnail from './task_card_thumbnails/GPTProcessorThumbnail.vue';
import IGScraperThumbnail from './task_card_thumbnails/InstagramProcessorThumbnail.vue';
import AISignUpTaskThumbnail from './task_card_thumbnails/AISignUpTaskThumbnail.vue';
import GoogleSheetFormulaTaskThumbnail from './task_card_thumbnails/GoogleSheetFormulaTaskThumbnail.vue';
import ImageScalerTaskThumbnail from './task_card_thumbnails/ImageScalerTaskThumbnail.vue';
import ClaudeOutputGeneratorThumbnail from './task_card_thumbnails/ClaudeOutputGeneratorThumbnail.vue';
import LlamaOutputGeneratorTaskThumbnail from './task_card_thumbnails/LlamaOutputGeneratorTaskThumbnail.vue';
import useEventsBus from '@/stores/eventBus';
import useNewEventBus from '@/stores/eventNewBus';
import DynamicCSVInput from '@/components/dynamicCSVInput.vue';
import Papa, { WORKERS_SUPPORTED } from "papaparse";

// Import or define the debounce function
import { debounce } from 'lodash';
import WorkflowActivity from './WorkflowActivity.vue';
// Debounced saveState function

const triggerPreview = (cardId) => {
  console.log('emitted preview');

  // emitNew('triggerPreview', cardPreview);
  emit('triggerPreview', cardId);
}

const currentTab = ref('');
const components = ref([
  { id: 'ArticleFetcher', thumbnail: ArticleFetcherThumbnail },
  { id: 'WebScraper', thumbnail: WebScraperThumbnail },
  { id: 'GPTOutputGenerator', thumbnail: GPTProcessorThumbnail },
  { id: 'IGScraper', thumbnail: IGScraperThumbnail },
  { id: 'AISignUpTask', thumbnail: AISignUpTaskThumbnail },
  { id: 'GoogleSheetFormulaTask', thumbnail: GoogleSheetFormulaTaskThumbnail },
  { id: 'ImageScalerTask', thumbnail: ImageScalerTaskThumbnail },
  { id: 'ClaudeOutputGenerator', thumbnail: ClaudeOutputGeneratorThumbnail,},
  {id: 'LlamaOutputGeneratorTask', thumbnail: LlamaOutputGeneratorTaskThumbnail}
]);


function getDropzonePayload(index) {
  console.log('getting dropzone payload for ', index);
}
function getThumbnailPayload(index) {
  console.log('getting thumbnail for index: ', index, components.value[index]);
  return components.value[index];
}

// Define getChildPayload function to return the card data for a specific index
function getChildPayload(index) {
  console.log("Getting payload for index:", index, workflowEditorStore.cards[index]); // Debugging: Check if the correct payload is being returned
  return workflowEditorStore.cards[index];
}


const undoTriggered = ref(false);

function onDropZoneDrop(columnIndex, event) {
  // applyDrag(workflowEditorStore.columns[columnIndex], event);
  console.log(`Drop Event on Dropzone  with card id ${columnIndex}:`, event);

  if (event.addedIndex !== null) {
        workflowEditorStore.saveState();

    if (columnIndex !== 'top') {
      addCard(event.payload.id, null, columnIndex);
    } else {
      addCard(event.payload.id, null, 0);

    }

  }

}
// Handle the drop event for the specific container and update state
function onDrop(columnIndex, event) {
  const { removedIndex, addedIndex, payload } = event;

  applyDrag(workflowEditorStore.cards, columnIndex, event);

}


async function applyDrag(arr, index, dragResult) {

  await nextTick();
  const { removedIndex, addedIndex, payload } = dragResult;

  // console.log('drag result ', dragResult);
  if (removedIndex === null && addedIndex === null) return arr;

  if (removedIndex !== null) return;

  const result = [...arr];
  let itemToAdd = payload;

  var targetPayload = workflowEditorStore.cards.findIndex(card => card.id == payload.id);

    workflowEditorStore.saveState();

  console.log("we foudn the payload index which si ", targetPayload);

  workflowEditorStore.cards.splice(targetPayload, 1)
  console.log("now the array is  ", workflowEditorStore.cards);

  if (addedIndex !== null) {
    workflowEditorStore.cards.splice(index, 0, itemToAdd);
  }

  console.log('the result is now ', workflowEditorStore.cards);
  return result;
};

const showModal = ref([]);

const { emit } = useEventsBus();

const { emitNew, on, off, offAll } = useNewEventBus();

const { bus } = useEventsBus();


function dropNotAllowed() {
  return
}



watch(
  () => bus.value.get('undoTriggered'),
  async (val) => {

    undoTriggered.value = true;



  }
);

watch(
  () => bus.value.get('range:in'),
  async (val) => {

    console.log('user is in range');

    dragDisabled.value = true;



  }
);


watch(
  () => bus.value.get('range:out'),
  async (val) => {

    dragDisabled.value = false;

    console.log('user is out of range');


  }
);


// if the user confirms deletion of a workflow we don't need to show the unsaved confirmation modal (since they have deleted it already)
watch(
  () => bus.value.get('deleteConfirm'),
  async (val) => {

    didUserChange.value = false;

  }
);


watch(
  () => bus.value.get('savedWorkflow'),
  async (val) => {

    didUserChange.value = false;

    console.log('now saved do not show modal')


  }
);



watch(
  () => bus.value.get('reRenderEditor'),
  async (val) => {

    console.log('triggered rerender');
    // console.log('the workflow id is now ' ,  route.params.workflowId );
    const userStore = useUserStore();
    const userToken = userStore.getToken
    // const passedWorkflow = route.params.workflowId;
    const workflowId = route.params.workflowId;

    document.addEventListener('click', handleDocumentClick);

    try {
      const response = await axios.post(`${process.env.VUE_APP_API_URL}/user/getWorkflowById`, {
        workflowId: workflowId
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`,
        }
      });

      var workFlowData = response.data;

     workflowEditorStore.resetStacks();

      taskDefinerStore.setHeaders(workFlowData.csvData);
    if (workFlowData.csvFileData) {
      Papa.parse(workFlowData.csvFileData, {
      complete: (results) => {

        taskDefinerStore.setSideHeaders(results.meta.fields.map(header => ({
          type: 'header',
          id: header,
          name: header,
          showBolt: false,
        })));

        taskDefinerStore.setHeaders(results.meta.fields);
        taskDefinerStore.setCSVData(results.data);
      },
      header: true,
    });


    }


      taskDefinerStore.setSideHeaders(workFlowData.csvData.map(header => ({
        type: 'header',
        id: header,
        name: header,
        showBolt: false,
      })))


      taskDefinerStore.setFileName(workFlowData.csvFileName);



      taskDefinerStore.setCsvOutputSegment(workFlowData.csvOutputSegment);

      workflowEditorStore.setWorkflowName(workFlowData.workflowName); // Set the workflow name in the store


      workflowEditorStore.cards = [];
      for (const step of workFlowData.steps) {
        var id = await addCard(step.stepType, step.stepName);
        await nextTick(); // Wait for DOM update
        cardComp.value[cardComp.value.length - 1].loadBackendData(step);

        // callSpecificChildMethod(step);

      }

      jobRef.value.loadBackendData();

      console.log('this called');
      // Handle the response as needed
      console.log(response.data);

      showModal.value = new Array(workflowEditorStore.cards.length).fill(false);

      console.log('the show modal is ', showModal.value);


      // await addCard()
    } catch (error) {
      console.error("Error fetching workflow:", error);
    }

  }
);

const didUserChange = ref(false);
const showRenameModal = ref(false);
const showDeleteModal = ref(false);
const showDuplicateModal = ref(false);
const showUnsavedModal = ref(false);
const currentCardName = ref('');

const dragDisabled = ref(false);

// const cardRefs = ref(new Map());
const cardComp = ref(null);
const jobRef = ref(null);
const route = useRoute();
const router = useRouter();

let resolveNavigation = null;

onBeforeRouteLeave((to, from, next) => {


  console.log('the from is ', from);

  console.log('the user value is ', didUserChange.value);




  resolveNavigation = next;

  if (didUserChange.value) {
    openUnsavedModal()
  } else {
    closeUnSavedModal();
  }

});


const currentCardIndex = ref(null);
const clickedCardIndex = ref(null);

const handleScroll = () => {
  const cardContainers = document.querySelectorAll('div.relative.card-container');

  const viewportHeight = window.innerHeight;
  let currentIndex = null;

  cardContainers.forEach((card, index) => {
    const rect = card.getBoundingClientRect();
    const cardHeight = rect.height;
    const visibleHeight = Math.min(rect.bottom, viewportHeight) - Math.max(rect.top, 0);

    if (visibleHeight > cardHeight / 2) {
      currentIndex = index;
    }
  });


  currentCardIndex.value = currentIndex;
};

const handleCardClick = (index) => {
  console.log('the state of nodes is ', nodeDefinerItems.value);
  clickedCardIndex.value = index;
};

const handleDocumentClick = () => {
  clickedCardIndex.value = null;
};


const csvEmit = () => {

  console.log('the card comp value is ', cardComp.value[0]);

  cardComp.value[0].$emit('triggerCsvChange');

}


const csvInvoke = () => {
  for (const card of cardComp.value) {
    card.nestedCsvInvoke();

  }
}

const jobDefiner = ref(null);

const jobDefinerOpen = ref(false);

const handleTabChange = (val) => {
  currentTab.value = val;

  // so update the csv whitelist here
  taskDefinerStore.setCurrentTab(val);

  try  {
    // the csv columns in google sheets vs csv may be different
    //, so update the validity of the dynamic variable when switching tabs
    for (var cards of cardComp.value) {
      cards.nestedCsvInvoke();
    }


  } catch(error) {

  }
  // console.log('the editor tab is now ' , val);
}
const jobDefinerItems = computed(() => {
  if (currentTab.value == "Google Sheets") {
    return taskDefinerStore.sideGoogleHeaders
  } else {
    return taskDefinerStore.sideHeaders
  }
});


const nodeDefinerItems = computed(() => {
  // Map over workflowEditorStore.cards
  const cardItems = workflowEditorStore.cards.map(card => ({
    type: 'node',
    id: card.id,
    name: card.name,
    showBolt: card.showBolt || false,
  }));
  // Combine the two arrays
  return [...cardItems];
});


const toggleJobDefiner = () => {

  taskDefinerStore.setJobOpened(!taskDefinerStore.jobOpenedToggle);
};



const handleBoltClick = async (boltObj) => {
  console.log('here is bolt click');
  if (boltObj.type == 'header') {

    if(taskDefinerStore.currentTab == "Google Sheets") {
    // console.log(`Bolt clicked for item with id: ${boltObj.id}`);
    if (!taskDefinerStore.sideGoogleHeaders.find(header => header.id === boltObj.id).showBolt) {
      return;
    }

    } else {
          // console.log(`Bolt clicked for item with id: ${boltObj.id}`);
    if (!taskDefinerStore.sideHeaders.find(header => header.id === boltObj.id).showBolt) {
      return;
    }

    }

  } else {

    if (!workflowEditorStore.cards.find(card => card.id === boltObj.id).showBolt) {
      return;
    }


  }
  let dynamicVar;
  if (boltObj.type === 'node') {
    dynamicVar = workflowEditorStore.cards.find(card => card.id === boltObj.id);
  } else {
    dynamicVar = { name: boltObj.id, id: boltObj.id, type: 'header' };
  }

  // console.log('The dynamic var is', dynamicVar);

  await nextTick();


  // let dynamicDestination = workflowEditorStore.cards.slice().reverse().find(card => card.cardDVClicked);

  let dynamicDestination = workflowEditorStore.cards.find(card => card.cardDVClicked);
  // console.log('The destination before triggerAddCardEvent is', dynamicDestination);
  // if(taskDefinerStore.csvDVClicked) {

  // }
  if (!dynamicDestination && !taskDefinerStore.csvDVClicked) {

    console.error('No destination found with cardDVClicked or csvDVClicked set to true');
    return;
  }

  // // Temporarily store the destination before triggering the event
  // const previousDynamicDestination = JSON.parse(JSON.stringify(dynamicDestination));

  await nextTick();

  if (!dynamicDestination) {

    dynamicDestination = taskDefinerStore.csvOutputSegment;

    console.log('the destination is ' , dynamicDestination);

    emit('addCsvInput' , dynamicDestination, { old: dynamicDestination, new: dynamicVar } );

    // workflowEditorStore.triggerAddCardEvent(dynamicDestination, { old: dynamicDestination, new: dynamicVar })

  // // Log the dynamicDestination again after the event to compare
  // dynamicDestination = workflowEditorStore.cards.find(card => card.cardDVClicked);

  } else {
    console.log('the destination is ' , dynamicDestination);
    workflowEditorStore.triggerAddCardEvent(dynamicDestination.id, { old: dynamicDestination, new: dynamicVar })

  }

};



const toggleModal = (index) => {


  // Create a copy of the current state and update the specific card's modal state
  const newShowModal = { ...showModal.value };
  newShowModal[index] = !newShowModal[index];
  showModal.value = newShowModal;

  if (showModal.value[index] && index === Object.keys(showModal.value).length - 1) {
    nextTick(() => {
      document.getElementById(`modal-${index}`).scrollIntoView({ behavior: "smooth" });
    });
  }

  // if (showModal.value[index]) {
  //   nextTick(() => {
  //     document.getElementById(`modal-${index}`).scrollIntoView({ behavior: "smooth" });
  //   });
  // }
};


const workflowEditorStore = useWorkflowEditorStore();
const taskDefinerStore = useTaskDefinerStore();

const addCard = async (type, name = null, index = null) => {

  console.log('the index is ', index);
  console.log('the index is ', name);

  // Find the highest ID in the existing cards
  const highestId = workflowEditorStore.cards.reduce((maxId, card) => {
    return Math.max(maxId, card.id);
  }, 0);

  // Increment the highest ID for the new card
  const id = highestId + 1;

  let cardComponent, cardName;

  if (type === 'ArticleFetcher') {
    
    cardComponent = shallowRef(ArticleFetcher);
    cardName = 'Article Fetcher';
  } else if (type === 'WebScraper') {
    cardComponent = shallowRef(WebScraper);
    cardName = 'Web Scraper';
  } else if (type === 'GPTOutputGenerator') {
    cardComponent = shallowRef(GPTOutputGenerator);
    cardName = 'GPT Processor';
  } else if (type === 'IGScraper') {
    cardComponent = shallowRef(IGScraper);
    cardName = 'Instagram Processor';
  } else if (type === 'AISignUpTask') {
    cardComponent = shallowRef(AISignUpTask);
    cardName = 'Form Processor';
  } else if (type == 'GoogleSheetFormulaTask') {
    cardComponent = shallowRef(GoogleSheetFormulaTask);
    cardName = 'Google Sheet Processor'
  } else if (type == 'ImageScalerTask') {
    cardComponent =shallowRef(ImageScalerTask);
    cardName = 'ImageScalerTask';
  } else if (type == 'ClaudeOutputGenerator') {
    cardComponent = shallowRef(ClaudeOutputGenerator);
    cardName = 'ClaudeProcessor';
  } else if (type == 'LlamaOutputGeneratorTask') {
    cardComponent = shallowRef(LlamaOutputGeneratorTask);
    cardName = 'LlamaProcessor';
  }

  if (name !== null) {
    cardName = name;
  }

  var targetPosition;
  const newCard = { id, type: cardComponent, name: cardName, showBolt: false, cardDVClicked: false, activeDVId: null, options: [{}] };
  if (index !== null) {
    targetPosition = workflowEditorStore.cards.findIndex(card => card.id == index);
    // workflowEditorStore.saveState();

    workflowEditorStore.cards.splice(targetPosition + 1, 0, newCard);

    // workflowEditorStore.saveState();

  } else {
    workflowEditorStore.addCard(newCard);

  }

  if (targetPosition !== undefined && targetPosition !== null) {

    nextTick(() => {
      const card = document.querySelectorAll('.card-container')[targetPosition + 1];
      // const card = document.querySelector(`.card-container:nth-child(${targetPosition + 3})`);
      if (card) {
        flashCard(card);
      }
    });


  } else {
    nextTick(() => {
      const card = document.querySelector(`.card-container:nth-child(${workflowEditorStore.cards.length + 1})`);
      if (card) {
        flashCard(card);
      }
    });


  }

  // setCardRef(id, cardComponent);

  await nextTick(); // Wait for DOM update

  showModal.value = false;

  return id;
};



const saveStateDebounced = debounce( async () => {
  console.log('the state was saved')
  console.log('this state is ' , workflowEditorStore.cards);
  workflowEditorStore.saveState();
}, 100); // Adjust the wait time as needed



const initForm = async (index, options) => {

if (index !== null && workflowEditorStore.cards[index]) {
  console.log('received form init option ', options);
  console.log('index is ', index);

  try {
    console.log('options here is ', options);


    if (!undoTriggered.value) {
      console.log('updated card inputs');
    // Update the current card
    workflowEditorStore.updateCardInputs(index, options);

    }

    // Use nextTick to wait for the DOM updates
    await nextTick();

    // Update the next card if it exists
    if (workflowEditorStore.cards[index + 1]) {
      // workflowEditorStore.updateCardInputs(index + 1, options);

      // Use nextTick to ensure the updates are applied
      await nextTick();

      // // Directly update the options of the next card
      // workflowEditorStore.cards[index + 1].options = options;

      console.log('Updated next card: ', workflowEditorStore.cards[index + 1]);
      console.log('added to index ', index + 1);
    }
  } catch (error) {
    console.log('error is ', error);
  }

  console.log('Updated Card Data:', workflowEditorStore.cards);

  didUserChange.value = true;

}
};


const updateCardForm = async (index, options) => {

  if (index !== null && workflowEditorStore.cards[index]) {
    console.log('received form option ', options);
    console.log('index is ', index);

    try {
      console.log('options here is ', options);


      // if (!undoTriggered.value) {
        console.log('updated card inputs');
      // Update the current card
      workflowEditorStore.updateCardInputs(index, options);

      // }

      // Use nextTick to wait for the DOM updates
      await nextTick();

      console.log('is duplicating is ' , isDuplicating.value);

      if(isDuplicating.value == false)  {
        workflowEditorStore.saveState();

console.log('the state was saved')
console.log('this state is ' , workflowEditorStore.cards);

      }

      // // Call the debounced save state function
      // await saveStateDebounced();


      // Update the next card if it exists
      if (workflowEditorStore.cards[index + 1]) {
        // workflowEditorStore.updateCardInputs(index + 1, options);

        // Use nextTick to ensure the updates are applied
        await nextTick();

        // // Directly update the options of the next card
        // workflowEditorStore.cards[index + 1].options = options;

        console.log('Updated next card: ', workflowEditorStore.cards[index + 1]);
        console.log('added to index ', index + 1);
      }
    } catch (error) {
      console.log('error is ', error);
    }

    console.log('Updated Card Data:', workflowEditorStore.cards);

    didUserChange.value = true;

  }
};

const openDeleteModal = (index) => {
  currentCardIndex.value = index;
  showDeleteModal.value = true;
};

const confirmDeleteCard = ()  => {
  if (currentCardIndex.value !== null) {
    const targetIndex = currentCardIndex.value;
    const targetId = workflowEditorStore.cards[targetIndex].id;

      workflowEditorStore.saveState();

      console.log('delete state saved ' , workflowEditorStore.cards[targetIndex]);


    console.log('Deleting card at index:', targetIndex, 'with target ID:', targetId);

    nextTick(() => {
      // Process each card and its options
      for (const card of workflowEditorStore.cards) {
        console.log('Processing card:', card);

        if (card.options && typeof card.options[Symbol.iterator] === 'function') {
          for (const option of card.options) {
            console.log('Processing option:', option);

            if (option.hasAddVariable) {
              console.log('Option has add variable:', option);

              option.value = option.value.filter(valueItem => {
                console.log('Processing valueItem:', valueItem);

                if (valueItem.nodeReference === targetId) {
                  console.log('Matching segment found for target ID:', targetId);

                  // Trigger the rename event in the store before deleting
                  workflowEditorStore.triggerRenameEvent({
                    id: targetId,
                    cardName: card.name,
                    optionLabel: option.label,
                    valueItem: valueItem,
                  });

                  return false; // Exclude this item from the new array
                }

                return true; // Keep this item in the new array
              });



              console.log('Updated option:', option);
            }
          }
        }
      }


      workflowEditorStore.saveState();

      console.log("delete state saved " , workflowEditorStore.cards[targetIndex]);

      // Manually splice the array to remove the card
      workflowEditorStore.cards.splice(targetIndex, 1);

      console.log('Updated cards after deletion:', workflowEditorStore.cards);

      showDeleteModal.value = false;
      currentCardIndex.value = null;
      didUserChange.value = true;
    });
  }
};


const getFinalWorkflow = () => {

  console.log(workflowEditorStore.getWorkflow());

}
const closeDeleteModal = () => {
  showDeleteModal.value = false;
  currentCardIndex.value = null;
};


const openUnsavedModal = () => {
  showUnsavedModal.value = true;
  console.log('set it to true');
}

const closeUnSavedModal = () => {
  // don't show modal and then just redirect user to wherever they wanna go
  showUnsavedModal.value = false;
  resolveNavigation();

}

const onSave = async () => {

  console.log('the next is here ');

  await emitNew('saveWorkflow');
  // emit('saveWorkflow' , null);

  resolveNavigation();

}
const openRenameModal = (index) => {
  currentCardIndex.value = index;
  currentCardName.value = workflowEditorStore.cards[index].name;
  showRenameModal.value = true;
};

const renameCard = async (newName) => {
  if (currentCardIndex.value !== null) {
    // Use nextTick to wait for reactivity updates
    await nextTick();

    const beforeUpdateCards = JSON.parse(JSON.stringify(workflowEditorStore.cards));

    console.log('the before update is ', beforeUpdateCards);

    workflowEditorStore.updateCardName(currentCardIndex.value, newName);

    console.log('the after update is ', workflowEditorStore.cards);

    await nextTick();


    const targetId = workflowEditorStore.cards[currentCardIndex.value].id;

    for (const card of workflowEditorStore.cards) {
      if (card.options) {
        for (const option of card.options) {
          if (option.hasAddVariable) {
            for (const valueItem of option.value) {
              if (valueItem.nodeReference == targetId) {
                valueItem.value = newName;
              }

              // Trigger the rename event in the store
              workflowEditorStore.triggerRenameEvent({
                id: targetId,
                cardName: newName,
                optionLabel: option.label,
                valueItem: valueItem,
              });
            }
          }
        }
      }
    }

    // Use nextTick to wait for reactivity updates
    await nextTick();

  }
  didUserChange.value = true;
  showRenameModal.value = false;
  currentCardIndex.value = null;
};

const closeRenameModal = () => {
  showRenameModal.value = false;
  currentCardIndex.value = null;
};

const openDuplicateModal = (index) => {
  currentCardIndex.value = index;
  currentCardName.value = workflowEditorStore.cards[index].name;
  showDuplicateModal.value = true;
};

const isDuplicating = ref(false);

const duplicateCard = async (newName) => {
  if (currentCardIndex.value !== null) {

    isDuplicating.value = !isDuplicating.value;
    const originalCard = workflowEditorStore.getCard(currentCardIndex.value);
    console.log('The original card is ', originalCard);

    const highestId = workflowEditorStore.cards.reduce((maxId, card) => {
      return Math.max(maxId, card.id);
    }, 0);

    // Increment the highest ID for the new card
    const id = highestId + 1;

    // Create a deep copy of the original card
    const duplicatedCard = JSON.parse(JSON.stringify({ ...originalCard, id, name: newName }));
    // duplicatedCard.type = ClaudeOutputGenerator;

    // workflowEditorStore.saveState();
    // Convert the deep-cloned card to a reactive object
    const reactiveDuplicatedCard = duplicatedCard;

    workflowEditorStore.addCard({ ...originalCard, id, name: newName });

    await nextTick();
    // workflowEditorStore.cards.push({ ...originalCard, id, name: newName  ,type: ClaudeOutputGenerator});

    cardComp.value[cardComp.value.length - 1].loadClonedCardData(reactiveDuplicatedCard.options);


    nextTick(() => {
      scrollToCard(cardComp.value.length - 1);
      const card = document.querySelector(`.card-container:nth-child(${workflowEditorStore.cards.length})`);
      isDuplicating.value = !isDuplicating.value;
      if (card) {
        flashCard(card);
      }
    });
  }

  showDuplicateModal.value = false;
  currentCardIndex.value = null;
  didUserChange.value = true;

};

const closeDuplicateModal = () => {
  showDuplicateModal.value = false;
  currentCardIndex.value = null;
};


const flashCard = (card) => {

  console.log('card flashed');
  card.classList.add('flash');
  setTimeout(() => {
    card.classList.remove('flash');
  }, 1000);
};

const scrollToCard = (index) => {
  console.log('the state of nodes is ', nodeDefinerItems.value);
  console.log('we clicked ', index);

  // console.log('all of the nodes are ' , document.querySelectorAll(`.card-container`))
  const card = document.querySelectorAll('.card-container')[index];
  // const card = document.querySelector(`.card-container:nth-child(${index + 1})`);
  if (card) {
    card.scrollIntoView({ behavior: "smooth" });

    setTimeout(() => {
      nextTick(() => {
        flashCard(card);
      });
    }, 500); // Adjust delay as needed to ensure scroll completion
  }


};


const scrollToJobDefiner = () => {
  if (jobDefiner.value) {
    jobDefiner.value.scrollIntoView({ behavior: "smooth" });
  }
};



onBeforeUnmount(() => {
  console.log('the user left');
  window.removeEventListener('beforeunload', handleBeforeUnload);
  offAll('saveWorkflow'); // Unregister all handlers for the event

  document.removeEventListener('click', handleDocumentClick);
});


watch(() => taskDefinerStore.googleHeaders, (headers) => {
  try {
    // when the csv headers change, we need to update the
    // validity of the tags in each card so update the csv whitelist
    for (var cards of cardComp.value) {
      cards.nestedCsvInvoke();
    }

  } catch (error) {
    console.log('the error is ', error);
  }

  // console.log("Updated headers:", headers);
});


watch(() => taskDefinerStore.headers, (headers) => {
  try {
    // when the csv headers change, we need to update the
    // validity of the tags in each card so update the csv whitelist
    for (var cards of cardComp.value) {
      cards.nestedCsvInvoke();
    }
    jobRef.value.nestedCsvInvoke();

  } catch (error) {
    console.log('the error is ', error);
  }

  // console.log("Updated headers:", headers);
});

const handleBeforeUnload = (event) => {

  if (didUserChange.value) {
    event.returnValue = "You have unsaved changes. Do you want to save changes before leaving?";

    event.preventDefault();

  }


}

onMounted(async () => {

  console.log('onmounted called');

  window.addEventListener('beforeunload', handleBeforeUnload);

  workflowEditorStore.resetStacks();

  const userStore = useUserStore();
  const userToken = userStore.getToken
  // const passedWorkflow = route.params.workflowId;
  const workflowId = route.params.workflowId;


  document.addEventListener('click', handleDocumentClick);

  try {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/user/getWorkflowById`, {
      workflowId: workflowId
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`,
      }
    });

    var workFlowData = response.data;

    console.log('workflow is ' , workFlowData);


    if (workFlowData.csvFileData) {
      Papa.parse(workFlowData.csvFileData, {
      complete: (results) => {

        taskDefinerStore.setSideHeaders(results.meta.fields.map(header => ({
          type: 'header',
          id: header,
          name: header,
          showBolt: false,
        })));

        taskDefinerStore.setHeaders(results.meta.fields);
        taskDefinerStore.setCSVData(results.data);
      },
      header: true,
    });


    }




    // taskDefinerStore.setCSVData(workFlowData.csvFileData);

    taskDefinerStore.setHeaders(workFlowData.csvData);

    taskDefinerStore.setSideHeaders(workFlowData.csvData.map(header => ({
      type: 'header',
      id: header,
      name: header,
      showBolt: false,
    })))


    taskDefinerStore.setFileName(workFlowData.csvFileName);

    workflowEditorStore.setWorkflowName(workFlowData.workflowName); // Set the workflow name in the store

    if (workFlowData.csvOutputSegment) {
      taskDefinerStore.setCsvOutputSegment(workFlowData.csvOutputSegment.map(({ _id, ...rest }) => rest));

    } else {
      taskDefinerStore.setCsvOutputSegment([]);

    }


    
      taskDefinerStore.setSpreadSheetId(workFlowData.spreadsheetId);
      taskDefinerStore.setSheetId(workFlowData.sheetId);

// // Temporarily set the value to something different
// taskDefinerStore.setCurrentTab('hi');

// Set it back to the original value to trigger the watcher
taskDefinerStore.setCurrentTab(workFlowData.currentTab);

jobRef.value.selectTab(workFlowData.currentTab);




      console.log('the current tab is now ' , taskDefinerStore.currentTab);


      let googleCsvData;
      try {
         googleCsvData = await axios.post(`${process.env.VUE_APP_API_URL}/user/getSpreadsheet`, {
      selectedSpreadsheetId:taskDefinerStore.spreadsheetId,
      selectedChildSheetId: taskDefinerStore.sheetId
    }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`,
        }
      });
      console.log('the google csv data is ' , googleCsvData)

        taskDefinerStore.setGoogleSheetData(googleCsvData.data.data);

        taskDefinerStore.setGoogleSideHeaders(taskDefinerStore.googleSheetData[0].map(header => ({
          type: 'header',
          id: header,
          name: header,
          showBolt: false,
        })));

        taskDefinerStore.setGoogleHeaders(taskDefinerStore.googleSheetData[0]);


      } catch(error){
        console.log('the google error is ' , error); 
        taskDefinerStore.setGoogleSheetData([]);


      }

      workflowEditorStore.cards = [];

    for (const step of workFlowData.steps) {
      var id = await addCard(step.stepType, step.stepName);
      await nextTick(); // Wait for DOM update
      cardComp.value[cardComp.value.length - 1].loadBackendData(step);

      // callSpecificChildMethod(step);

    }





      var googleNameData = "";
      try {
         googleNameData = await axios.post(`${process.env.VUE_APP_API_URL}/user/getSpreadsheetNames`, {
      selectedSpreadsheetId: taskDefinerStore.spreadsheetId,
      selectedChildSheetId: taskDefinerStore.sheetId
    }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`,
        }
      });

      // console.log('the spreadsheet result is ' ,googleNameData.data);

      taskDefinerStore.setGoogleSpreadsheetName(googleNameData.data.sheetName);
      taskDefinerStore.setGoogleSheetName(googleNameData.data.spreadsheetName);



      } catch(error) {
        taskDefinerStore.setGoogleSpreadsheetName("");
        taskDefinerStore.setGoogleSheetName("");

      }
      taskDefinerStore.setSheetWorkflowType(workFlowData.sheetWorkflowType);
      taskDefinerStore.setGoogleOutputFileName(workFlowData.fileName);
      taskDefinerStore.setGoogleOutputSheetName(workFlowData.subsheetName);



      if (taskDefinerStore.currentTab == 'CSV Upload') {
        jobRef.value.loadBackendData();

      }
    // Handle the response as needed
    console.log(response.data);

    showModal.value = new Array(workflowEditorStore.cards.length).fill(false);

    console.log('the show modal is ', showModal.value);

    await nextTick();
    didUserChange.value = false;


    // console.log('after mount the user change value is ' , didUserChange.value);


    // await addCard()
  } catch (error) {
    console.error("Error fetching workflow:", error);
  }
});

</script>
<style scoped>
.sticky {
  position: -webkit-sticky;
  /* For Safari */
  position: sticky;
}

.card-container {
  position: relative;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.flash {
  animation: flash-animation 1s;
}

@keyframes flash-animation {
  0% {
    background-color: rgb(255, 255, 255, 0.1);
  }

  100% {
    background-color: transparent;
  }
}

.shadow-lg {
  box-shadow: 0 25px 30px rgba(121, 134, 203, 0.7);
}

.active-task {
  background-color: rgba(255, 255, 255, 0.1);
}

.active-task:hover {
  background-color: rgba(255, 255, 255, 0.2);
  /* More intense blue on hover */
}

.clicked-task {
  background-color: rgba(99, 102, 241, 0.1)
}

/* Additional hover style */
li:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

/* Ensure hover styles do not override the existing styles */
.clicked-task:hover {
  background-color: rgba(99, 102, 241, 0.2)
}
</style>
