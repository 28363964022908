const statusColors = {
  Green: "text-green-400 bg-green-400/10",
  Amber: "text-amber-400 bg-amber-400/10",
  Rose: "text-rose-400 bg-rose-400/10",
  Gray: "text-gray-500 bg-gray-100/10",
};

const tags = {
  Gray: "text-gray-400 bg-gray-400/10 ring-gray-400/20",
  Indigo: "text-indigo-400 bg-indigo-400/10 ring-indigo-400/30",
};

export default statusColors;