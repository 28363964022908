<!-- Usage Example -->
<template>
    <CardTemplate :elements="formElements" />
  </template>
  
  <script setup>
  import FormExample from './CardTemplate.vue'
  
  const formElements = [
    {
      id: 'website',
      type: 'username',
      label: 'Username',
      prefix: 'https://',
      inputType: 'text',
      autocomplete: 'username',
      title: 'Profile Information',
    },
    {
      id: 'about',
      type: 'textarea',
      label: 'About',
      rows: 3,
      description: 'Write a few sentences about yourself.',
      title: 'Profile Information',
    },
    {
      id: 'photo',
      type: 'photo',
      label: 'Photo',
      buttonText: 'Change',
      title: 'Profile Information',
    },
    {
      id: 'file-upload',
      type: 'file',
      label: 'Cover photo',
      buttonText: 'Upload a file',
      description: 'or drag and drop',
      fileTypes: 'PNG, JPG, GIF up to 10MB',
      title: 'Profile Information',
    },
    {
      id: 'comments',
      type: 'checkbox',
      label: 'Comments',
      description: 'Get notified when someone posts a comment on a posting.',
      title: 'Notifications',
    },
    {
      id: 'push-everything',
      type: 'radio',
      label: 'Everything',
      group: 'push-notifications',
      title: 'Push Notifications',
    },
    {
      id: 'push-email',
      type: 'radio',
      label: 'Same as email',
      group: 'push-notifications',
      title: 'Push Notifications',
    },
    {
      id: 'push-nothing',
      type: 'radio',
      label: 'No push notifications',
      group: 'push-notifications',
      title: 'Push Notifications',
    },
  ]
  </script>
  