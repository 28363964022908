<template>
  <div class="flex flex-col h-screen">
    <!-- Fixed header and secondary navigation section -->
    <div class="flex-none">
      <!-- Header section -->
      <div
        class="flex flex-col items-start justify-between gap-x-8 gap-y-4 border-b border-white/10 bg-gray-700/10 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8">
        <!--Util Controls and Progress Bar Container-->
        <div class="flex items-start gap-x-4">
          <!--Util Controls-->
          <div>
            <div class="flex items-center gap-x-3">
              <div v-if="isRunning" class="flex-none rounded-full bg-amber-400/10 p-1 text-amber-400">
                <div class="h-2 w-2 rounded-full bg-current" />
              </div>
              <div v-else class="flex-none rounded-full bg-gray-400/10 p-1 text-gray-400">
                <div class="h-2 w-2 rounded-full bg-current" />
              </div>
              <h1 class="flex gap-x-3 text-base leading-7">
                <span class="font-semibold text-white">
                  {{ workflowEditorStore.workflowName }}
                  <span v-if="!documentIsSaved">*</span>
                </span>
              </h1>
              <div v-if="isSaving" role="status">
                <svg aria-hidden="true" class="w-3.5 h-3.5 text-gray-200 animate-spin dark:text-gray-700 fill-gray-500"
                  viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor" />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill" />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
              <button class="text-gray-400 hover:text-white" @click="saveWorkflow">
                <FolderArrowDownIcon class="h-5 w-5" aria-hidden="true" />
              </button>
              <button class="text-gray-400 hover:text-white" @click="openRenameModal">
                <PencilSquareIcon class="h-5 w-5" aria-hidden="true" />
              </button>
              <button class="text-gray-400 hover:text-white" @click="openDuplicateModal">
                <DocumentDuplicateIcon class="h-5 w-5" aria-hidden="true" />
              </button>
              <button class="text-gray-400 hover:text-white" @click="openDeleteModal">
                <TrashIcon class="h-5 w-5" aria-hidden="true" />
              </button>

              <button class="text-gray-400 hover:text-white" @click="undo">
                <ArrowUturnLeftIcon class="h-5 w-5" aria-hidden="true" />
              </button>


              <button class="text-gray-400 hover:text-white" @click="redo">
                <ArrowUturnRightIcon class="h-5 w-5" aria-hidden="true" />
              </button>

            </div>
            <p class="mt-2 text-xs leading-6 text-gray-400">
              {{ workflow.description }}
              Workflow Description should appear
            </p>
          </div>
        </div>
        <div class="flex flex-row items-center h-full">
          <!-- Launch / Cancel Button -->
          <div>
            <!-- Launch Button -->
            <button v-if="!isRunning" type="button" @click="runWorkflow"
              class="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              <PlayIcon class="-ml-0.5 h-4 w-4" aria-hidden="true" />Launch
            </button>
            <!-- Cancel Button -->
            <button type="button" v-else @click="cancelJob"
              class="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              <StopIcon class="-ml-0.5 h-4 w-4" aria-hidden="true" />Cancel
            </button>
          </div>
        </div>
      </div>

      <!-- Secondary navigation section -->
      <header>
        <nav class="flex overflow-x-auto border-b border-white/10 py-4 bg-gray-700/10">
          <ul role="list"
            class="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8">
            <li v-for="item in updatedSecondaryNavigation" :key="item.name">
              <router-link :to="{ name: item.name, params: { workflowId: workflowId } }" :class="item.current ? 'text-indigo-400' : 'hover:text-indigo-400'
                ">
                {{ item.name }}
              </router-link>
            </li>
          </ul>
        </nav>
      </header>
    </div>

    <!-- Scrollable main content -->
    <main class="flex-1 overflow-y-auto">
      <router-view></router-view>
      <!--<WorkflowDashboard />-->
    </main>

    <!-- Modals -->
    <DeleteModal :open="isDeleteModalOpen" title="Delete Workflow"
      message="Are you sure you want to delete this workflow? This action cannot be undone." confirmButtonText="Delete"
      cancelButtonText="Cancel" @update:open="isDeleteModalOpen = $event" @confirm="handleDeleteConfirm"
      @cancel="handleDeleteCancel" />
    <DuplicateModal :open="isDuplicateModalOpen" title="Duplicate Workflow"
      message="Please enter a new name for the duplicated workflow." confirmButtonText="Duplicate"
      cancelButtonText="Cancel" @update:open="isDuplicateModalOpen = $event" @confirm="handleDuplicateConfirm"
      @cancel="handleDuplicateCancel" :workflowName="workflowEditorStore.workflowName" />
    <RenameModal :open="isRenameModalOpen" title="Rename Workflow" message="Please enter a new name for the workflow."
      confirmButtonText="Rename" cancelButtonText="Cancel" @update:open="isRenameModalOpen = $event"
      @confirm="handleRenameConfirm" @cancel="handleRenameCancel" :name="workflowEditorStore.workflowName" />
  </div>
</template>


<script setup>
import { ref, onMounted, onBeforeUnmount, computed, watch, toRaw, getCurrentInstance, nextTick, onUnmounted, shallowRef } from "vue";
import { useRoute } from "vue-router";
import { useWorkflowEditorStore } from '@/stores/workflowEditorStore';
import { useTaskDefinerStore } from "@/stores/taskDefinerStore";
import { useUserStore } from '@/stores/user';
import useEventsBus from '@/stores/eventBus';
import newEventBus from '@/stores/eventNewBus';
import axios from 'axios';
import DeleteModal from "./modals/DeleteModal.vue";
import DuplicateModal from "./modals/DuplicateModal.vue";
import RenameModal from "./modals/RenameModal.vue";
import WorkflowDashboard from "./workflows/WorkflowDashboard.vue";
import RadialProgressBar from "./RadialProgressBar.vue";
import ProgressBar from "./ProgressBar.vue";
import { io } from 'socket.io-client';

import ArticleFetcher from './workflows/task_cards/ArticleFetcher.vue';
import WebScraper from './workflows/task_cards/WebScraper.vue';
import GPTOutputGenerator from './workflows/task_cards/GPTOutputGenerator.vue';
import AISignUpTask from './workflows/task_cards/AISignUpTask.vue';
import IGScraper from './workflows/task_cards/IGScraper.vue';
import GoogleSheetFormulaTask from './workflows/task_cards/GoogleSheetFormulaTask.vue';
import ImageScalerTask from './workflows/task_cards/ImageScalerTask.vue';
import ClaudeOutputGenerator from './workflows/task_cards/ClaudeOutputGenerator.vue';
import LlamaOutputGeneratorTask from './workflows/task_cards/LlamaOutputGeneratorTask.vue';



import {
  ChartBarSquareIcon,
  Cog6ToothIcon,
  FolderIcon,
  GlobeAltIcon,
  ServerIcon,
  SignalIcon,
  XMarkIcon,
  PencilSquareIcon,
  DocumentDuplicateIcon,
  TrashIcon,
  FolderArrowDownIcon,
  RocketLaunchIcon,
  ArrowUturnLeftIcon, // For Undo
  ArrowUturnRightIcon, // For Redo
} from "@heroicons/vue/24/outline";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  PauseIcon,
  PlayIcon,
  CheckCircleIcon,
  StopIcon,
} from "@heroicons/vue/20/solid";
import Papa from "papaparse";

import workflows from "@/data/user-variables";
import router from "@/router";
import useNewEventBus from "@/stores/eventNewBus";
import useEmitter from '@/composables/useEmitter';

const route = useRoute();
var workflowId = route.params.workflowId;
const reRenderKey = ref(0);
const workflow = ref({});
const documentIsSaved = ref(true);
const isSaving = ref(false);

const { emit } = useEventsBus();

const { bus } = useEventsBus();

const { emitNew, on, off, offAll } = useNewEventBus();

const emitter = useEmitter();



const undo = async () => {

// set this flag on to prevent saving un-needed states onto the stack
  emit('undoTriggered', true);


  const res = workflowEditorStore.undo();

  console.log('the workflow cards are ' , workflowEditorStore.cards);

  // Mapping component types to their corresponding Vue components
  const componentMap = {
    ArticleFetcher: shallowRef(ArticleFetcher),
    WebScraper: shallowRef(WebScraper),
    GPTOutputGenerator: shallowRef(GPTOutputGenerator),
    IGScraper: shallowRef(IGScraper),
    AISignUpTask: shallowRef(AISignUpTask),
    GoogleSheetFormulaTask: shallowRef(GoogleSheetFormulaTask),
    ImageScalerTask: shallowRef(ImageScalerTask),
    ClaudeOutputGenerator: shallowRef(ClaudeOutputGenerator),
    LlamaOutputGeneratorTask: shallowRef(LlamaOutputGeneratorTask),
  };


  for (let index = 0; index < workflowEditorStore.cards.length; index++) {

  const card = workflowEditorStore.cards[index];
  const typeName = card.type.__name;
  // const targetState = parsedRes[index];
  const options = card.options;

  // serializing and deserializing the data in the undo/redo stack causes the component refreence in the store 
// to break so we need to re-initalize the components when restoring from undo/redo
  const cardComponent = componentMap[typeName];
  if (cardComponent) {
    card.type = cardComponent;
  }

  card.options = options;

  console.log('emitted for card id', card.id);

  await nextTick();

  // once restored, we need to propgate the changes from card store back to the form element within each card component, 
  await emitter.emitAsync('updateForm', {id: card.id, options});


  console.log(`Card at index ${index} restored with type ${typeName} and options`, options);
}

  // propgates card dv data back to tagify
  emit('segmentToTagify');

  // Log the final state of cards
  console.log('Now the cards are', workflowEditorStore.cards);
};

const redo = async () => {

// set this flag on to prevent saving un-needed states onto the stack
emit('undoTriggered', true);

const res = workflowEditorStore.redo();

console.log('the workflow cards are ' , workflowEditorStore.cards);

// Mapping component types to their corresponding Vue components
const componentMap = {
  ArticleFetcher: shallowRef(ArticleFetcher),
  WebScraper: shallowRef(WebScraper),
  GPTOutputGenerator: shallowRef(GPTOutputGenerator),
  IGScraper: shallowRef(IGScraper),
  AISignUpTask: shallowRef(AISignUpTask),
  GoogleSheetFormulaTask: shallowRef(GoogleSheetFormulaTask),
  ImageScalerTask: shallowRef(ImageScalerTask),
  ClaudeOutputGenerator: shallowRef(ClaudeOutputGenerator),
  LlamaOutputGeneratorTask: shallowRef(LlamaOutputGeneratorTask),
};


for (let index = 0; index < workflowEditorStore.cards.length; index++) {

const card = workflowEditorStore.cards[index];
const typeName = card.type.__name;
// const targetState = parsedRes[index];
const options = card.options;

// serializing and deserializing the data in the undo/redo stack causes the component refreence in the store 
// to break so we need to re-initalize the components when restoring from undo/redo
const cardComponent = componentMap[typeName];
if (cardComponent) {
  card.type = cardComponent;
}

card.options = options;

console.log('emitted for card id', card.id);

await nextTick();

await emitter.emitAsync('updateForm', {id: card.id, options});

console.log(`Card at index ${index} restored with type ${typeName} and options`, options);
}

emit('segmentToTagify');

console.log('Now the cards are', workflowEditorStore.cards);
};


// Register event handler
on('saveWorkflow', async () => {
  console.log('Performing save operation...');
  await saveWorkflow();
});


// Use the workflowEditor store
const workflowEditorStore = useWorkflowEditorStore();
const taskDefinerStore = useTaskDefinerStore();
const userStore = useUserStore();

const csvFileName = ref("");
const fileInput = ref(null);
const csvData = ref([]);
const seeWorkflowRunModal = ref(false);


function papaConvertToCSV(data) {
  return Papa.unparse(data);
}
function convertToCSV(data) {
  // Check if data is not empty
  if (!data.length) {
    return '';
  }

  // Extract headers from the first object in the array
  const headers = Object.keys(data[0]).join(',');

  // Extract rows
  const rows = data.map(row => {
    return Object.values(row).map(value => {
      // Convert value to a string and check if it contains a comma
      const stringValue = `${value}`;
      return stringValue.includes(',') ? `"${stringValue}"` : stringValue;
    }).join(',');
  });

  // Combine headers and rows
  return [headers, ...rows].join('\n');
}


const cancelJob = async () => {
  try {

    var userToken = userStore.getToken;

    const jobResponse = await axios.post(`${process.env.VUE_APP_API_URL}/user/getLastJob`, {
      workflowId: workflowId
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`,
      }
    });

    const cancelResponse = await axios.post(`${process.env.VUE_APP_API_URL}/user/cancelJob`, {
      jobId: jobResponse.data._id
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`,
      }
    });

    console.log('the cancel result is ', cancelResponse.data);


    isRunning.value = false;
  } catch (error) {
    console.error('Error running workflow:', error);
  }
};


const runWorkflow = async () => {
  try {

    if (taskDefinerStore.currentTab !== 'Google Sheets') {

    //  also checkk for invalid csv references s
    if (Object.keys(taskDefinerStore.csvData).length === 0) {
      alert("Make sure you input a csv before running a workflow.");
      return;
    }


    


    }


    var csvData = papaConvertToCSV(taskDefinerStore.csvData);
    var fileName = taskDefinerStore.fileName;
    const jobDetails = await axios.post(`${process.env.VUE_APP_API_URL}/user/createJob`, {
      workflowId: workflowId,
      fileName: fileName,
      csvData: csvData,
      mode: taskDefinerStore.currentTab
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userStore.getToken}`,
      }
    });

    console.log('the job is  ' , jobDetails.data.jobDetails);


    var jobId = jobDetails.data.jobDetails;

    await saveWorkflow();


    var csvData = papaConvertToCSV(taskDefinerStore.csvData);
    var fileName = taskDefinerStore.fileName;
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/user/runWorkflow`, {
      workflowId: workflowId,
      fileName: fileName,
      csvData: csvData,
      mode: taskDefinerStore.currentTab,
      jobId: jobId
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userStore.getToken}`,
      }
    });

    console.log(response.data.jobDetails._id);
    console.log('Workflow started:');
    // signal the preview to look at this job id
    isRunning.value = true;
  } catch (error) {
    console.error('Error running workflow:', error);
  }
};

let socket;

onMounted(async () => {


  socket = io(`${process.env.VUE_APP_API_URL}`, {
    withCredentials: true,
    query: { workflowId: workflowId },  // Pass any necessary query parameters
  });

  socket.on('disconnect', () => {
    console.log('Disconnected from server');
  });

  console.log('connected to socket io on station');

  socket.on('job_completed', async (data) => {

    console.log('received job completed signal!');
    isRunning.value = false;
  });


  console.log('the onMounted was called');
  const userToken = userStore.getToken;
  const selectedWorkflow = workflows.find((wf) => wf.id === workflowId);
  if (selectedWorkflow) {
    workflow.value = selectedWorkflow;
    workflow.value.name = workflowEditorStore.workflowName;
  }

  try {
    const jobResponse = await axios.post(`${process.env.VUE_APP_API_URL}/user/getLastJob`, {
      workflowId: workflowId
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`,
      }
    });

    if (jobResponse.data.status == 'pending') {
      isRunning.value = true;
    } else {
      isRunning.value = false;
    }


  } catch (error) {
    isRunning.value = false;
  }
  window.addEventListener("beforeunload", handleBeforeUnload);
  window.addEventListener("keydown", handleKeyDown);
});

watch(() => workflowEditorStore.workflowName, (workflowName) => {
  workflow.value.name = workflowName;
});

onUnmounted(() => {
  socket.disconnect();
  console.log('disconnected station client')

})

onBeforeUnmount(() => {
  offAll('saveWorkflow')
  offAll('updateForm');
  // socket.disconnect();
  //  console.log('disconnected station client')
  window.removeEventListener("beforeunload", handleBeforeUnload);
  window.removeEventListener("keydown", handleKeyDown);
});

const handleBeforeUnload = (event) => {
  if (!documentIsSaved.value) {
    event.preventDefault();
    event.returnValue = "You have unsaved changes. Do you want to save changes before leaving?";
  }
};

const handleKeyDown = (event) => {
  if ((event.ctrlKey || event.metaKey) && event.key === 's') {
    event.preventDefault();
    saveWorkflow();
  }
};


const initializeAfterNavigate = (newWorkflowName) => {
  workflow.value.name = newWorkflowName;
  console.log('Workflow name updated to:', newWorkflowName);
  // Add any other initialization logic here
};


defineExpose({ initializeAfterNavigate })


const secondaryNavigation = [
  { name: "Editor", href: "editor", current: false },
  { name: "Preview", href: "preview", current: false },
  { name: "Activity", href: "activity", current: false },
  { name: "Usage", href: "usage", current: false },
];

const updatedSecondaryNavigation = computed(() => {
  return secondaryNavigation.map((item) => ({
    ...item,
    current: item.name === route.name,
  }));
});

const sidebarOpen = ref(false);
const isRunning = ref(false);
const isDeleteModalOpen = ref(false);
const isDuplicateModalOpen = ref(false);
const isRenameModalOpen = ref(false);

const openDeleteModal = () => {
  isDeleteModalOpen.value = true;
};

const openDuplicateModal = () => {
  isDuplicateModalOpen.value = true;
};

const openRenameModal = () => {
  isRenameModalOpen.value = true;
};

const handleDeleteConfirm = async () => {

  // signal delete event which prevents unsaved confirmation modal from showing
  emit('deleteConfirm', 'null');

  const userToken = userStore.getToken;


  const response = await axios.post(`${process.env.VUE_APP_API_URL}/user/deleteWorkflow`, { workflowId: workflowId }, {
    headers: {
      'Content-Type': 'application/json',  // Ensuring Content-Type is set
      'Authorization': `Bearer ${userToken}`,  // Adding Authorization header
    }
  });

  console.log("response.data message is ", response.data.message);

  isDeleteModalOpen.value = false;

  router.push('/workspace/workflows');


};

const handleDeleteCancel = () => {
  isDeleteModalOpen.value = false;
};

const handleDuplicateConfirm = async (newWorkflowName) => {

  const userToken = userStore.getToken;

  const response = await axios.post(`${process.env.VUE_APP_API_URL}/user/cloneWorkflow`, {
    workflowName: newWorkflowName,
    workflowId: workflowId
  }, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userToken}`,
    }
  });

  console.log('Response:', response.data);

  await router.push({
    path: `/workspace/workflows/${response.data._id}/editor`,
    query: {
      forceReload: 'true'
    }
  });

  await nextTick();



  try {
    const jobResponse = await axios.post(`${process.env.VUE_APP_API_URL}/user/getLastJob`, {
      workflowId: workflowId
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`,
      }
    });

    if (jobResponse.data.status === 'pending') {
      isRunning.value = true;
    } else {
      isRunning.value = false;
    }
  } catch (error) {
    isRunning.value = false;
  }

  workflowEditorStore.workflowName = newWorkflowName;
  isDuplicateModalOpen.value = false;
  emit('reRenderEditor', 'null');
  workflowId = route.params.workflowId;
  console.log("Workflow duplicated with name:", newWorkflowName);
};

const handleDuplicateCancel = () => {
  isDuplicateModalOpen.value = false;
};

const handleRenameConfirm = (newWorkflowName) => {
  workflow.value.name = newWorkflowName;
  workflowEditorStore.setWorkflowName(newWorkflowName);
  console.log("Workflow renamed to:", newWorkflowName);
  isRenameModalOpen.value = false;
};

const handleRenameCancel = () => {
  isRenameModalOpen.value = false;
};



const saveWorkflow = async () => {

  
  // tagify appends a line break at the end of output in mixed mode for some reason
  // so we remove the lat occurence of linebreak from the dynamic variable data
  function transformLastStaticItem(arr) {
    return arr.map((obj, i, array) =>
      i === array.length - 1 && obj.type === 'static'
        ? { ...obj, value: obj.value.replace(/\r\n$/, '') }
        : obj
    );
  }

  const userToken = userStore.getToken;

  var finalWorkflow = workflowEditorStore.getWorkflow();

  finalWorkflow.steps = finalWorkflow.steps.map((step) => {
    const transformedOptions = step.options.reduce((acc, option) => {
      if (Array.isArray(option.value) && option.value.every(item => item && typeof item === 'object' && 'tag' in item)) {
        acc[option.id] = option.value.map(item => item.tag);
      } else {
        acc[option.id] = option.value
      }

      if (option.hasAddVariable) {
        acc[option.id] = transformLastStaticItem(option.value);

        // remove the invisible character that may have been added during copy paste
        acc[option.id] = acc[option.id].map(obj => {
          if (obj.value) {
            // Remove all occurrences of the invisible character from the value property
            obj.value = obj.value.replace(/\u2063/g, '');
          }
          return obj;
        });
      }
      return acc;
    }, {});
    return {
      cardId: step.id,
      stepName: step.name,
      stepType: step.type.__name,
      options: transformedOptions
    };
  });
  // tagify appends a line break regardless, so remove last occurance of it in segment
  finalWorkflow.csvOutputSegment = transformLastStaticItem(taskDefinerStore.csvOutputSegment)
  // finalWorkflow.csvOutputSegment = taskDefinerStore.csvOutputSegment
  finalWorkflow.csvFileName = taskDefinerStore.fileName;
  finalWorkflow.workflowId = workflowId;
  finalWorkflow.csvFileData = convertToCSV(taskDefinerStore.csvData);
  if (finalWorkflow.sheetId !== "") { 
    finalWorkflow.sheetId = taskDefinerStore.sheetId.toString();

  }  else {
    finalWorkflow.sheetId = "";

  }
  finalWorkflow.spreadsheetId = taskDefinerStore.spreadsheetId;

  finalWorkflow.sheetWorkflowType = taskDefinerStore.sheetWorkflowType;

  finalWorkflow.fileName = taskDefinerStore.googleOutputFileName;

  finalWorkflow.subsheetName = taskDefinerStore.googleOutputSheetName;
  finalWorkflow.currentTab = taskDefinerStore.currentTab;

  finalWorkflow = JSON.parse(JSON.stringify(finalWorkflow));

  isSaving.value = true;

  const response = await axios.post(`${process.env.VUE_APP_API_URL}/user/updateWorkflowById`, finalWorkflow, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userToken}`,
    }
  });

  console.log('Response:', response.data);

  documentIsSaved.value = true;
  emit('savedWorkflow', false);

  console.log('emitted  save ');

  isSaving.value = false;
};
</script>

<style scoped>
/* Your styles here */
</style>
