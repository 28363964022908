import { defineStore } from 'pinia';
import { ref, nextTick } from 'vue';

export const useWorkflowEditorStore = defineStore('workflowEditor', () => {
  const cards = ref([]);
  const taskDefiner = ref(null);
  const renameEvent = ref(null);
  const addEvent = ref(null);
  const addEventCounter = ref(0); // Counter to force reactivity

  const previewCsvData = ref([]);
  const workflowName = ref('');

  const currentJobId = ref('');

  // Stack for undo/redo
  const undoStack = ref([]);
  const redoStack = ref([]);
  const resetStacks = () => {
    undoStack.value = [];
    redoStack.value = [];
  }
  const saveState = () => {
    undoStack.value.push(JSON.stringify(cards.value));
    redoStack.value = []; // Clear redo stack after a new action
  };

  const undo = async () => {
    if (undoStack.value.length > 0) {
      const currentState = JSON.stringify(cards.value);
      redoStack.value.push(currentState);

      const previousState = undoStack.value.pop();

      // console.log('we are restoring ' , previousState);
      cards.value = JSON.parse(previousState);
      // console.log('we are restoring after ' , previousState);

      return previousState;
    }
  };

  const redo = () => {
    if (redoStack.value.length > 0) {
      const currentState = JSON.stringify(cards.value);
      undoStack.value.push(currentState);

      const nextState = redoStack.value.pop();
      console.log('the next state is ' , nextState);
      cards.value = JSON.parse(nextState);
      return nextState;

    }
  };

  const setPreviewCsvData = (csvPreviewData) => {
    previewCsvData.value = csvPreviewData;
  }

  const setJobId = (jobName) => {
    currentJobId.value = jobName;
  }

  const getCard = (cardIndex) => {
    return cards.value[cardIndex];
  }

  const addCard = (card) => {
    saveState(); // Save state before adding a card
    cards.value.push(card);
  };

  const removeCard = (index) => {
    saveState(); // Save state before removing a card
    cards.value.splice(index, 1);
  };

  const setTaskDefiner = (taskDef) => {
    taskDefiner.value = taskDef;
  };

  const updateCardInputs = (index, inputs) => {
    if (cards.value[index]) {
      // saveState(); // Save state before updating card inputs
      cards.value[index].options = inputs;
    }
  };

  const setWorkflowName = (name) => {
    workflowName.value = name;
  };

  const updateCardName = (index, newName) => {
    if (cards.value[index]) {
      console.log('the undo state is ' ,  undoStack.value[undoStack.value.length - 1]);
      saveState(); // Save state before updating the card name
      cards.value[index].name = newName;
    }
  };

  const getWorkflow = () => {
    console.log('the csv output Segment ', taskDefiner.value.csvOutputSegment);
    
    return {
      workflowName: workflowName.value,
      csvData: taskDefiner.value.headers.map((value) => value),
      steps: cards.value,
      csvFileName: taskDefiner.value.fileName,
    };
  };

  const triggerRenameEvent = (eventData) => {
    renameEvent.value = eventData;
  };

  const triggerAddEvent = (eventData) => {
    addEvent.value = eventData;
    addEventCounter.value++; // Increment counter to force reactivity
  };

  const triggerAddCardEvent = (cardId, eventData) => {
    const cardIndex = cards.value.findIndex(card => card.id == cardId);
    if (cardIndex !== -1) {
      saveState(); // Save state before triggering add card event
      cards.value[cardIndex] = {
        ...cards.value[cardIndex],
        addEvent: eventData
      };
    }
  };

  const reassignCards = (newCards) => {
    saveState(); // Save state before reassigning cards
    cards.value = newCards;
  };

  const clearRenameEvent = () => {
    renameEvent.value = null;
  };

  return { 
    cards, 
    addCard, 
    removeCard, 
    setTaskDefiner, 
    updateCardInputs, 
    updateCardName, 
    getWorkflow,
    renameEvent,
    triggerRenameEvent,
    clearRenameEvent,
    setWorkflowName,
    triggerAddEvent,
    addEvent,
    addEventCounter,
    triggerAddCardEvent,
    reassignCards,
    workflowName,
    currentJobId,
    setJobId,
    setPreviewCsvData,
    previewCsvData,
    getCard,
    undo, // Undo action
    redo, // Redo action
    saveState,
    resetStacks
  };
});
