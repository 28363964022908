<template>

<div ref ="dropdownContainer" class="flex flex-row justify-between">

    <div class="flex flex-row gap-4">

<label :for="props.targetDynamicId" class="block text-sm font-medium leading-6 text-white">{{
  props.label }}</label>
</div>


      <div class="flex flex-row gap-2">

        <div class="relative inline-flex items-center">
          <button type="button" @click="dropdownClick"
            class="inline-flex items-center gap-x-1.5 rounded-full bg-green-100 hover:bg-green-50 px-2 py-1 text-xs font-medium text-green-700">
            <BoltIcon class="h-3 w-3 fill-green-700" viewBox="0 0 12 12" aria-hidden="true"></BoltIcon>
            Add Dynamic Variable
          </button>
          <div :style="{ height: `35px` }"></div>
          <div v-if="dropdownVisible" class="">
            <div
              class="py-1 absolute right-0 z-10 mt-4 origin-top-right rounded-md bg-green-100 shadow-lg ring-1 ring-white/20 focus:outline-none">
              <div v-for="variable in variables" :key="variable"
                class="pl-3 pr-4 py-2 text-xs flex flex-row font-medium text-green-700 cursor-pointer hover:bg-green-50"
                @click="selectVariable(variable)">
                <BoltIcon class="h-3 w-3 mr-1 fill-green-700" viewBox="0 0 12 12" aria-hidden="true"></BoltIcon>
                {{ variable.value }}
              </div>
            </div>
          </div>
        </div>

      </div>


    </div>


  </template>
  
  <script setup>
  import { ref, onMounted , computed, onBeforeUnmount, nextTick} from 'vue';
  import { BoltIcon } from '@heroicons/vue/24/solid';
  import useEventsBus from '@/stores/eventBus';
  import { useWorkflowEditorStore } from '@/stores/workflowEditorStore'; // Adjust the import path as needed
  import { useTaskDefinerStore } from '@/stores/taskDefinerStore'; // Adjust the import path as needed


  const { emit } = useEventsBus();

const { bus }  = useEventsBus();
  const props = defineProps({
    targetElementId: {
      type: String,
      required: false,
    },
    dynamicInputRef: {
    type: Object, // This should be of type Ref
    required: true,
  },

  targetDynamicId: {
    type: String,
    required: true,
  },

  card: {
    type: Object,
    required: true
  },
   label: {
    type: String,
    required: true
   },

   mode: {
    type: String,
    required: false
   }


  });
  

  const dropdownContainer = ref(null);

  const variables = ref([]);

  const workflowEditorStore = useWorkflowEditorStore();
  const taskDefinerStore = useTaskDefinerStore();

  // Computed property to access dropdownVisible from dynamicInputRef
const dropdownVisible = computed(() => {

    try {
        const cardIndex = workflowEditorStore.cards.findIndex(card => card.id === props.card.id);

return  workflowEditorStore.cards[cardIndex].cardDVClicked && workflowEditorStore.cards[cardIndex].activeDVId == props.targetDynamicId;

    } catch(error) {
        return false;
    }   

});

const handleClickOutside = async (event) => {



    if (props.mode == 'csv') {
        if (taskDefinerStore.csvDVClicked && dropdownContainer.value && !dropdownContainer.value.contains(event.target)) {
            
            emit('dropdownChange' , props.targetDynamicId);
            emit('toggleDropdown', props.targetDynamicId);


        }
    } else {
        const cardIndex = workflowEditorStore.cards.findIndex(card => card.id === props.card.id);
  if (workflowEditorStore.cards[cardIndex].cardDVClicked && dropdownContainer.value && !dropdownContainer.value.contains(event.target)) {
    // emit('dropdownChange' , props.targetDynamicId);
    await nextTick();
    emit('toggleDropdown', props.targetDynamicId, props.card.id);

    
    // workflowEditorStore.cards.slice(0, cardIndex).forEach(card => {
    //   card.showBolt = false;
    // });


    // taskDefinerStore.sideHeaders.forEach(header => {
    //   header.showBolt = false;
    // });



  }

    }
};


onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);

});

onMounted(() => {
    document.addEventListener('click', handleClickOutside);

});


  const dropdownClick = () => {
      emit('toggleDropdown', props.targetDynamicId, props.card.id);

  };
  
  </script>
  
  <style scoped>
  </style>