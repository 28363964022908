import mitt from 'mitt';

// mitt doesn't allow awaiting for emit events to complete so this is an extension for this
export function mittAsync(all) {
  const inst = mitt(all);
  inst.emitAsync = emitAsync;
  return inst;
}

async function emitAsync(type, e) {
  let handlers = this.all.get(type);
  if (handlers) {
    for (const f of handlers) {
      await f(e);
    }
  }

  handlers = this.all.get('*');
  if (handlers) {
    for (const f of handlers) {
      await f(type, e);
    }
  }
}
