<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full bg-gray-900">
    <body class="h-full">
    ```
  -->
  <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <a href="/">
        <img class="mx-auto h-10 w-auto" src="@/assets/AudareMatic-logo.png" alt="Your Company" />
      </a>
      <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">Sign in to your account</h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form @submit.prevent="login" class="space-y-6">
        <div>
          <label for="username" class="block text-sm font-medium leading-6 text-white">Username</label>
          <div class="mt-2">
            <input v-model="username" id="username" name="username" type="text" autocomplete="username" required class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
          </div>
        </div>

        <div>
          <div class="flex items-center justify-between">
            <label for="password" class="block text-sm font-medium leading-6 text-white">Password</label>
            <div class="text-sm">
              <a href="#" class="font-semibold text-indigo-400 hover:text-indigo-300">Forgot password?</a>
            </div>
          </div>
          <div class="mt-2 relative">
            <input v-model="password" :type="isPasswordVisible ? 'text' : 'password'" id="password" name="password" autocomplete="current-password" required class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
            <button type="button" @click="togglePasswordVisibility" class="bg-white/5 absolute inset-y-0 right-0 flex items-center px-2">
              <EyeIcon v-if="isPasswordVisible" class="h-4 w-4 text-gray-200" aria-hidden="true" />
              <EyeSlashIcon v-else class="h-4 w-4 text-gray-200" aria-hidden="true" />
            </button>
          </div>
        </div>

        <div>
          <button type="submit" class="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Sign in</button>
        </div>
      </form>

      <p class="mt-10 text-center text-sm text-gray-400">
        Not a member?
        {{ ' ' }}
        <a href="" class="font-semibold leading-6 text-indigo-400 hover:text-indigo-300">Request an account</a>
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/stores/user';
import {
  EyeIcon, EyeSlashIcon
} from '@heroicons/vue/24/solid';
const router = useRouter();
const userStore = useUserStore();

const username = ref('');
const password = ref('');
const isPasswordVisible = ref(false); // Add a reactive variable for visibility state

const togglePasswordVisibility = () => {
  isPasswordVisible.value = !isPasswordVisible.value;
};

const login = async () => {
  try {
    // Simulate an API call to authenticate the user
    const response = await userStore.login({ username: username.value, password: password.value });

    if (response.status === 'success') {
      router.push({ name: 'WorkflowsHome' }); // Redirect to Workspace on successful login
    } else {
      alert('Login failed. Please check your username and password.');
    }
  } catch (error) {
    console.error('Login error:', error);
    alert('An error occurred during login. Please try again.');
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>
